// import auth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { useEffect } from "react";
import { useAuth } from "../Hooks/useAuth";
import { getAuth } from "firebase/auth";

const DebounceDueTime = 200;

export default function AuthProvider({ children }: any) {
    const { onAuthStateChanged } = useAuth();

    useEffect(() => {
        var debounceTimeout: any;
        const authStateChange = async (user: any) => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
            debounceTimeout = setTimeout(async () => {
                onAuthStateChanged(user);
            }, DebounceDueTime);
        };
        const subscriber = getAuth().onAuthStateChanged(authStateChange);
        return subscriber; // unsubscribe on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
}
