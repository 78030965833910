import React from "react";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

interface TeamMemberProps {
    name: string;
    title: string;
    bio: string;
    imageSrc: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
    name,
    title,
    bio,
    imageSrc,
}) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.GREY_0,
        dark: colors.DARKGREY_14,
    });
    const textColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });
    return (
        <div className="member-card" style={{ backgroundColor }}>
            <img
                loading="lazy"
                src={imageSrc}
                alt={`${name}, ${title}`}
                className="member-image"
            />
            <div className="member-info">
                <h3 className="member-name" style={{ color: textColor }}>
                    {name}
                </h3>
                <p className="member-title">{title}</p>
                <p className="member-bio">{bio}</p>
            </div>
        </div>
    );
};

export default TeamMember;
