import React, { useState, useEffect } from "react";
import { useDebounce } from "../../../../Utils/debounceUtil";
import { NetworkCode } from "../../../../Utils/network";
import { useErrorStore, useMiscStore } from "../../../../state/store";
import ErrorFilter from "./ErrorFilters";
import ErrorRow from "./ErrorRow";
import Pagination from "../../../../Components/Pagination";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../../../Utils/colors";

const AllErrors = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const getErrors = useErrorStore((state) => state.getErrors);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const [versions, setVersions] = useState<string[]>([]);
    const [platforms, setPlatforms] = useState<string[]>([]);
    const [otaVersions, setOtaVersions] = useState<string[]>([]);

    const [errors, setErrors] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [version, setVersion] = useState<string | null>(null);
    const [platform, setPlatform] = useState<string | null>(null);
    const [otaVersion, setOtaVersion] = useState<string | null>(null);
    const [limit] = useState<number>(10);

    const fetchErrors = async () => {
        let filters: any = {};
        if (query) {
            filters.query = query;
        }
        if (version) {
            filters.version = version;
        }
        if (platform) {
            filters.platform = platform;
        }
        if (otaVersion) {
            filters.otaVersion = otaVersion;
        }
        filters.offset = page - 1;
        filters.limit = limit;

        const response = await getErrors(filters);
        if (response.status === NetworkCode.OK) {
            setErrors(response.data.errors);
            setPages(response.data.pages ?? 0);

            if (response.data.versions) {
                setVersions(
                    response.data.versions.map((v: any) => v._id).sort(),
                );
            }
            if (response.data.platforms) {
                setPlatforms(
                    response.data.platforms.map((p: any) => p._id).sort(),
                );
            }
            if (response.data.otaVersions) {
                setOtaVersions(
                    response.data.otaVersions.map((o: any) => o._id).sort(),
                );
            }
        } else {
            console.log("Error fetching errors: ", response.data?.message);
        }
    };
    const debouncedSearch = useDebounce(fetchErrors, 500);

    useEffect(() => {
        debouncedSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        fetchErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version, platform, otaVersion, page]);

    const filterContainerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: isTabletOrMobile ? "flex-start" : "flex-end",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 20,
        marginBottom: 20,
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: 14,
        marginTop: isTabletOrMobile ? 0 : -50,
    } as React.CSSProperties;

    const filters = [
        {
            label: "Search",
            type: "text",
            value: query,
            setValue: setQuery,
        },
        {
            label: "Version",
            type: "select",
            options: ["All", ...versions],
            value: version,
            setValue: setVersion,
        },
        {
            label: "Platform",
            type: "select",
            options: ["All", ...platforms],
            value: platform,
            setValue: setPlatform,
        },
        {
            label: "OTA Version",
            type: "select",
            options: ["All", ...otaVersions],
            value: otaVersion,
            setValue: setOtaVersion,
        },
    ];

    const theadStyle = {
        padding: "5px 10px",
        textAlign: "left",
        borderBottom: `1px solid ${colors.GREY_6}`,
        borderTop: `1px solid ${colors.GREY_6}`,
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: 12,
    } as React.CSSProperties;

    return (
        <React.Fragment>
            <div style={filterContainerStyle}>
                {filters.map((filter, index) => {
                    return (
                        <ErrorFilter
                            label={filter.label}
                            type={filter.type}
                            value={filter.value}
                            options={filter.options}
                            setValue={filter.setValue}
                            key={index}
                        />
                    );
                })}
            </div>

            <div
                style={{
                    overflowX: "auto",
                    width: "100%",
                }}>
                <table
                    style={{
                        width: "100%",
                    }}>
                    <thead>
                        <tr>
                            <th style={theadStyle}>Version</th>
                            <th style={theadStyle}>UserID</th>
                            <th style={theadStyle}>Platform</th>
                            <th style={theadStyle}>OTA Version</th>
                            <th style={theadStyle}>Date</th>
                            <th style={theadStyle}>Title</th>
                            <th style={theadStyle}>Metadata</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errors?.length > 0 ? (
                            errors.map((error, index) => (
                                <ErrorRow
                                    error={error}
                                    isEmpty={false}
                                    key={`error-${index}`}
                                />
                            ))
                        ) : (
                            <ErrorRow error={{}} isEmpty={true} />
                        )}
                    </tbody>
                </table>
            </div>

            {/* pagination */}
            <Pagination
                totalPages={pages}
                currentPage={page}
                onChange={(page: number) => {
                    setPage(page);
                }}
                siblingCount={1}
            />
        </React.Fragment>
    );
};

export default AllErrors;
