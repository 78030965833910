import React from "react";
import { useMiscStore } from "../../../../state/store";
import { Outlet, useOutlet } from "react-router-dom";
import AllErrors from "./AllErrors";
import { colors } from "../../../../Utils/colors";

const ErrorLogScreen = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const outlet = useOutlet();

    return (
        <div>
            <h1
                style={{
                    color: getThemeColor({
                        light: colors.DARKGREY_16,
                        dark: colors.GREY_0,
                    }),
                }}>
                Error Log
            </h1>

            {/* if viewing a specific error */}
            {outlet ? <Outlet /> : <AllErrors />}
        </div>
    );
};

export default ErrorLogScreen;
