import React from "react";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

export default function TermsScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { pageStyle, divStyle, linkStyle } = styles(colors, getThemeColor);

    return (
        <div style={pageStyle}>
            <div style={divStyle}>RTRO Terms of Service</div>
            <div style={divStyle}>Effective April 14, 2023</div>
            <div style={divStyle}>
                <center>
                    <b>Terms of Use</b>
                </center>
            </div>
            <div style={divStyle}>
                Welcome, and thank you for your interest in Librex, Inc. d/b/a
                RTRO ("RTRO," "we," or "us") and our website at rtro.com and
                mobile application app and other services provided by us
                (collectively, the "Service"). The Terms of Use are a legally
                binding contract between you and RTRO regarding your use of the
                Service.
            </div>
            <div style={divStyle}>
                PLEASE READ THE FOLLOWING TERMS CAREFULLY
            </div>
            <div style={divStyle}>
                By accessing or using the RTRO Service and clicking "I Accept,"
                you agree to be bound by the following terms and conditions,
                including RTRO's Privacy Policy (collectively, these "terms" ) .
                You acknowledge that you have read, understood, and agreed to
                these Terms, and that your use of the Service is contingent upon
                your agreement to be bound by these Terms. If you do not meet
                the eligibility requirements or do not agree to the Terms, you
                are not authorized to use the Service. By using the Service, you
                and RTRO both agree to be bound by these Terms.
            </div>
            <div style={divStyle}>
                Please read these Terms of Use (these "Terms") carefully,
                including the mandatory arbitration provision outlined in
                "Dispute Resolution" below. This provision will require you to
                submit any claims against RTRO to binding arbitration, as the
                procedures are outlined in "Dispute Resolution," or unless a
                limited exception within these Terms applies., and you will only
                be able to seek relief (including monetary, injunctive, and
                declaratory relief) on an individual basis. These Terms govern
                your access to and use of the Service and all Collective Content
                (as defined below), except as otherwise provided in these Terms.
                These Terms constitute a legally binding agreement between you
                and RTRO.
            </div>
            <div style={divStyle}>
                When using certain parts of the Service, you may be subject to
                additional terms, policies, or guidelines that are applicable to
                those parts of the Service. These terms, policies, and
                guidelines may be posted on the Site or Apps from time to time,
                and may include our Privacy Policy, located at{" "}
                <a href="https://www.rtro.com/privacy" style={linkStyle}>
                    www.rtro.com/privacy
                </a>
                , our Fee Policy, located at{" "}
                <a href="https://www.rtro.com/fees" style={linkStyle}>
                    www.rtro.com/fees
                </a>
                . All such terms, policies, and guidelines are incorporated by
                reference into these Terms. Please read all such terms,
                policies, and guidelines carefully.
            </div>
            <div style={divStyle}>
                <b>ARBITRATION NOTICE.</b> Except for certain types of disputes
                described in "Dispute Resolution" you agree that all disputes
                arising from these Terms will be resolved through individual
                arbitration, and by accepting these Terms, you and RTRO are each
                waiving the right to trial by jury or to participate in any
                class action or representative proceeding. You agree to give up
                your right to go to court to assert or defend your rights under
                this agreement (except for matters that may be taken to small
                claims court). Your rights will be determined by a NEUTRAL
                ARBITRATOR and NOT a judge or jury. "Dispute Resolution".
            </div>
            <div>
                <ol type="1">
                    <li>
                        <b>RTRO Service Overview:</b>
                        <ol type="a">
                            <li>
                                Through its website ("Site"), mobile
                                applications ("Apps"), and other related
                                properties (collectively, the "Service"), RTRO
                                offers new and used clothing, sneakers, and
                                other collectibles for trade. In order to
                                facilitate trades, users on the Service, upload
                                photos and enter items into their closets, where
                                they also list item size, condition. Users
                                initiate trades by browsing others' public
                                offers/profiles for items they are interested
                                in, then making offers to those users. When a
                                user agrees to a trade, RTRO provides a prepaid
                                shipping label. The user is then prompted to
                                ship their item to RTRO, where authentication
                                services ensure item exchanges meet RTRO's
                                quality, inspection, and authenticity standards.
                                Using the Service, users can trade securely and
                                anonymously without revealing any personal
                                information. Through the Service, users can
                                manage shipping and trading, while payments can
                                be managed through a third-party payment
                                processor.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>KEY TERMS RELATED TO CONTENT</b>
                        <ol type="a">
                            <li>
                                "Collective Content" means Registered User
                                Content and RTRO Content.
                            </li>
                            <li>
                                "Content" means text, graphics, images, music,
                                software, audio, video, information or other
                                materials.
                            </li>
                            <li>
                                "RTRO Content" means all Content that RTRO makes
                                available through the Service, including any
                                Content licensed from a third party, but
                                excluding Registered User Content.
                            </li>
                            <li>
                                "Registered User" means a person who completes
                                RTRO's account registration process, as
                                described under "Account Registration" below.
                            </li>
                            <li>
                                "Registered User Content" means all Content that
                                a Registered User posts, uploads, publishes,
                                submits, or transmits to be made available
                                through the Service.
                            </li>
                            <li>
                                Note on Content Key Terms
                                <ol type="i">
                                    <li>
                                        Certain areas of the Site and Apps, and
                                        your access to or use of parts of the
                                        Service or Collective Content, may have
                                        different terms and conditions posted or
                                        may require you to agree with and accept
                                        additional terms and conditions. If
                                        there is a conflict between these Terms
                                        and terms and conditions posted for a
                                        specific area of the Site, Apps, Service
                                        or Collective Content, the latter terms
                                        and conditions will take precedence with
                                        respect to your use of or access to that
                                        area of the Site, Apps, Service or
                                        Collective Content.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR
                                USING THE SERVICE OR BY POSTING ANY CONTENT ON
                                OR THROUGH THE SERVICE, YOU ARE INDICATING THAT
                                YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND
                                BY THESE TERMS, WHETHER OR NOT YOU HAVE
                                REGISTERED WITH THE SERVICE. IF YOU DO NOT AGREE
                                TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS
                                OR USE THE SERVICE OR COLLECTIVE CONTENT.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>MODIFICATION TO SERVICE</b>
                        <ol type="a">
                            <li>
                                RTRO reserves the right, in its sole discretion,
                                to modify, discontinue, or terminate the Service
                                and to modify these Terms at any time. If there
                                are any modifications to these Terms, RTRO will
                                update them on the Site and provide notice of
                                the modification via email or a general notice
                                on the Site. The "Last Updated Date" at the top
                                of these Terms will also be updated. It is
                                recommended to review these Terms and this page
                                periodically to stay informed of any changes. By
                                accessing or using the Service after
                                modifications have been made to the Terms, you
                                agree to be bound by the modified Terms. If the
                                modified Terms are not acceptable to you, please
                                stop using the Service.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>USER ELIGIBILITY</b>
                        <ol type="a">
                            <li>
                                To use the RTRO Service, users must be at least
                                18 years old or have the permission and
                                acceptance (to these Terms) of a parent or
                                guardian. Access to the Service by individuals
                                under the age of 13, even with parental or
                                guardian consent, is prohibited.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>SECURITY AND ACCOUNT REGISTRATION</b>
                        <ol type="a">
                            <li>
                                To access certain features of the RTRO Service
                                and post Registered User Content, you must
                                become a "Registered User" by registering to
                                create an account ("Account"). During the
                                registration process, you must provide accurate,
                                current, and complete information and keep it
                                up-to-date. We reserve the right to suspend or
                                terminate your Account at our discretion if any
                                information provided during the registration
                                process or afterward is found to be inaccurate,
                                not current, or incomplete. You are responsible
                                for safeguarding your password and agree not to
                                disclose it to any third party. Regardless of
                                whether you have authorized them, you are solely
                                responsible for any activities or actions under
                                your Account. If you suspect unauthorized use of
                                your Account, please notify us immediately
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>INTELLECTUAL PROPERTY</b>
                        <ol type="a">
                            <li>
                                You acknowledge and agree that the RTRO Service
                                and Collective Content may contain images and
                                descriptions of fashion items and other content
                                that are protected by copyright, trademark, or
                                other laws of the United States and foreign
                                countries. RTRO and its licensors exclusively
                                own all right, title, and interest in and to the
                                Service and Collective Content, including all
                                associated intellectual property rights, except
                                as expressly provided in these Terms. You agree
                                not to modify, copy, frame, scrape, rent, lease,
                                loan, sell, distribute, remove, obscure, or
                                create derivative works based on any copyright,
                                trademark, service mark, or other proprietary
                                right contained on, incorporated in, or
                                accompanying the Service or Collective Content.
                            </li>
                            <li>
                                All trademarks, service marks, logos, trade
                                names, and other proprietary designations of
                                RTRO (the "RTRO Trademarks") displayed on the
                                Service or Collective Content are trademarks or
                                registered trademarks of RTRO. Any other
                                trademarks, service marks, logos, trade names,
                                and other proprietary designations displayed on
                                the Service or Collective Content may be the
                                trademarks or registered trademarks of their
                                respective owners. Nothing in these Terms should
                                be construed as granting, by implication,
                                estoppel, or otherwise, any license or right to
                                use any of the RTRO Trademarks, without our
                                prior written permission in each instance. All
                                goodwill generated from the use of RTRO
                                Trademarks will inure to RTRO's exclusive
                                benefit.
                            </li>
                            <li>
                                Subject to your compliance with these Terms,
                                RTRO grants you a limited, non-exclusive,
                                non-transferable license, without the right to
                                sublicense, to access, view, and print any
                                Collective Content solely for your personal and
                                non-commercial purposes. You will not use, copy,
                                adapt, modify, prepare derivative works based
                                upon, distribute, license, sell, transfer,
                                publicly display, publicly perform, transmit, or
                                otherwise exploit the Service or Collective
                                Content, except as expressly permitted by these
                                Terms. No licenses or rights are granted to you
                                by implication or otherwise under any
                                intellectual property rights owned or controlled
                                by RTRO or its licensors, except for the
                                licenses and rights expressly granted in these
                                Terms. Any violation of the foregoing will
                                constitute a breach of these Terms and may
                                result in immediate termination of your right to
                                use the Service.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>USER CONTENT FROM REGISTERED USERS</b>
                        <ol type="a">
                            <li>
                                As a Registered User of the RTRO Service, you
                                may post, upload, publish, submit, and transmit
                                Registered User Content. By making any
                                Registered User Content available through the
                                Service, you grant RTRO and its users a
                                worldwide, irrevocable, perpetual,
                                non-exclusive, transferable, royalty-free
                                license, with the right to sublicense, to use,
                                copy, adapt, modify, distribute, license, sell,
                                transfer, publicly display, publicly perform,
                                transmit, and otherwise exploit such Registered
                                User Content. RTRO does not claim ownership
                                rights in any such Registered User Content, and
                                these Terms do not restrict any rights you may
                                have to use and exploit any such Registered User
                                Content. You are solely responsible for all
                                Registered User Content that you make available
                                through the Service. Therefore, you represent
                                and warrant that:
                                <ol type="i">
                                    <li>
                                        you are the sole and exclusive owner of
                                        all Registered User Content that you
                                        make available through the Service or
                                        have all rights, licenses, consents, and
                                        releases that are necessary to grant the
                                        rights in such Registered User Content
                                        as contemplated under these Terms; and
                                    </li>
                                    <li>
                                        neither the Registered User Content nor
                                        your posting, uploading, publication,
                                        submission, or transmittal of the
                                        Registered User Content, or RTRO's or
                                        its users' use of the Registered User
                                        Content (or any portion thereof), will
                                        infringe, misappropriate, or violate a
                                        third party's patent, copyright,
                                        trademark, trade secret, moral rights,
                                        or other intellectual property rights,
                                        or rights of publicity or privacy, or
                                        result in the violation of any
                                        applicable law or regulation.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>SERVICE FEEDBACK</b>
                        <ol type="a">
                            <li>
                                We value and appreciate your feedback, comments,
                                and suggestions for improving the RTRO Service
                                ("Feedback"). You can submit Feedback by
                                contacting us support@rtro.com. You acknowledge
                                and agree that all Feedback will be the sole and
                                exclusive property of RTRO, and you hereby
                                assign all your right, title, and interest in
                                and to all Feedback, including without
                                limitation all worldwide patent rights,
                                copyright rights, trade secret rights, and other
                                proprietary or intellectual property rights, to
                                RTRO. At our request and expense, you will
                                execute documents and take such further actions
                                as we may reasonably request to assist us in
                                acquiring, perfecting, and maintaining RTRO's
                                intellectual property rights and other legal
                                protections for the Feedback.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>GENERAL LIST OF PROHIBITIONS</b>
                        <ol type="a">
                            <li>
                                When using the RTRO Service, you agree that you
                                will not do any of the following:
                                <ol type="i">
                                    <li>
                                        Upload, post, submit, publish, or
                                        transmit any content which: (a) Affects
                                        the rights of publicity or privacy of
                                        another by infringing, misappropriating,
                                        or violating their patents, copyrights,
                                        trademarks, trade secrets, or other
                                        intellectual property rights; (b)
                                        engages in conduct in violation of any
                                        applicable law or regulation, or that is
                                        likely to result in civil liability; (e)
                                        misleading, fraudulent, false or
                                        deceptive; (d) contains defamatory,
                                        offensive, pornographic, or vulgar
                                        material; (e) Promotes discrimination,
                                        bigotry, racism, hatred, harassment, or
                                        harm against anyone or any group; (f)
                                        promotes illegal or harmful activities
                                        or substances; or (g) is violent or
                                        threatening, or promotes violence or
                                        threatening actions against another
                                        person.
                                    </li>
                                    <li>
                                        Without our express written consent, you
                                        may not mirror, display, mirror, use, or
                                        frame the Service or any of its
                                        individual elements (such as the layout
                                        and design of any page), RTRO's name,
                                        any RTRO Trademark, or any other
                                        proprietary information of RTRO.
                                    </li>
                                    <li>
                                        Unauthorized access to, tampering with,
                                        or use of non-public areas of the
                                        Service, our computer system, or those
                                        of our providers without our explicit
                                        written permission is prohibited.
                                    </li>
                                    <li>
                                        Attempt to probe, scan, or test any RTRO
                                        system or network for vulnerability or
                                        breach any security or authentication
                                        measures.
                                    </li>
                                    <li>
                                        Attempt to avoid, bypass, disable,
                                        deactivate, impair, descramble, or
                                        otherwise circumvent any technical
                                        measures implemented by RTRO or any of
                                        its providers or any third party
                                        (including other users) to protect the
                                        Service or Collective Content.
                                    </li>
                                    <li>
                                        Attempt to access or search the Service
                                        or Collective Content, or download
                                        Collective Content from the Service,
                                        using any software, tool, agent, device
                                        or mechanism other than the software
                                        and/or search agents provided by RTRO or
                                        other generally available third-party
                                        web browsers (including spiders, robots,
                                        crawlers, data mining tools or similar).
                                    </li>
                                    <li>
                                        Use any meta tags or other hidden text
                                        or metadata utilizing a RTRO Trademark,
                                        RTRO logo URL, or RTRO product name
                                        without our express written consent.
                                    </li>
                                    <li>
                                        Use the RTRO app for any commercial
                                        purpose that benefits a third party or
                                        in any way that violates these Terms.
                                    </li>
                                    <li>
                                        RTRO prohibits you from using any
                                        Collective Content for commercial
                                        purposes, for a third party's benefit,
                                        or in any way or manner that does not
                                        comply with these Terms.
                                    </li>
                                    <li>
                                        Modify or manipulate any TCP/IP packet
                                        header or any part of it in any e-mail
                                        or newsgroup posting, or use the RTRO to
                                        send altered, misleading, or false
                                        information that could identify the
                                        source.
                                    </li>
                                    <li>
                                        Attempt to decipher, decompile,
                                        disassemble, or reverse engineer any of
                                        the software used to provide the RTRO
                                        Service.
                                    </li>
                                    <li>
                                        Attempt to interfere with the access of
                                        any user, host, or network on RTRO,
                                        including sending a virus, overloading,
                                        flooding, spamming, or mail-bombing
                                    </li>
                                    <li>
                                        Any action which unreasonably or
                                        disproportionately overloads RTRO's
                                        infrastructure or interferes with the
                                        proper functioning of the Service, as
                                        determined by RTRO, at their sole
                                        discretion
                                    </li>
                                    <li>
                                        Any personally identifiable information
                                        about other users of RTRO without their
                                        express permission should not be
                                        collected or stored from the Service
                                    </li>
                                    <li>
                                        Falsify your affiliation with any
                                        individual or entity when using the RTRO
                                        app
                                    </li>
                                    <li>
                                        Violate any applicable law or
                                        regulation; xvii. Any other act that, in
                                        our sole discretion, we determine
                                        misuses or negatively impacts the
                                        Service;
                                    </li>
                                    <li>
                                        Encourage or enable any individual to do
                                        any of the foregoing.
                                    </li>
                                    <li>
                                        To the maximum extent permitted by law,
                                        RTRO reserves the right to investigate
                                        and prosecute any violations of the
                                        above terms. Any user violating these
                                        terms may be prosecuted by us in
                                        collaboration with law enforcement
                                        authorities. As a Registered User, you
                                        acknowledge and agree that we have the
                                        right, but not the obligation, to
                                        monitor your use of the Service or
                                        Registered User Content to ensure your
                                        compliance with the terms, operate the
                                        service, or be in compliance with
                                        applicable laws, court orders, or
                                        governmental body requirements. If we,
                                        at our sole discretion, consider
                                        Collective Content, including Registered
                                        User Content, to be objectionable,
                                        harmful to the Service, or in violation
                                        of these Terms, we may remove or
                                        restrict access to it without prior
                                        notice
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>LINK</b>
                        <ol type="a">
                            <li>
                                Through the Service, RTRO may provide links to
                                third-party websites or resources. It is
                                important to note, however, that we don't take
                                responsibility for the availability or accuracy
                                of these websites or resources. Moreover, we
                                disclaim any liability for any content,
                                products, or services found on these websites or
                                resources. The inclusion of links to these
                                websites or resources does not imply endorsement
                                by RTRO of their content or services. You
                                acknowledge and assume all risk and
                                responsibility for any actions you take when you
                                access third-party websites or resources through
                                the Service.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>FEES</b>
                        <ol type="a">
                            <li>
                                While RTRO registration is free, fees may be
                                charged for certain transactions on the
                                platform. Our fee structure can be viewed at
                                www.rtro.com/fees and is also incorporated into
                                these Terms by reference. Some or all of these
                                fees may be changed or discontinued, temporarily
                                or permanently (including for promotional
                                events). We will post information about such
                                changes through the platform once they become
                                effective. Our Fee Policy may also be changed at
                                any time and at our sole discretion, and any
                                such changes will be effective upon posting to
                                the platform or upon written notification to any
                                user.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>Trading on RTRO</b>
                        <ol type="a">
                            <li>
                                Traders
                                <ol type="i">
                                    <li>
                                        All Contracts for the exchange of goods
                                        on the RTRO platform are solely between
                                        the two parties exchanging items, and
                                        RTRO serves only as a facilitator of the
                                        transaction. Although RTRO may suggest
                                        certain trades or provide an estimated
                                        market value on items, we do not warrant
                                        these values as fair market values and
                                        only provide them as a reference. The
                                        final valuation decisions are left to
                                        the discretion of the two parties
                                        involved in the trade. RTRO does not set
                                        prices or act as an auctioneer. As
                                        described in greater detail below, RTRO
                                        charges a broker fee at RTRO's then
                                        current rates for every trade you
                                        execute using the service.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Terms of Trading</b>
                                <ol type="i">
                                    <li>
                                        <u>Offer</u>
                                        <ol type="1">
                                            <li>
                                                Offers made are Binding. Any
                                                user who sends offers for items
                                                to other users, including
                                                trading items for cash, or
                                                offering items for trade or any
                                                combination of thereof (an
                                                "Offer"), such Offer is binding
                                                upon the user making the Offer
                                                (the "Offeror") unless and until
                                                (1) it is revoked by the Offeror
                                                prior to being "Accepted"
                                                (defined below) by the user to
                                                whom the Offer is made (the
                                                "Offeree"), (2) it is "Rejected"
                                                (defined below) by the Offeree,
                                                or (3) the Offeree submits a
                                                "Counteroffer" (defined below).
                                            </li>
                                            <li>
                                                As offers are binding when made,
                                                Offerors are required to review
                                                payment and shipping information
                                                and acknowledge their
                                                willingness to complete the
                                                trade if accepted.
                                            </li>
                                            <li>
                                                items added to your closet{" "}
                                                <ol type="a">
                                                    <li>
                                                        When adding an item to
                                                        your virtual closet, or
                                                        otherwise posting it,
                                                        solicits trade offers
                                                        for your item. To ensure
                                                        that your listings are
                                                        accurate and up-to-date,
                                                        please review them
                                                        frequently.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Acceptance</b>
                                <ol type="i">
                                    <li>
                                        If the Offeree accepts any Offer, the
                                        Offer shall be considered "Accepted,"
                                        and both the Offeror and Offeree are
                                        required to fulfill the transaction, and
                                        a contract will be created between them.
                                        Both parties are obligated to consummate
                                        the transaction, and the Offeror will be
                                        charged automatically. Upon the Offer
                                        being accepted, either or both parties
                                        must ship their respective items
                                        promptly to RTRO for authentication in
                                        accordance with these Terms. The
                                        transaction will only be deemed complete
                                        upon the authentication of all items
                                        covered by the transaction pursuant to
                                        Section 5 below.
                                    </li>
                                    <li>
                                        If you or any user sends any other user
                                        an offer for any item, including
                                        offering items for trade, cash, or any
                                        combination of thereof (an "Offer"),
                                        such Offer is binding upon the user
                                        making the Offer (the "Offeror") unless
                                        and until (1) it is revoked by the
                                        Offeror prior to being "Accepted"
                                        (defined below) by the user to whom the
                                        Offer is made (the "Offeree"), (2) it is
                                        "Rejected" (defined below) by the
                                        Offeree, or (3) the Offeree submits a
                                        "Counteroffer" (defined below).
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Rejection.</b> Any Offer may be rejected by
                                the Offeree for any reason. Upon rejection, the
                                original Offer ceases to be binding.
                                <ol type="i">
                                    <li>
                                        <b>
                                            <u>Counteroffer.</u>
                                        </b>{" "}
                                        A counteroffer will result if the
                                        offeree proposes any different terms in
                                        response to an offer. As a result, a
                                        counteroffer serves as both a rejection
                                        and a new offer that modifies the
                                        original offer materially. By serving as
                                        a rejection, a counteroffer completely
                                        nullifies the original offer.
                                        Consequently, the original proposal
                                        cannot be accepted.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>Authenticity Check</b>
                        <ol type="a">
                            <li>
                                Listing for sale or trade acknowledges and
                                agrees that the item or items will be sent to
                                RTRO upon sale or trade agreement, where they
                                will undergo RTRO's Authenticity Check process.
                            </li>
                            <li>
                                By agreeing to sell or trade with another user
                                on RTRO, you must send the item(s) to RTRO
                                within the allotted time frame for
                                authentication and inspection using the provided
                                shipping label.
                                <ol type="i">
                                    <li>
                                        <b>
                                            RTRO retaining ownership and rights
                                            to photographs:
                                        </b>
                                        <ol type="1">
                                            <li>
                                                Items received by RTRO for
                                                authentication and inspection,
                                                may also be photographed for use
                                                by RTRO or its licensees as part
                                                of RTRO's image catalog or for
                                                any other purpose, at the sole
                                                discretion of RTRO.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Authenticity check process</b>
                                        <ol type="1">
                                            <li>
                                                In keeping with our goal to only
                                                sell authentic items, we conduct
                                                a multilayered authenticity
                                                check and inspection process in
                                                pursuit of our aim that every
                                                item sold or traded on RTRO is
                                                authentic and in the condition
                                                described in the listing.
                                            </li>
                                            <li>
                                                Consequently, all items before
                                                they are shipped to the end
                                                customer undergo a multilayered
                                                authenticity and inspection
                                                process to verify they meet
                                                RTRO's rigorous authenticity
                                                check and quality standard
                                            </li>
                                            <li>
                                                In order to meet RTRO's rigorous
                                                authenticity checks, we employ
                                                both digital authentication and
                                                in-person verification and
                                                inspection, which are conducted
                                                by our in-house authentication
                                                team with extensive hands-on
                                                training and inspections as well
                                                as third-party agents who have
                                                been thoroughly vetted.
                                            </li>
                                            <li>
                                                In keeping with our rigorous
                                                authenticity, we will provide
                                                one of three results: authentic,
                                                inconclusive, or replica.
                                                <ol type="1">
                                                    <li>
                                                        <b>Inconclusive:</b>{" "}
                                                        items that cannot be
                                                        positively identified as
                                                        authentic are marked as
                                                        "Inconclusive."
                                                        <ol type="a">
                                                            <li>
                                                                Item in trade
                                                                deemed
                                                                inconclusive: If
                                                                any item
                                                                involved in a
                                                                trade is deemed
                                                                inconclusive,
                                                                RTRO will cancel
                                                                the entire
                                                                transaction and
                                                                all items will
                                                                be shipped back
                                                                to the parties,
                                                                with RTRO
                                                                holding the
                                                                right to charge
                                                                and retain the
                                                                platform fee as
                                                                compensation for
                                                                authenticating
                                                                and shipping
                                                                back all items,
                                                                which will be
                                                                determined by
                                                                RTRO alone.
                                                                <ol type="i">
                                                                    <li>
                                                                        While
                                                                        Cash
                                                                        payments
                                                                        made as
                                                                        part of
                                                                        the
                                                                        trade
                                                                        will be
                                                                        returned
                                                                        to the
                                                                        user who
                                                                        provided
                                                                        the
                                                                        payment
                                                                        according
                                                                        to the
                                                                        third
                                                                        party
                                                                        payment
                                                                        processor's
                                                                        process.
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                            <li>
                                                                Items in a sale
                                                                deemed
                                                                inconclusive:
                                                                Upon inspection
                                                                of an item in a
                                                                sale, RTRO will
                                                                cancel the
                                                                entire
                                                                transaction and
                                                                return the item
                                                                to its original
                                                                parties, but
                                                                reserves the
                                                                right to charge
                                                                the parties for
                                                                authenticating
                                                                and shipping all
                                                                items back to
                                                                the original
                                                                parties, which
                                                                will be
                                                                determined
                                                                solely by RTRO
                                                            </li>
                                                        </ol>
                                                    </li>

                                                    <li>
                                                        <b>
                                                            Replica/counterfit:
                                                        </b>
                                                        <ol type="a">
                                                            <li>
                                                                <b>
                                                                    Item in
                                                                    trade deemed
                                                                    counterfeit/replica:
                                                                </b>
                                                                <ol type="i">
                                                                    <li>
                                                                        If, upon
                                                                        inspection,
                                                                        any of
                                                                        the
                                                                        items in
                                                                        a trade
                                                                        are
                                                                        determined
                                                                        to be a
                                                                        replica
                                                                        or
                                                                        counterfeit,
                                                                        RTRO
                                                                        will
                                                                        cancel
                                                                        the
                                                                        entire
                                                                        trade
                                                                        transaction,
                                                                        returning
                                                                        all
                                                                        items to
                                                                        the
                                                                        original
                                                                        parties
                                                                        - while
                                                                        reserving
                                                                        the
                                                                        right to
                                                                        retain
                                                                        all
                                                                        platform
                                                                        fees as
                                                                        compensation
                                                                        for
                                                                        authenticity
                                                                        check
                                                                        and
                                                                        shipping
                                                                        all
                                                                        items
                                                                        back to
                                                                        the
                                                                        original
                                                                        parties,
                                                                        which
                                                                        will be
                                                                        determined
                                                                        solely
                                                                        by RTRO.
                                                                    </li>
                                                                    <li>
                                                                        While
                                                                        Cash
                                                                        payments
                                                                        made as
                                                                        part of
                                                                        the
                                                                        trade
                                                                        will be
                                                                        returned
                                                                        to the
                                                                        user who
                                                                        provided
                                                                        the
                                                                        payment
                                                                        according
                                                                        to the
                                                                        payment
                                                                        processor's
                                                                        process.
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    Items in a
                                                                    sale that
                                                                    are deemed
                                                                    counterfeit/replica
                                                                </b>
                                                                <ol type="i">
                                                                    <li>
                                                                        In the
                                                                        event
                                                                        that any
                                                                        of the
                                                                        items in
                                                                        a sale
                                                                        are
                                                                        determined
                                                                        to be
                                                                        replicas
                                                                        or
                                                                        counterfeits,
                                                                        RTRO
                                                                        will
                                                                        cancel
                                                                        the
                                                                        entire
                                                                        order
                                                                        and will
                                                                        return
                                                                        the item
                                                                        at the
                                                                        owner's
                                                                        expense
                                                                        within a
                                                                        reasonable
                                                                        timeframe.
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    Waiver of
                                                                    liability:
                                                                </b>
                                                                <ol type="i">
                                                                    <li>
                                                                        RTRO
                                                                        provides
                                                                        a
                                                                        platform
                                                                        for
                                                                        traders
                                                                        to
                                                                        transact
                                                                        with
                                                                        each
                                                                        other,
                                                                        so
                                                                        despite
                                                                        RTRO's
                                                                        comprehensive
                                                                        authenticity
                                                                        check
                                                                        and
                                                                        inspection
                                                                        process,
                                                                        trading
                                                                        on
                                                                        secondary
                                                                        market
                                                                        platforms
                                                                        always
                                                                        involves
                                                                        some
                                                                        inherent
                                                                        risk and
                                                                        cannot
                                                                        be
                                                                        mitigated.
                                                                        While we
                                                                        strive
                                                                        to
                                                                        ensure
                                                                        that the
                                                                        items
                                                                        you
                                                                        purchase
                                                                        and
                                                                        trade
                                                                        for, are
                                                                        both
                                                                        authentic
                                                                        and as
                                                                        described,
                                                                        it is
                                                                        acknowledge
                                                                        and
                                                                        agreed
                                                                        that, by
                                                                        using
                                                                        the
                                                                        service
                                                                        RTRO
                                                                        will
                                                                        have no
                                                                        liability
                                                                        for
                                                                        counterfeit
                                                                        items
                                                                        which
                                                                        were
                                                                        deemed
                                                                        as
                                                                        authentic
                                                                        or
                                                                        authentic
                                                                        items
                                                                        which
                                                                        were
                                                                        rejected
                                                                    </li>
                                                                    <li>
                                                                        Items
                                                                        may also
                                                                        be
                                                                        photographed
                                                                        for use
                                                                        by RTRO
                                                                        or its
                                                                        licensees
                                                                        as part
                                                                        of
                                                                        either
                                                                        parties
                                                                        image
                                                                        catalog
                                                                        or for
                                                                        any
                                                                        other
                                                                        purpose,
                                                                        at
                                                                        RTRO's
                                                                        sole
                                                                        discretion.
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                            <li>
                                                                <b>
                                                                    Expected
                                                                    time for
                                                                    authentication
                                                                </b>
                                                                <ol type="i">
                                                                    <li>
                                                                        {" "}
                                                                        In most
                                                                        cases,
                                                                        an item
                                                                        will
                                                                        take 2-3
                                                                        business
                                                                        days for
                                                                        RTRO to
                                                                        authenticate
                                                                        after it
                                                                        has been
                                                                        sold and
                                                                        received.
                                                                    </li>
                                                                    <li>
                                                                        Items
                                                                        that are
                                                                        more
                                                                        difficult
                                                                        to
                                                                        process
                                                                        through
                                                                        our
                                                                        authenticity
                                                                        check,
                                                                        may
                                                                        require
                                                                        extra
                                                                        precautions
                                                                        and
                                                                        steps -
                                                                        which
                                                                        may
                                                                        extend
                                                                        the
                                                                        normal
                                                                        timeline
                                                                        for
                                                                        authentication.
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                        </ol>
                                                    </li>

                                                    <li>
                                                        Any disputes related to
                                                        this section are subject
                                                        to our Dispute Policies,
                                                        which can be found at{" "}
                                                        <a
                                                            href="https://www.rtro.com/dispute-policy"
                                                            style={linkStyle}>
                                                            https://www.rtro.com/dispute-policy
                                                        </a>{" "}
                                                        and are subject to
                                                        change at RTRO's sole
                                                        discretion without
                                                        notice.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>TERMINATION AND ACCOUNT CANCELLATION</b>
                        <ol type="a">
                            <li>
                                If you violate any of these Terms, the RTRO may,
                                at our sole discretion and without prior notice,
                                suspend or disable your Account, or terminate
                                these Terms as they apply to you. We reserve the
                                right to revoke your access to and use of the
                                Service and Collective Content at any time, with
                                or without cause. When you register to join the
                                Service and create an Account, you may choose
                                any name to identify yourself to other
                                Registered Users. However, using a false name
                                may result in the suspension or disabling of
                                your Account, or the termination of these Terms,
                                in our sole discretion and without prior notice
                                to you. If we terminate these Terms for your
                                breach, revoke your access to and use of the
                                Service, or terminate or discontinue the Service
                                and consequently these Terms, you will remain
                                liable for all amounts due under these Terms.
                                You may cancel your Account at any time by
                                contacting us.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>SURVIVAL</b>
                        <ol type="a">
                            <li>
                                NOTWITHSTANDING ANY OTHER PROVISION OF THESE
                                TERMS, ANY PROVISION OF THESE TERMS THAT IMPOSES
                                OR CONTEMPLATES CONTINUING OBLIGATIONS ON YOU OR
                                GOAT WILL SURVIVE THE TERMINATION OF THESE
                                TERMS.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>DISCLAIMERS</b>
                        <ol type="a">
                            <li>
                                The RTRO Service and Collective Content are
                                provided on an "as is' ' and "as available"
                                basis, without any warranty of any kind, whether
                                express or implied. RTRO specifically disclaims
                                any warranty of merchantability, fitness for a
                                particular purpose, quiet enjoyment,
                                non-infringement, or any other warranty arising
                                from course of dealing or usage of trade. RTRO
                                does not guarantee that the Service or
                                Collective Content will meet your requirements,
                                or that they will be uninterrupted, secure, or
                                error-free. RTRO does not guarantee that defects
                                will be corrected or that the Service is free of
                                viruses or other harmful components. RTRO makes
                                no warranty regarding the quality of any
                                services or Collective Content, or anything else
                                purchased or obtained through the Service, or
                                the accuracy, timeliness, truthfulness,
                                completeness, or reliability of any Collective
                                Content obtained through the Service.
                            </li>
                            <li>
                                RTRO reserves the right to periodically amend,
                                change, update, and alter the Service without
                                notice. RTRO assumes no liability or
                                responsibility for any errors or omissions in
                                the content of the Service. RTRO specifically
                                disclaims any duty to update the content of, or
                                any other information on, the Service. No advice
                                or information, whether oral or written,
                                obtained from RTRO or through the Service or
                                Collective Content, will create any warranty not
                                expressly made herein.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>INDEMNITY</b>
                        <ol type="a">
                            <li>
                                You agree to defend, indemnify, and hold RTRO
                                and its officers, directors, employees, agents,
                                and representatives harmless from and against
                                any claims, liabilities, damages, losses, and
                                expenses, including, without limitation,
                                reasonable legal and accounting fees, arising
                                out of or in any way connected with your access
                                to or use of the Service or Collective Content
                                or your violation of these Terms. RTRO reserves
                                the right to assume all or any part of the
                                defense of any such claims and negotiations for
                                settlement, and you agree to fully cooperate
                                with us in doing so.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>LIMITATION OF LIABILITY</b>
                        <ol type="a">
                            <li>
                                When using the RTRO Service and accessing the
                                Collective Content, you agree that the entire
                                risk associated with your access and use of the
                                Service and Collective Content remains with you
                                to the maximum extent permitted by law. RTRO,
                                and any other parties involved in creating,
                                producing, or delivering the Service or
                                Collective Content, will not be liable for any
                                compensatory, consequential, incidental,
                                indirect, special, exemplary, or punitive
                                damages, including lost profits, loss of data or
                                loss of goodwill, service interruption, computer
                                damage or system failure, or the cost of
                                substitute products or services, or for any
                                damages for personal or bodily injury or
                                emotional distress, arising out of or in
                                connection with: (i) these Terms or from the use
                                of or inability to use the Service or Collective
                                Content; (ii) the conduct of you or anyone else
                                in connection with the use of the Service or
                                Collective Content; (iii) any communications,
                                interactions, or meetings with other users of
                                the Service or Collective Content or other
                                persons with whom you communicate or interact as
                                a result of your use of the Service or
                                Collective Content; (iv) your visit to a venue
                                resulting from your use of the Service or
                                Collective Content; (v) pricing, shipping,
                                format, or other guidance provided by RTRO; (vi)
                                delays or disruptions in the Service; (vii)
                                viruses or other malicious software obtained by
                                accessing the Service or those of any site,
                                services, or tools linked to the Service; (viii)
                                glitches, bugs, errors, or inaccuracies of any
                                kind in the Service; (ix) damage to your
                                hardware device from the use of the Service; (x)
                                the content, actions, or inactions of third
                                parties, including items listed using the
                                Service or the destruction of allegedly fake
                                items; (xi) a suspension or other action taken
                                with respect to your account; (xii) the duration
                                or manner in which items you list appear on the
                                Service; or (xiii) your need to modify
                                practices, content, or behavior, or your loss of
                                ability to do business, as a result of changes
                                to these Terms or our policies. This applies
                                whether based on warranty, contract, tort
                                (including negligence), product liability, or
                                any other legal theory, and whether or not RTRO
                                has been informed of the possibility of such
                                damage, even if a limited remedy set forth
                                herein is found to have failed of its essential
                                purpose. You acknowledge that RTRO shall not be
                                liable to you for your actions or communications
                                using the Service or in connection with the
                                Service. RTRO shall not be liable for any breach
                                of security associated with the transmission of
                                information through the Service or any linked
                                sites, or for any information or products
                                obtained through use of the Service or otherwise
                                arising out of the use of the same, regardless
                                of the form of action or basis of any claim.
                            </li>
                            <li>
                                In some jurisdictions, limitations or exclusions
                                of liability may not be permitted. If such
                                restrictions are prohibited in your
                                jurisdiction, RTRO's aggregate liability will be
                                limited to the fullest extent permitted by
                                applicable law in accordance with these Terms.
                            </li>
                            <li>
                                RTRO's liability to you arising out of or in
                                connection with these Terms or the use of the
                                Service or Collective Content will not exceed
                                the amounts you have paid to RTRO for the use of
                                the Service or Collective Content in the twelve
                                (12) months prior to the action giving rise to
                                the liability or one hundred dollars ($100.00),
                                whichever is greater. The limitations of damages
                                are essential parts of the agreement between you
                                and RTRO
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>CONTROLLING LAW AND JURISDICTION</b>
                        <ol type="a">
                            <li>
                                These Terms and any action related to these
                                Terms will be governed by the laws of the State
                                of California without regard to its conflict of
                                laws provisions. The exclusive jurisdiction and
                                venue of any action to prevent the actual or
                                threatened infringement, misappropriation, or
                                violation of a party's copyrights, trademarks,
                                trade secrets, patents, or other intellectual
                                property rights will be the state and federal
                                courts located in the Central District of
                                California, and each of you and RTRO waives any
                                objection to jurisdiction and venue in such
                                courts. If the agreement to arbitrate set forth
                                below under the heading "Dispute Resolution" is
                                found not to apply to you or to a particular
                                dispute, claim, or controversy, either as a
                                result of your decision to opt out of the
                                agreement to arbitrate or as a result of a
                                decision by the arbitrator or a court order, the
                                exclusive jurisdiction and venue for the
                                resolution of such dispute, claim, or
                                controversy will be the state and federal courts
                                located in the Central District of California,
                                and each of you and RTRO waives any objection to
                                jurisdiction and venue in such courts.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>CALIFORNIA USER NOTICES</b>
                        <ol type="a">
                            <li>
                                Under California Civil Code Section 1789.3,
                                California users of the Service are entitled to
                                the following specific consumer rights notice:
                                The Complaint Assistance Unit of the Division of
                                Consumer Services of the California Department
                                of Consumer Affairs may be contacted in writing
                                at 1020 N Street, #501, Sacramento, California
                                95814, or by telephone at 1-916-445-1254.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>ENTIRE AGREEMENT</b>
                        <ol type="a">
                            <li>
                                These Terms represent the complete and exclusive
                                understanding and agreement between you and RTRO
                                regarding the Service and Collective Content,
                                and these Terms prevail over and replace any
                                prior oral or written understandings or
                                agreements between you and RTRO concerning the
                                Service and Collective Content.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>ASSIGNMENT</b>
                        <ol type="a">
                            <li>
                                You may not transfer or assign these Terms, in
                                whole or in part, without the prior written
                                consent of RTRO. Any attempted transfer or
                                assignment without such consent will be null and
                                void. RTRO may transfer or assign these Terms at
                                any time without your consent. These Terms will
                                be binding upon and inure to the benefit of you,
                                RTRO, and your and RTRO's respective successors
                                and permitted assigns, subject to the foregoing.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>NOTICES</b>
                        <ol type="a">
                            <li>
                                Any notices or other communications permitted or
                                required hereunder, including those regarding
                                modifications to these Terms, will be in writing
                                and given by RTRO: (i) via email to the address
                                that you provide to us or (ii) by posting to the
                                App. For notices made by e-mail, the date of
                                receipt will be deemed the date on which such
                                notice is transmitted.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>DISPUTE RESOLUTION</b>
                        <ol type="a">
                            <li>
                                You and RTRO agree that any dispute, claim, or
                                controversy that has arisen, or may arise,
                                between you and RTRO out of or relating to these
                                Terms (including previous versions of these
                                Terms) or the breach, termination, enforcement,
                                interpretation, or validity thereof, your use of
                                or access to the Service, the actions of RTRO or
                                its agents, or any products or services sold,
                                offered, or purchased through the Service
                                (collectively, "Disputes") shall be settled by
                                final and binding arbitration, except that each
                                party retains the right to bring an individual
                                action in small claims court and the right to
                                seek injunctive or other equitable relief in a
                                court of competent jurisdiction to prevent the
                                actual or threatened infringement,
                                misappropriation, or violation of a party's
                                copyrights, trademarks, trade secrets, patents,
                                or other intellectual property rights.
                            </li>
                            <li>
                                <b>
                                    PROHIBITION OF CLASS AND REPRESENTATIVE
                                    ACTIONS AND NON-INDIVIDUALIZED RELIEF
                                </b>
                                <ol type="i">
                                    <li>
                                        You and RTRO agree that any claims or
                                        disputes between you and RTRO will be
                                        resolved through binding arbitration,
                                        except for claims or disputes seeking to
                                        protect RTRO's intellectual property
                                        rights, which may be brought in court.
                                        You and RTRO also agree that each of us
                                        may only bring claims against the other
                                        on an individual basis and not as a
                                        plaintiff or class member in any
                                        purported class or representative
                                        proceeding. The arbitrator may not
                                        consolidate or join more than one
                                        person's claims, and may only award
                                        relief in favor of the individual
                                        seeking relief and only to the extent
                                        necessary to provide relief necessitated
                                        by that party's individual claim(s). Any
                                        relief awarded cannot affect other third
                                        parties. If a court decides that
                                        applicable law precludes enforcement of
                                        any of these limitations as to a
                                        particular claim for relief, then that
                                        claim (and only that claim) must be
                                        severed from the arbitration and may be
                                        brought in court, subject to your and
                                        RTRO's right to appeal the court's
                                        decision. All other claims will be
                                        arbitrated.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>ARBITRATION RULES AND GOVERNING LAW</b>
                                <ol type="i">
                                    <li>
                                        Arbitration of any Disputes between you
                                        and RTRO shall be administered by the
                                        American Arbitration Association ("AAA")
                                        and will be governed by the Consumer
                                        Arbitration Rules (the "AAA Rules") then
                                        in effect, except as modified herein.
                                        (The AAA Rules are available at
                                        www.adr.org/rules or by calling the AAA
                                        at 1-800-778-7879.) If the AAA is
                                        unavailable for the arbitration, the
                                        parties will select an alternative
                                        private neutral arbitrator to conduct
                                        the arbitration in accordance with the
                                        AAA Rules. The use of the word
                                        "arbitrator" in this "Dispute
                                        Resolution" section shall not be
                                        construed to prohibit more than one
                                        arbitrator from presiding over an
                                        arbitration; rather, the AAA Rules will
                                        govern the number of arbitrators that
                                        may preside over an arbitration
                                        conducted under this "Dispute
                                        Resolution" section. The Federal
                                        Arbitration Act will govern the
                                        interpretation and enforcement of this
                                        "Dispute Resolution" section.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    ARBITRATION PROCESS AND SELECTION OF
                                    ARBITRATOR
                                </b>
                                <ol type="i">
                                    <li>
                                        If either party wants to start
                                        arbitration, they must send a written
                                        Demand for Arbitration to the other
                                        party by mail, following the
                                        specifications set forth in the AAA
                                        Rules. The AAA provides a form Demand
                                        for Arbitration on their website. Unless
                                        agreed otherwise by the parties, the
                                        arbitration will take place in the
                                        county where the user resides within a
                                        reasonable timeframe. Each party may be
                                        represented by a spokesperson of their
                                        choosing at their own expense. If the
                                        user's claim does not exceed $10,000,
                                        the arbitration may be conducted by
                                        telephone or solely based on the
                                        documents submitted by both parties to
                                        the arbitrator, unless a hearing is
                                        requested or the arbitrator deems a
                                        hearing necessary. If the claim exceeds
                                        $10,000, the right to a hearing will be
                                        determined by the AAA Rules. The
                                        arbitrator, subject to the AAA Rules,
                                        will have discretion to direct an
                                        appropriate exchange of information
                                        between the parties, consistent with the
                                        expedited nature of the arbitration.
                                    </li>
                                    <li>
                                        If the AAA is not available for the
                                        arbitration and an alternative private
                                        neutral arbitrator needs to be selected,
                                        the following process will be followed:
                                        RTRO and you will each provide a list of
                                        three preferred private arbitrators, and
                                        if there is a common name on both lists,
                                        that person will be appointed as the
                                        arbitrator. If there are multiple common
                                        names, the parties will act in good
                                        faith to select one of them. If there
                                        are no common names, the first
                                        arbitrator on each list will confer and
                                        appoint a third arbitrator who will
                                        conduct the proceeding as the Presiding
                                        Arbitrator. If the parties agree to have
                                        more than one arbitrator preside over
                                        the arbitration, the Presiding
                                        Arbitrator will select additional
                                        arbitrators, taking into account any
                                        common names on the submitted lists.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>ARBITRATOR'S DECISION</b>
                                <ol type="i">
                                    <li>
                                        The arbitrator will make a decision
                                        within the timeframe set out in the AAA
                                        Rules. The arbitrator's decision will
                                        contain the essential findings and
                                        conclusions on which the arbitrator
                                        based the award. The arbitrator's
                                        decision is final and binding but may be
                                        reviewed in accordance with applicable
                                        laws governing arbitration decisions. A
                                        judgment on the arbitration award may be
                                        entered in any court with jurisdiction.
                                        If you win in arbitration, you will be
                                        entitled to an award of attorneys' fees
                                        and expenses to the extent permitted by
                                        applicable law.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>FEE'S</b>
                        <ol type="a">
                            <li>
                                The arbitrator will make a decision within the
                                timeframe set out in the AAA Rules. The
                                arbitrator's decision will contain the essential
                                findings and conclusions on which the arbitrator
                                based the award. The arbitrator's decision is
                                final and binding but may be reviewed in
                                accordance with applicable laws governing
                                arbitration decisions. A judgment on the
                                arbitration award may be entered in any court
                                with jurisdiction. If you win in arbitration,
                                you will be entitled to an award of attorneys'
                                fees and expenses to the extent permitted by
                                applicable law.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>CHANGES</b>
                        <ol type="a">
                            <li>
                                Regardless of the terms in the "Modification"
                                section above, if the RTRO modifies this
                                "Dispute Resolution" section after you first
                                accepted these Terms (or accepted any subsequent
                                changes to these Terms), you have the option to
                                reject any such modification by sending us
                                written notice (including by contacting us)
                                within 30 days of the date the modification
                                became effective, as indicated in the "Last
                                Updated Date" above or in the date of our email
                                to you notifying you of such change. By
                                rejecting any modification, you agree to resolve
                                any Dispute between you and RTRO through
                                arbitration in accordance with the provisions of
                                this "Dispute Resolution" section as of the date
                                you first accepted these Terms (or accepted any
                                subsequent changes to these Terms).
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>SEVERABILITY</b>
                        <ol type="a">
                            <li>
                                If a court or arbitrator determines that any
                                portion of this "Dispute Resolution" section,
                                excluding the Prohibition of Class and
                                Representative Actions and Non-Individualized
                                Relief subsection, is invalid or unenforceable,
                                the remaining portions of this section will
                                remain in effect.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>Opt-Out</b>
                        <ol type="a">
                            <li>
                                As a user of the RTRO app, you have the option
                                to choose not to participate in the arbitration
                                process. If you decide to opt-out, neither you
                                nor RTRO can compel the other party to engage in
                                an arbitration proceeding. To opt-out, you must
                                provide us with written notice within 30 days of
                                the date you first became subject to this
                                arbitration provision. The address to opt-out
                                is:
                                <ol type="i">
                                    <li>
                                        RTRO Attn: Legal Dept. - Arbitration
                                        Opt-out 5900 Smiley Drive, Culver City,
                                        CA 90232
                                    </li>
                                    <li>
                                        To opt out of this arbitration
                                        agreement, you must include your name,
                                        residence address, and email address.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <b>WAIVER: SEVERABILITY</b>
                        <ol type="a">
                            <li>
                                Any failure by RTRO to enforce any provision of
                                these Terms shall not be deemed a waiver of
                                future enforcement of that provision. Any waiver
                                of any provision of these Terms will be
                                effective only if it is in writing and signed by
                                an authorized representative of RTRO. The
                                exercise of any remedy under these Terms by
                                either party shall be without prejudice to any
                                other remedies available to such party under
                                these Terms or at law. If any provision of these
                                Terms is found by a court of competent
                                jurisdiction to be invalid or unenforceable, the
                                parties agree that the court should endeavor to
                                give effect to the parties' intentions as
                                reflected in the provision, and the other
                                provisions of these Terms shall remain in full
                                force and effect.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>

            <div style={divStyle}>
                <b>QUESTIONS</b>
            </div>

            <div style={divStyle}>
                If you have any questions about these Terms or the Service,
                please contact us at{" "}
                <a href="mailto:admin@rtro.com" style={linkStyle}>
                    admin@rtro.com
                </a>
            </div>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    pageStyle: {
        margin: "50px auto",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        width: "90%",
        maxWidth: "1200px",
    } as React.CSSProperties,
    divStyle: {
        marginBottom: "20px",
    } as React.CSSProperties,
    linkStyle: {
        color: colors.INDIGO,
        textDecoration: "none",
    } as React.CSSProperties,
});
