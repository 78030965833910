import React from "react";
import TeamMember from "./TeamMember";
import { colors } from "../../Utils/colors";
import { useMiscStore } from "../../state/store";

interface TeamSectionProps {}

const auth7 = require("../../images/auth_7.jpg");
const auth8 = require("../../images/auth_8.jpg");
const auth9 = require("../../images/auth_9.jpg");

const TeamSection: React.FC<TeamSectionProps> = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.GREY_0,
        dark: colors.DARKGREY_14,
    });
    const textColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });

    const teamMembers = [
        {
            name: `Nick Mendez`,
            title: `HEAD OF SNEAKERS`,
            bio: `Nike Talk and SoleCollector were the beginning of a passion for Nick that turned into two decades in the industry while overseeing operations for Flight Club New York, LA and Miami.`,
            imageSrc: auth9,
        },
        {
            name: `Mel Nunez`,
            title: `HEAD OF AUTHENTICATION`,
            bio: `From the city that started it all, Mel knows what real sneakerheads care about. Bringing his expertise as head of authentication at Flight Club New York for over 10 years, he helps maintain RTRO's industry leading authentication and quality control standards.`,
            imageSrc: auth7,
        },
        {
            name: `George Coles`,
            title: `AUTHENTICATION LEAD`,
            bio: `As an OG collector and our Lead Authenticator, George joined the team after tenured careers with household names such as NiceKicks and GOAT to add to RTRO's accredited roster.`,
            imageSrc: auth8,
        },
    ];

    return (
        <section className="team-section">
            <h2 className="team-title" style={{ color: textColor }}>
                About us
            </h2>
            <div className="team-members">
                <div className="member-list">
                    {teamMembers.map((member, index) => (
                        <TeamMember key={index} {...member} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TeamSection;
