import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cryptojs from "crypto-js";
import { NetworkCode } from "../Utils/network";
import { useMiscStore } from "../state/store";
import { colors } from "../Utils/colors";

export default function UnsubscribeScreen() {
    const { search } = useLocation();

    const getEmailSettings = useMiscStore((state) => state.getEmailSettings);
    const saveEmailSettings = useMiscStore((state) => state.saveEmailSettings);
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    useMiscStore((state) => state.theme); // listen to theme change

    const [isValidLink, setIsValidLink] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [emailSettings, setEmailSettings] = useState<any>({});
    const [updateMessage, setUpdateMessage] = useState<string>("");
    const [updateType, setUpdateType] = useState<string>("");
    const [showUpdateMessage, setShowUpdateMessage] = useState<boolean>(false);

    useEffect(() => {
        // no query params or crypto secret passed in env for decryption
        if (!search || (process.env.REACT_APP_CRYPTO_SECRET || "") === "") {
            setLoading(false);
            return;
        }
        const code = new URLSearchParams(search).get("code") ?? "";
        // no code param
        if (code === "") {
            setLoading(false);
            return;
        }

        // decrypt code
        const bytes = cryptojs.AES.decrypt(
            code,
            process.env.REACT_APP_CRYPTO_SECRET || "",
        );
        const decryptedEmail = bytes.toString(cryptojs.enc.Utf8);
        // no email after decrypt
        if (!decryptedEmail) {
            setLoading(false);
            return;
        }
        setEmail(decryptedEmail);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (email) loadEmailSettings(email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    // get current email settings from DB
    const loadEmailSettings = async (email: string) => {
        const response = await getEmailSettings(email);
        setLoading(false);
        if (response.status === NetworkCode.OK) {
            setEmailSettings(response.data?.emailSettings);
            setIsValidLink(true);
        }
    };

    const handleUpdateEmailSettings = async () => {
        const response = await saveEmailSettings(email, emailSettings);
        if (response.status === NetworkCode.OK) {
            setUpdateMessage("Email settings updated successfully.");
            setUpdateType("success");
        } else {
            setUpdateMessage("Something went wrong. Please try again later.");
            setUpdateType("error");
        }

        setShowUpdateMessage(true);
        setTimeout(() => {
            setShowUpdateMessage(false);
        }, 3000);
    };

    const {
        titleStyle,
        textStyle,
        buttonStyle,
        emailTypeRow,
        emailLabelStyle,
        checkboxStyle,
    } = styles(colors, getThemeColor);

    return (
        <div>
            {loading ? (
                <div style={textStyle}>Loading...</div>
            ) : (
                <div>
                    {!isValidLink || Object.keys(emailSettings).length === 0 ? (
                        <div>
                            <h1 style={titleStyle}>Invalid unsubscribe link</h1>
                            <p style={textStyle}>
                                The link you have clicked is invalid or has
                                expired.
                            </p>
                        </div>
                    ) : (
                        <div>
                            <h1 style={titleStyle}>Email Settings</h1>
                            <p style={textStyle}>
                                You can choose which emails to receive and not
                                receive by adjusting the toggles below.
                            </p>
                            {updateMessage && showUpdateMessage && (
                                <div
                                    style={{
                                        color:
                                            updateType === "success"
                                                ? "green"
                                                : "red",
                                        backgroundColor:
                                            updateType === "success"
                                                ? "lightgreen"
                                                : "pink",
                                        padding: "10px",
                                        margin: "10px 0",
                                        width: "fit-content",
                                        borderRadius: "5px",
                                    }}>
                                    {updateMessage}
                                </div>
                            )}
                            {Object.keys(emailSettings).map((key) => {
                                return (
                                    <div key={key} style={emailTypeRow}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                style={checkboxStyle}
                                                value={emailSettings[key]}
                                                checked={emailSettings[key]}
                                                onChange={() => {
                                                    setEmailSettings({
                                                        ...emailSettings,
                                                        [key]: !emailSettings[
                                                            key
                                                        ],
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={emailLabelStyle}
                                            onClick={() => {
                                                setEmailSettings({
                                                    ...emailSettings,
                                                    [key]: !emailSettings[key],
                                                });
                                            }}>
                                            {key}
                                        </div>
                                    </div>
                                );
                            })}
                            <button
                                style={buttonStyle}
                                onClick={handleUpdateEmailSettings}>
                                Update Email Settings
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    titleStyle: {
        fontSize: "40px",
        fontWeight: "700",
        margin: "20px auto",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    textStyle: {
        fontSize: "18px",
        fontWeight: "400",
        margin: "20px auto",
        color: colors.GREY_6,
    } as React.CSSProperties,
    buttonStyle: {
        backgroundColor: "#4B45FF",
        color: "#ffffff",
        borderRadius: "24px",
        padding: "12px 24px",
        border: "none",
        fontSize: "18px",
        fontWeight: "500",
        letterSpacing: "-0.03em",
        lineHeight: "140%",
        cursor: "pointer",
        textDecoration: "none",
        margin: "20px auto",
    } as React.CSSProperties,
    emailTypeRow: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        alignItems: "center",
        padding: "10px 0",
        marginBottom: "10px",
        width: "100%",
        maxWidth: "300px",
    } as React.CSSProperties,
    emailLabelStyle: {
        fontSize: "18px",
        fontWeight: "400",
        color: colors.GREY_6,
        cursor: "pointer",
    } as React.CSSProperties,
    checkboxStyle: {
        cursor: "pointer",
    } as React.CSSProperties,
});
