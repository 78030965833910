import React, { useEffect, useState } from "react";
import "./App.css";
import { useMediaQuery } from "react-responsive";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";
const appstore_btn = require("./../../images/appstore_btn.png");
const google_play_btn = require("./../../images/google_play_btn.png");

const images = [
    require("./../../images/landing/Air Jordan 1 Retro High OG UNC Toe 1.png"),
    require("./../../images/landing/Air Jordan 4 Retro Thunder.png"),
    require("./../../images/landing/Nike Concepts Air Max 1 SP Heavy.png"),
    require("./../../images/landing/Nike Dunk Low Viotech 2019.png"),
    require("./../../images/landing/Nike HUF SB Dunk Low New York.png"),
    require("./../../images/landing/Nike Off-White Air Max 90 Black.png"),
    require("./../../images/landing/Nike SB Dunk Low Rayssa Leal.png"),
    require("./../../images/landing/Union LA Air Jordan 4 Guava Ice.png"),
    require("./../../images/landing/Without front-camera.png"),
];

const cardImages = [
    // Replace these with your actual card image paths or any content you want in the cards
    require("./../../images/landing/slide1.png"),
    require("./../../images/landing/slide2.png"),
    require("./../../images/landing/slide3.png"),
    require("./../../images/landing/slide4.png"),
    require("./../../images/landing/slide5.png"),
    require("./../../images/landing/slide6.png"),
];

function Carousel() {
    return (
        <div className="carousel">
            <div className="carousel-track">
                {cardImages.map((src, index) => (
                    <div key={index} className="carousel-card">
                        <img src={src} alt={`card-${index}`} />
                    </div>
                ))}
                {cardImages.map((src, index) => (
                    <div
                        key={index + cardImages.length}
                        className="carousel-card">
                        <img src={src} alt={`card-${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default function Billboard() {
    const [showPhone, setShowPhone] = useState(false);
    const [showText, setShowText] = useState(false);
    useEffect(() => {
        const sneakerCount = images.length - 1; // excluding the phone image
        const delay = 0;
        setTimeout(() => {
            setShowPhone(true);
        }, delay);
        setTimeout(() => {
            setShowText(true);
        }, 1500);
    }, []);

    const calculateWidth = (index: number) => {
        const minSize = 150;
        const maxSize = 300;
        return minSize + (index % 8) * ((maxSize - minSize) / 7);
    };

    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const { btnContainerStyle, googlePlayBtnStyle, appstoreBtnStyle } = style(
        colors,
        getThemeColor,
        isTabletOrMobile,
    );

    return (
        <div className="App">
            <div className="container">
                {images.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`image-${index}`}
                        className={`floating-image ${
                            index === images.length - 1
                                ? showPhone
                                    ? "phone-image fade-in "
                                    : "hidden"
                                : "sneaker"
                        }`}
                        style={{
                            animationDelay: `${index * 0.3 - index * 0.2}s`,
                            transform: `rotate(${index * 10}deg)`,
                            maxWidth:
                                index === images.length - 1
                                    ? "300px"
                                    : `${calculateWidth(index)}px`,
                        }}
                    />
                ))}
                <div
                    className={` ${showText ? "title-quick" : "hidden"}`}
                    style={{
                        position: "absolute",
                        width: "100%",
                        bottom: 130,
                    }}>
                    <div className="black-ellipse" />
                </div>
                <div
                    className={` ${showText ? "title-quick" : "hidden"}`}
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <div className="header-text">
                        Trade your sneakers
                        <br />
                        and streetwear
                    </div>
                    <div
                        style={{
                            paddingTop: 42,
                            paddingBottom: 42,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 12,
                        }}>
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.2147 2.0664C12.8155 1.66862 12.1698 1.66862 11.7706 2.0664L9.07283 4.75474C8.90955 4.91744 8.69611 5.02023 8.4671 5.04644L4.68324 5.4795C4.12335 5.54358 3.72075 6.04842 3.78287 6.60853L4.20265 10.3939C4.22806 10.623 4.17534 10.8539 4.05305 11.0493L2.03243 14.2777C1.73345 14.7554 1.87713 15.3849 2.35377 15.6856L5.57501 17.7175C5.76997 17.8405 5.91768 18.0257 5.99419 18.2431L7.25838 21.8357C7.44544 22.3673 8.02721 22.6475 8.55945 22.4623L12.1565 21.2107C12.3742 21.135 12.6111 21.135 12.8288 21.2107L16.4258 22.4623C16.9581 22.6475 17.5399 22.3673 17.7269 21.8357L18.9911 18.2431C19.0676 18.0257 19.2153 17.8405 19.4103 17.7175L22.6315 15.6856C23.1082 15.3849 23.2518 14.7554 22.9529 14.2777L20.9322 11.0493C20.8099 10.8539 20.7572 10.623 20.7826 10.3939L21.2024 6.60853C21.2645 6.04842 20.8619 5.54358 20.3021 5.4795L16.5182 5.04644C16.2892 5.02023 16.0757 4.91744 15.9125 4.75474L13.2147 2.0664ZM15.9117 8.99929L11.5591 13.3508L9.02026 10.8119L7.56873 12.2624L11.5591 16.2528L17.3622 10.4498L15.9117 8.99929Z"
                                fill="#8A86FF"
                            />
                        </svg>
                        <div className="subheader-text">
                            All items 100% authenticated by hand
                        </div>
                    </div>
                    <div style={btnContainerStyle}>
                        <a
                            href={
                                "https://play.google.com/store/apps/details?id=com.rtro"
                            }
                            target="_blank"
                            rel="noreferrer">
                            <img
                                src={google_play_btn}
                                alt="google play button"
                                style={googlePlayBtnStyle}
                            />
                        </a>
                        <a
                            href={
                                "https://apps.apple.com/us/app/rtro-trading-marketplace/id1661563424"
                            }
                            target="_blank"
                            rel="noreferrer">
                            <img
                                src={appstore_btn}
                                alt="appstore button"
                                style={appstoreBtnStyle}
                            />
                        </a>
                    </div>
                </div>
            </div>
            {/* <div className="second-section">
                <div className="recent-trades">
                    <div className="header-text">
                        Recent trades on the community
                    </div>
                    <div
                        style={{
                            paddingTop: 42,
                            paddingBottom: 42,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 12,
                            zIndex: 10,
                        }}>
                        <svg
                            width="26"
                            height="25"
                            viewBox="0 0 26 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="closet-coach-hanger">
                                <path
                                    id="Vector"
                                    d="M11.2915 6.07043C10.5652 6.93597 10.6781 8.22639 11.5436 8.95266L11.5535 8.96102L23.2257 19.0089C24.3041 19.9372 23.6475 21.7061 22.2246 21.7061L3.49009 21.706C2.06717 21.706 1.41067 19.9371 2.48906 19.0088L10.1136 12.4454L11.6815 13.7951L4.86819 19.6602L20.8465 19.6603L10.2233 10.5154C8.49707 9.06205 8.27321 6.48471 9.72428 4.75539C11.1768 3.02431 13.7577 2.79852 15.4888 4.25106C16.4526 5.05982 16.9498 6.21836 16.9504 7.38525H14.9046C14.9042 6.80184 14.6556 6.22262 14.1737 5.81826C13.3082 5.09199 12.0178 5.20489 11.2915 6.07043Z"
                                    fill="#8A86FF"
                                />
                            </g>
                        </svg>

                        <div className="subheader-text">
                            Join now and increase your collection
                        </div>
                    </div>
                    <div className="radius-card"></div>
                </div>
                <Carousel />
            </div> */}
        </div>
    );
}

const style = (colors: any, getThemeColor: any, isTabletOrMobile: boolean) => ({
    containerStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_1,
            dark: colors.DARKGREY_14,
        }),
        paddingTop: 16,
        marginTop: 24,
        borderTop: `1px solid ${getThemeColor({
            light: colors.GREY_3,
            dark: colors.GREY_9,
        })}`,
        width: "100%",
    } as React.CSSProperties,
    divStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_1,
            dark: colors.DARKGREY_14,
        }),
        borderRadius: "16px",
        padding: "20px 0px",
        paddingBottom: "0px",
        display: "flex",
        flexDirection: isTabletOrMobile ? "column" : "row",
        alignItems: isTabletOrMobile ? "flex-start" : "center",
        gap: isTabletOrMobile ? "20px" : "33px",
        width: "90%",
        maxWidth: "1200px",
        margin: "auto",
    } as React.CSSProperties,
    textStyle: {
        flex: 1,
        fontSize: "9px",
        fontWeight: "700",
        letterSpacing: "0.22em",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    linkStyle: {
        fontSize: "12px",
        cursor: "pointer",
        fontWeight: "700",
        textDecoration: "underline",
        color: getThemeColor({
            light: colors.GREY_6,
            dark: colors.GREY_6,
        }),
    } as React.CSSProperties,
    btnContainerStyle: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
    } as React.CSSProperties,
    googlePlayBtnStyle: {
        width: isTabletOrMobile ? "125px" : "150px",
    } as React.CSSProperties,
    appstoreBtnStyle: {
        width: isTabletOrMobile ? "108px" : "130px",
    } as React.CSSProperties,
});
