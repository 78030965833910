import { Navigate } from "react-router-dom";
import { useUserStore } from "../state/store";

const LoggedInRedirect = ({ children }: { children: JSX.Element }) => {
    const user = useUserStore((state) => state.user);
    if (user) {
        let link = "/upload";
        return <Navigate to={link} replace />;
    }
    return <>{children}</>;
};
export default LoggedInRedirect;
