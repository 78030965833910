import React, { useState } from "react";
import { useMiscStore } from "../state/store";
import { RiSunLine, RiMoonLine } from "react-icons/ri";
import { colors } from "../Utils/colors";

export default function ThemeSwitcher() {
    const theme = useMiscStore((state) => state.theme);
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const [isDarkMode, setIsDarkMode] = useState<boolean>(theme === "dark");

    const toggleTheme = () => {
        if (isDarkMode) {
            setIsDarkMode(false);
            localStorage.setItem("theme", "light");
            useMiscStore.setState({ theme: "light" });
        } else {
            setIsDarkMode(true);
            localStorage.setItem("theme", "dark");
            useMiscStore.setState({ theme: "dark" });
        }
    };

    const { divStyle, moonStyle, sunStyle } = styles(
        colors,
        getThemeColor,
        isDarkMode,
    );

    return (
        <div style={divStyle} onClick={toggleTheme}>
            <div style={moonStyle}>
                <RiMoonLine
                    size={16}
                    color={getThemeColor({
                        light: colors.GREY_6,
                        dark: colors.INDIGO,
                    })}
                />
            </div>
            <div style={sunStyle}>
                <RiSunLine
                    size={16}
                    color={getThemeColor({
                        light: colors.INDIGO,
                        dark: colors.GREY_6,
                    })}
                />
            </div>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any, isDarkMode: boolean) => ({
    divStyle: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: getThemeColor({
            light: colors.GREY_4,
            dark: colors.DARKGREY_12,
        }),
        borderRadius: 20,
        padding: "2px",
    } as React.CSSProperties,
    moonStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_4,
            dark: colors.DARKGREY_16,
        }),
        borderRadius: 20,
        padding: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as React.CSSProperties,
    sunStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_12,
        }),
        borderRadius: 20,
        padding: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as React.CSSProperties,
});
