import React from "react";
import { useMediaQuery } from "react-responsive";
import { useMiscStore } from "../state/store";
import { colors } from "../Utils/colors";
import { useNavigate } from "react-router-dom";

const rtro_logo = require("../images/rtro-logo-new-light.png");
const rtro_logo_white = require("../images/rtro-logo-new-dark.png");
const appstore_btn = require("../images/appstore_btn.png");
const google_play_btn = require("../images/google_play_btn.png");

export default function Footer() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const theme = useMiscStore((state) => state.theme);
    const year = new Date().getFullYear();

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const navigate = useNavigate();

    const {
        containerStyle,
        divStyle,
        linkStyle,
        textStyle,
        btnContainerStyle,
        googlePlayBtnStyle,
        appstoreBtnStyle,
    } = style(colors, getThemeColor, isTabletOrMobile);

    return (
        <div style={containerStyle}>
            <div style={divStyle}>
                <a href="/">
                    <img
                        src={theme === "light" ? rtro_logo : rtro_logo_white}
                        alt="RTRO logo"
                        style={{ width: 140 }}
                    />
                </a>
                <div style={textStyle}></div>
            </div>
            <div style={{ ...divStyle, paddingBottom: 32 }}>
                <div
                    style={{
                        ...linkStyle,
                        textDecoration: "none",
                        cursor: "auto",
                    }}>
                    ©{year}
                </div>
                <a onClick={() => navigate("/history")} style={linkStyle}>
                    Our History
                </a>
                <a onClick={() => navigate("/faq")} style={linkStyle}>
                    FAQ
                </a>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: isTabletOrMobile ? "20px" : "33px",
                    }}>
                    <a onClick={() => navigate("/terms")} style={linkStyle}>
                        Terms
                    </a>
                    <a onClick={() => navigate("/privacy")} style={linkStyle}>
                        Privacy
                    </a>
                    <a onClick={() => navigate("/login")} style={linkStyle}>
                        Upload
                    </a>
                    <a
                        onClick={() => navigate("/guidelines")}
                        style={linkStyle}>
                        Guidelines
                    </a>
                    <a onClick={() => navigate("/contact")} style={linkStyle}>
                        Contact
                    </a>
                </div>
            </div>
        </div>
    );
}

const style = (colors: any, getThemeColor: any, isTabletOrMobile: boolean) => ({
    containerStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_1,
            dark: colors.DARKGREY_14,
        }),
        paddingTop: 16,
        marginTop: 24,
        borderTop: `1px solid ${getThemeColor({
            light: colors.GREY_3,
            dark: colors.GREY_9,
        })}`,
        width: "100vw",
    } as React.CSSProperties,
    divStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_1,
            dark: colors.DARKGREY_14,
        }),
        borderRadius: "16px",
        padding: "20px 0px",
        paddingBottom: "0px",
        display: "flex",
        flexDirection: isTabletOrMobile ? "column" : "row",
        alignItems: isTabletOrMobile ? "flex-start" : "center",
        gap: isTabletOrMobile ? "20px" : "33px",
        width: "90%",
        maxWidth: "1200px",
        margin: "auto",
    } as React.CSSProperties,
    textStyle: {
        flex: 1,
        fontSize: "9px",
        fontWeight: "700",
        letterSpacing: "0.22em",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    linkStyle: {
        fontSize: "12px",
        cursor: "pointer",
        fontWeight: "700",
        textDecoration: "underline",
        color: getThemeColor({
            light: colors.GREY_6,
            dark: colors.GREY_6,
        }),
    } as React.CSSProperties,
    btnContainerStyle: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center",
    } as React.CSSProperties,
    googlePlayBtnStyle: {
        width: isTabletOrMobile ? "125px" : "150px",
    } as React.CSSProperties,
    appstoreBtnStyle: {
        width: isTabletOrMobile ? "108px" : "130px",
    } as React.CSSProperties,
});
