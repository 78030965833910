import React from "react";
import { useMiscStore } from "../../../state/store";
import { colors } from "../../../Utils/colors";

const BotManagementScreen = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    return (
        <h1
            style={{
                color: getThemeColor({
                    light: colors.DARKGREY_16,
                    dark: colors.GREY_0,
                }),
            }}>
            Bot Management
        </h1>
    );
};

export default BotManagementScreen;
