import { NetworkCode } from "../requests/network";
import { pxFetch } from "../requests/utils";
import { FetchReturn } from "./store";
import produce from "immer";

export const TRADES_PER_PAGE = 20;

export enum ETradeStatus {
    OPEN = "open",
    CLOSED = "closed",
    FINALIZED = "finalized", // A trade is finalized when both users finalize their offers with pictures and pay
    COMPLETED = "completed", // An admin marks a trade as completed when the items are recieved and are authenticated, and items are sent to the traders
    REJECTED = "rejected", // An admin marks a trade as rejected when the items cannot be authenticated
}

export enum EOfferStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
}

export enum ETradeItemType {
    ITEM = "item",
    MONEY = "money",
}

export enum EOfferType {
    OFFER = "offer",
    COUNTER_OFFER = "counterOffer",
}

export enum EModelGender {
    MENS = "mens",
    WOMENS = "womens",
    YOUTH = "youth",
    INFANTS = "infants",
    UNISEX = "unisex",
}

export enum ETradeFetchMethod {
    ID = "id",
    ALL = "all",
    MINE = "mine", // created by me and participated by me
    PARTICIPANT_OF = "participantOf", // created by me or participated by me or ones im involved in (all trades im in)
    CREATED_BY_ME = "createdByMe", // ONLY created by me
    DESIRED_ITEMS_MATCH = "desiredItemsMatch",
    BEST_DEAL = "bestDeal",
    DESIRED_ITEMS_MATCH_BEST_DEAL = "desiredItemsMatchBestDeal",
    MY_OFFERS = "myOffers",
    TRADES_USING_MODEL = "tradesUsingModel",
}

export enum ETradeFilter {
    ALL = "all",
    PENDING = "pending",
    CLOSED_OR_FINALIZED = "closedOrFinalized",
    COMPLETED = "completed",
}

export const getTradeFetchMethodLabel = (fetchMethod: ETradeFetchMethod) => {
    switch (fetchMethod) {
        case ETradeFetchMethod.BEST_DEAL:
            return "Best Deal";
        case ETradeFetchMethod.DESIRED_ITEMS_MATCH:
            return "Relevant Trades";
        case ETradeFetchMethod.DESIRED_ITEMS_MATCH_BEST_DEAL:
            return "Best Relevant Trades";
        case ETradeFetchMethod.ID:
            return "Specific Trade";
        case ETradeFetchMethod.MINE:
            return "My Trades";
        case ETradeFetchMethod.ALL:
        default:
            return "All Trades";
    }
};
export const getTradeFetchMethodKey = (fetchMethod: string) => {
    switch (fetchMethod) {
        case "Best Deal":
            return ETradeFetchMethod.BEST_DEAL;
        case "Relevant Trades":
            return ETradeFetchMethod.DESIRED_ITEMS_MATCH;
        case "Best Relevant Trades":
            return ETradeFetchMethod.DESIRED_ITEMS_MATCH_BEST_DEAL;
        case "Specific Trade":
            return ETradeFetchMethod.ID;
        case "My Trades":
            return ETradeFetchMethod.MINE;
        case "All Trades":
        default:
            return ETradeFetchMethod.ALL;
    }
};

interface IGetTradeProps {
    id?: string;
    fetchMethod?: ETradeFetchMethod;
    tradeFilter?: string[];
    offset?: number;
    limit?: number;
    otherUserId?: string;
    modelSlug?: string;
}

interface IGetTradeDetailsProps {
    tradeId: string;
    recipientId: string;
}

interface IAddTradeOfferProps {
    tradeId: string;
    recipientId: string;
    items: any[];
}

interface IUpdateOfferStatusProps {
    tradeId: string;
    recipientId: string;
    status: EOfferStatus;
}

export interface ITradesState {
    // States
    exploreTrades: any[];
    adminTrades: any[];
    exploreSortBy: ETradeFetchMethod;
    exploreTradesPage: number;
    adminTradesPage: number;
    exploreTradesLoading: boolean;
    adminTradesLoading: boolean;
    myTrades: any[];
    myTradesPage: number;
    pendingOffers: any[];
    numPendingOffers: number;

    //////////////////////////
    // State management
    //////////////////////////
    getExploreTrades: (reset?: boolean) => Promise<FetchReturn>;
    getAdminTrades: (reset?: boolean) => Promise<FetchReturn>;
    setExploreSortBy: (sortBy: ETradeFetchMethod) => void;
    getMyTrades: (reset?: boolean, userId?: string) => Promise<FetchReturn>;

    //////////////////////////
    // Apis
    //////////////////////////
    createTrade: (data: any) => Promise<FetchReturn>;
    updateTrade: (tradeId: string, data: any) => Promise<FetchReturn>;
    getTrades: (data: any) => Promise<FetchReturn>;
    getTradeDetails: (data: any) => Promise<FetchReturn>;
    addOffer: (data: any) => Promise<FetchReturn>;
    viewOffer: (data: any) => Promise<FetchReturn>;
    cancelOffer: (data: any) => Promise<FetchReturn>;
    addOfferRequest: (data: any) => Promise<FetchReturn>;
    getOfferRequests: (tradeId: string) => Promise<FetchReturn>;
    answerOfferRequest: (data: any) => Promise<FetchReturn>;
    updateOfferStatus: (data: any) => Promise<FetchReturn>;
    getPendingOffers: (
        limit: number,
        offset: number,
        reset?: boolean,
    ) => Promise<FetchReturn>;
    finalizeTrade: (
        tradeId: string,
        recipientId: string,
        stripePaymentIntentId: any,
    ) => Promise<FetchReturn>;
    reopenTrade: (data: any) => Promise<FetchReturn>;
    removeTrade: (data: any) => Promise<FetchReturn>;
    getSimilarTrades: (data: any) => Promise<FetchReturn>;
    getAdminTradesRequest: (data?: any) => Promise<FetchReturn>;
    adminCompleteTrade: (
        tradeId: string,
        status: ETradeStatus,
        notes?: string,
    ) => Promise<FetchReturn>;

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearTradesStore: () => void;
}

export const tradesStore = (
    set: any,
    get: () => ITradesState,
    api: any,
): ITradesState => ({
    // States
    exploreTrades: [],
    adminTrades: [],
    exploreTradesPage: 0,
    adminTradesPage: 0,
    exploreSortBy: ETradeFetchMethod.ALL,
    exploreTradesLoading: false,
    adminTradesLoading: false,
    myTrades: [],
    myTradesPage: 0,
    pendingOffers: [],
    numPendingOffers: 0,

    //////////////////////////
    // State management
    //////////////////////////
    getExploreTrades: async (reset: boolean = false) => {
        try {
            set(() => ({
                exploreTradesLoading: true,
            }));
            const existingTrades = get().exploreTrades;
            const page = reset ? 0 : get().exploreTradesPage;
            const response = await get().getTrades({
                fetchMethod: get().exploreSortBy,
                offset: page * TRADES_PER_PAGE,
                limit: TRADES_PER_PAGE,
            });
            if (
                response.status === NetworkCode.OK &&
                Array.isArray(response?.data?.trades)
            ) {
                const tradesWereFound = response.data.trades.length > 0;
                set(
                    () => ({
                        exploreTrades: reset
                            ? response.data.trades
                            : [...existingTrades, ...response.data.trades],
                        exploreTradesPage: tradesWereFound ? page + 1 : page,
                    }),
                    false,
                    "getExploreTrades",
                );
            }

            // Set loading to false
            set(() => ({
                exploreTradesLoading: false,
            }));

            return response;
        } catch (e) {
            console.log(e);
            set(
                () => ({
                    exploreTradesLoading: false,
                }),
                false,
                "getExploreTrades2",
            );
        }

        return {
            status: NetworkCode.INTERNAL_SERVER_ERROR,
            message: "Something went wrong while loading trades.",
        };
    },

    getMyTrades: async (reset?: boolean) => {
        if (reset) {
            set(() => ({
                myTradesPage: 0,
            }));
        }
        const response = await get().getTrades({
            fetchMethod: ETradeFetchMethod.MINE,
            offset: get().myTradesPage * TRADES_PER_PAGE,
            limit: TRADES_PER_PAGE,
        });
        if (
            response.status === NetworkCode.OK &&
            Array.isArray(response?.data?.trades)
        ) {
            set(
                produce((state: any) => {
                    state.myTrades = reset
                        ? response.data.trades
                        : [...state.myTrades, ...response.data.trades];
                    state.myTradesPage = state.myTradesPage + 1;
                }),
                false,
                "getMyTrades",
            );
        }
        return response;
    },
    setExploreSortBy: (sortBy: ETradeFetchMethod) => {
        set(() => ({
            exploreSortBy: sortBy,
        }));
    },

    //////////////////////////
    // Apis
    //////////////////////////

    createTrade: async (data: any) => {
        const response = await pxFetch.post("/trade", {
            ...data,
        });
        if (response.status === NetworkCode.OK) {
            // Refetch explore trade list and my trade list after adding a trade
            get().getExploreTrades(true);
            get().getMyTrades();
        }
        return response;
    },
    updateTrade: async (tradeId: string, data: any) => {
        const response = await pxFetch.put(`/trade/${tradeId}`, {
            ...data,
        });
        if (response.status === NetworkCode.OK) {
            // Refetch explore trade list and my trade list after adding a trade
            get().getExploreTrades(true);
            get().getMyTrades();
        }
        return response;
    },
    getTrades: async ({
        id,
        fetchMethod,
        tradeFilter,
        limit,
        offset,
        otherUserId,
        modelSlug,
    }: IGetTradeProps) => {
        let response;
        // Fetch by specific ID or fetch type
        if (id !== undefined) {
            response = await pxFetch.get(`/trade/${id}`);
        } else {
            const tradefetchMethod = fetchMethod ?? ETradeFetchMethod.ALL;
            const offsetAndLimit =
                limit !== undefined && offset !== undefined
                    ? `/${limit}/${offset}`
                    : "";
            response = await pxFetch.get(
                `/trade/${tradefetchMethod}${offsetAndLimit}/?${
                    otherUserId ? `userId=${otherUserId}&` : ""
                }${modelSlug ? `modelSlug=${modelSlug}&` : ""}${
                    tradeFilter ? `tradeFilter=${tradeFilter}&` : ""
                }`,
            );
        }
        return response;
    },
    getTradeDetails: async ({
        tradeId,
        recipientId,
    }: IGetTradeDetailsProps) => {
        // Fetch by specific ID and recipient ID
        const response = await pxFetch.get(
            `/trade/id/${tradeId}/recipient/${recipientId}`,
        );
        return response;
    },
    addOffer: async ({ tradeId, recipientId, items }: IAddTradeOfferProps) => {
        // Add trade offer
        const response = await pxFetch.post(`/trade/offer`, {
            tradeId,
            recipientId,
            items,
        });
        return response;
    },
    viewOffer: async ({ tradeId, recipientId }: any) => {
        // View trade offer
        const response = await pxFetch.post(`/trade/offer/view`, {
            tradeId,
            recipientId,
        });
        return response;
    },
    cancelOffer: async ({ tradeId, recipientId }: any) => {
        // Cancel trade offer
        const response = await pxFetch.post(`/trade/offer/cancel`, {
            tradeId,
            recipientId,
        });
        return response;
    },
    addOfferRequest: async (data: any) => {
        const response = await pxFetch.post("/trade/offer/request", data);
        return response;
    },
    getOfferRequests: async (tradeId: string) => {
        const response = await pxFetch.get(`/trade/offer/requests/${tradeId}`);
        return response;
    },
    answerOfferRequest: async (data: any) => {
        const response = await pxFetch.post(
            "/trade/offer/request/answer",
            data,
        );
        return response;
    },
    updateOfferStatus: async ({
        tradeId,
        recipientId,
        status,
    }: IUpdateOfferStatusProps) => {
        const response = await pxFetch.put("/trade/status", {
            tradeId,
            recipientId,
            status,
        });
        return response;
    },
    getPendingOffers: async (
        limit: number,
        offset: number,
        reset: boolean = false,
    ) => {
        const response = await pxFetch.get(
            `/offers/pending?limit=${limit}&offset=${offset}`,
        );
        if (response.status === NetworkCode.OK) {
            set(() => ({
                pendingOffers: reset
                    ? response.data?.pendingOffers
                    : [...get().pendingOffers, ...response.data?.pendingOffers],
                numPendingOffers: response.data.count,
            }));
        }
        return response;
    },
    finalizeTrade: async (
        tradeId: string,
        recipientId: string,
        stripePaymentIntentId: any,
    ) => {
        const response = await pxFetch.post("/trade/finalize", {
            tradeId,
            recipientId,
            stripePaymentIntentId,
        });
        return response;
    },
    reopenTrade: async (data: any) => {
        const response = await pxFetch.post("/trade/reopen", {
            tradeId: data.tradeId,
        });
        return response;
    },
    removeTrade: async (data: any) => {
        const response = await pxFetch.delete(`/trade/${data.tradeId}`);
        if (response.status === NetworkCode.OK) {
            // Refetch explore trade list and my trade list after removing a trade
            get().getExploreTrades(true);
            get().getMyTrades();
        }
        return response;
    },
    getSimilarTrades: async (data: any) => {
        const response = await pxFetch.get(
            `/trade/similar/${data?.tradeId}/${data?.limit}/${data?.offset}/`,
        );
        return response;
    },

    getAdminTradesRequest: async (data?: any) => {
        const response = await pxFetch.get(
            `/trade/finalized/${data?.limit}/${data?.offset}/`,
        );
        return response;
    },
    getAdminTrades: async (reset: boolean = false) => {
        try {
            set(() => ({
                adminTradesLoading: true,
            }));
            const existingTrades = get().adminTrades;
            const page = reset ? 0 : get().adminTradesPage;
            const response = await get().getAdminTradesRequest({
                fetchMethod: get().exploreSortBy,
                offset: page * TRADES_PER_PAGE,
                limit: TRADES_PER_PAGE,
            });
            if (
                response.status === NetworkCode.OK &&
                Array.isArray(response?.data?.trades)
            ) {
                const tradesWereFound = response.data.trades.length > 0;
                set(
                    () => ({
                        adminTrades: reset
                            ? response.data.trades
                            : [...existingTrades, ...response.data.trades],
                        adminTradesPage: tradesWereFound ? page + 1 : page,
                    }),
                    false,
                    "getAdminTrades",
                );
            }

            // Set loading to false
            set(() => ({
                adminTradesLoading: false,
            }));

            return response;
        } catch (e) {
            console.log(e);
            set(
                () => ({
                    adminTradesLoading: false,
                }),
                false,
                "getAdminTrades2",
            );
        }

        return {
            status: NetworkCode.INTERNAL_SERVER_ERROR,
            message: "Something went wrong while loading trades.",
        };
    },
    adminCompleteTrade: async (
        tradeId: string,
        status: ETradeStatus,
        notes?: string,
    ) => {
        const response = await pxFetch.post("/trade/complete", {
            tradeId,
            status,
            notes,
        });
        return response;
    },
    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearTradesStore: () => {
        set(() => ({
            exploreTrades: [],
            exploreTradesPage: 0,
            exploreSortBy: ETradeFetchMethod.ALL,
            exploreTradesLoading: false,
            myTrades: [],
            myTradesPage: 0,
            pendingOffers: 0,
        }));
    },
});

export default tradesStore;
