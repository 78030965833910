import { getToken } from "../Hooks/useAuth";
import { NetworkCode } from "./network";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const getFetchHeaders = async () => {
    const token = (await getToken()) || "";
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
};

export const pxFetch: any = {
    get: async (path: string) => {
        try {
            const headers = await getFetchHeaders();
            const apiVersion = "v1";
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}${apiVersion}${path}`,
                {
                    method: "GET",
                    headers: headers,
                },
            );
            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    post: async (path: string, body: any) => {
        try {
            const headers = await getFetchHeaders();
            const apiVersion = "v1";
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}${apiVersion}${path}`,
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(body, getCircularReplacer()),
                },
            );
            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    put: async (path: string, body: any) => {
        try {
            const headers = await getFetchHeaders();
            const apiVersion = "v1";
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}${apiVersion}${path}`,
                {
                    method: "PUT",
                    headers: headers,
                    body: JSON.stringify(body, getCircularReplacer()),
                },
            );
            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    delete: async (path: string, body?: any) => {
        try {
            const headers = await getFetchHeaders();
            const apiVersion = "v1";
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}${apiVersion}${path}`,
                {
                    method: "DELETE",
                    headers: headers,
                    body: body
                        ? JSON.stringify(body, getCircularReplacer())
                        : undefined,
                },
            );
            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
};
