import React from "react";
import { useMiscStore } from "../state/store";
import BottomBillboard from "./LandingScreen/BottomBillboard";
import { RiMailSendLine, RiArrowRightLine } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import { NetworkCode } from "../Utils/network";
import { colors } from "../Utils/colors";

export default function ContactUsScreen() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const sendContactEmail = useMiscStore((state) => state.sendContactEmail);

    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const {
        containerStyle,
        titleStyles,
        pillStyles,
        pillTextStyles,
        formStyle,
        nameFlexStyle,
        labelStyles,
        textInputStyles,
        textareaStyles,
        submitBtnStyles,
        btnDivStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile, disabled || loading);

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
    };

    const trySubmitContactForm = async () => {
        if (disabled || loading) return;
        console.log("submit contact form");
        setLoading(true);
        const response = await sendContactEmail({
            firstName,
            lastName,
            email,
            message,
        });
        setLoading(false);
        alert(response?.data?.friendlyMessage);
        if (response?.status === NetworkCode.OK) {
            clearForm();
        }
    };

    React.useEffect(() => {
        setDisabled(
            firstName.length === 0 ||
                lastName.length === 0 ||
                email.length === 0 ||
                message.length === 0,
        );
    }, [firstName, lastName, email, message]);

    return (
        <div style={containerStyle}>
            <h1 style={titleStyles}>Get in touch with us</h1>

            {/* form */}
            <div style={formStyle}>
                {/* pill */}
                <div style={pillStyles}>
                    <RiMailSendLine size="16" color={colors.GREY_6} />
                    <div style={pillTextStyles}>hello@rtro.com</div>
                </div>
                <div style={nameFlexStyle}>
                    <div style={{ flex: `1 0 47%` }}>
                        <label style={labelStyles}>FIRST NAME</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e: any) => setFirstName(e.target.value)}
                            style={textInputStyles}
                            placeholder={"John"}
                        />
                    </div>
                    <div style={{ flex: `1 0 46%` }}>
                        <label style={labelStyles}>LAST NAME</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e: any) => setLastName(e.target.value)}
                            style={textInputStyles}
                            placeholder={"Wick"}
                        />
                    </div>
                </div>

                <div>
                    <label style={labelStyles}>EMAIL</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        style={textInputStyles}
                        placeholder={"johnwick@example.com"}
                    />
                </div>

                <div>
                    <label style={labelStyles}>MESSAGE</label>
                    <textarea
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}
                        style={textareaStyles}
                        rows={4}
                        placeholder="Type your message..."
                    />
                </div>

                <button
                    disabled={disabled || loading}
                    onClick={trySubmitContactForm}
                    style={submitBtnStyles}>
                    <div style={btnDivStyle}>Send</div>
                    <RiArrowRightLine
                        size={32}
                        color={getThemeColor({
                            light: colors.GREY_0,
                            dark: colors.DARKGREY_16,
                        })}
                    />
                </button>
            </div>

            {/* <div>
                <BottomBillboard />
            </div> */}
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
    disabled: boolean,
) => ({
    containerStyle: {
        margin: "auto",
        width: "90%",
        maxWidth: 1200,
    } as React.CSSProperties,
    titleStyles: {
        fontSize: isTabletOrMobile ? 48 : 72,
        fontWeight: 700,
        textAlign: "center",
        margin: "50px auto",
        width: "75%",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        letterSpacing: "-2.16px",
    } as React.CSSProperties,
    pillStyles: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
        border: `1px solid ${colors.GREY_6}`,
        borderRadius: 40,
        padding: "8px 20px",
        width: "min-content",
    } as React.CSSProperties,
    pillTextStyles: {
        fontSize: 13,
        fontWeight: 600,
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    formStyle: {
        width: "100%",
        maxWidth: "500px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        marginBottom: 50,
    } as React.CSSProperties,
    nameFlexStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 32,
    } as React.CSSProperties,
    labelStyles: {
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: "Noto Sans, sans-serif",
        letterSpacing: "1.92px",
        display: "block",
        marginBottom: "8px",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    textInputStyles: {
        padding: "8px 10px",
        border: "none",
        borderBottom: `2px solid ${getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        })}`,
        width: "100%",
        background: "transparent",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        outline: "none",
    } as React.CSSProperties,
    textareaStyles: {
        padding: "16px",
        border: `2px solid ${getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        })}`,
        borderRadius: 12,
        width: "100%",
        background: "transparent",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        marginTop: 8,
        outline: "none",
    } as React.CSSProperties,
    submitBtnStyles: {
        cursor: disabled ? "not-allowed" : "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "12px 24px",
        borderRadius: 36,
        border: "none",
        background: disabled
            ? getThemeColor({
                  light: colors.GREY_4,
                  dark: colors.GREY_7,
              })
            : getThemeColor({
                  light: colors.DARKGREY_16,
                  dark: colors.GREY_0,
              }),
    } as React.CSSProperties,
    btnDivStyle: {
        fontSize: 18,
        fontWeight: 700,
        color: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_16,
        }),
    } as React.CSSProperties,
});
