import React, { useState } from "react";
import { useAuth } from "../Hooks/useAuth";
import { useMediaQuery } from "react-responsive";
import Drawer from "rc-drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useMiscStore, useUserStore } from "../state/store";
import NavbarLink from "./NavbarLink";
import ThemeSwitcher from "./ThemeSwitcher";
import { colors } from "../Utils/colors";

const rtro_logo = require("../images/rtro-logo-new-light.png");
const rtro_logo_white = require("../images/rtro-logo-new-dark.png");
const menu_fill = require("../images/menu_fill.png");
const menu_fill_white = require("../images/menu_fill_white.png");

export default function Navbar() {
    const theme = useMiscStore((state) => state.theme);
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const user = useUserStore((state) => state.user);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const { logout } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    const tryLogout = async () => {
        await logout();
        navigate("/");
    };

    const links = [
        {
            text: "HOME",
            onClick: () => navigate("/"),
            active: location.pathname === "/",
        },
        {
            text: "AUTHENTICATION",
            onClick: () => navigate("/authentication"),
            active: location.pathname === "/authentication",
        },
        {
            text: "OUR HISTORY",
            onClick: () => navigate("/history"),
            active: location.pathname === "/history",
        },
        {
            text: "FAQ",
            onClick: () => navigate("/faq"),
            active: location.pathname === "/faq",
        },
        // {
        //     text: "TERMS",
        //     onClick: () => navigate("/terms"),
        //     active: location.pathname === "/terms",
        // },
        // {
        //     text: "PRIVACY",
        //     onClick: () => navigate("/privacy"),
        //     active: location.pathname === "/privacy",
        // },
        // {
        //     text: "GUIDELINES",
        //     onClick: () => navigate("/guidelines"),
        //     active: location.pathname === "/guidelines",
        // },
        {
            text: "CONTACT",
            onClick: () => navigate("/contact"),
            active: location.pathname === "/contact",
        },
    ];
    if (user?.admin) {
        links.push({
            text: "ADMIN DASHBOARD",
            onClick: () => navigate("/dashboard/trades"),
            active: location.pathname === "/dashboard/trades",
        });
    }

    if (user) {
        links.push({
            text: "LOGOUT",
            onClick: () => {
                tryLogout();
            },
            active: false,
        });
    }

    const {
        navbarStyle,
        logoStyle,
        rightLinksStyle,
        btnStyle,
        drawerMaskStyle,
        drawerStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    return (
        <div style={navbarStyle}>
            <div>
                <a href="/">
                    <img
                        src={theme === "light" ? rtro_logo : rtro_logo_white}
                        alt="RTRO logo"
                        style={logoStyle}
                    />
                </a>
            </div>
            {isTabletOrMobile ? (
                <>
                    <div style={btnStyle} onClick={() => setDrawerOpen(true)}>
                        <img
                            src={
                                theme === "light" ? menu_fill : menu_fill_white
                            }
                            alt="menu icon"
                        />
                    </div>
                    <Drawer
                        open={drawerOpen}
                        placement="right"
                        onClose={() => setDrawerOpen(false)}
                        mask={true}
                        maskStyle={drawerMaskStyle}
                        maskClosable={true}
                        style={drawerStyle}
                        destroyOnClose={true}>
                        {links.map((link, index) => (
                            <NavbarLink key={index} {...link} />
                        ))}
                    </Drawer>
                </>
            ) : (
                <div style={rightLinksStyle}>
                    {links
                        .filter((link: any) => link.text !== "HOME")
                        .map((link, index) => (
                            <NavbarLink key={index} {...link} />
                        ))}
                    <ThemeSwitcher />
                </div>
            )}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any, isTabletOrMobile: boolean) => {
    return {
        navbarStyle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: isTabletOrMobile ? "90%" : "100%",
            maxWidth: "1200px",
            margin: "0 auto 40px",
        } as React.CSSProperties,
        logoStyle: {
            width: "140px",
        } as React.CSSProperties,
        rightLinksStyle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "52px",
        } as React.CSSProperties,
        drawerStyle: {
            zIndex: 1000,
            height: "100vh",
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: getThemeColor({
                light: colors.GREY_1,
                dark: colors.DARKGREY_15,
            }),
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "30px",
            padding: "30px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        } as React.CSSProperties,
        drawerMaskStyle: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 999,
        } as React.CSSProperties,
        btnStyle: {
            cursor: "pointer",
        } as React.CSSProperties,
    };
};
