import React, { useEffect, useState } from "react";
import { confirmPasswordReset } from "firebase/auth";
import { useMiscStore } from "../../state/store";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";
import { NetworkCode } from "../../Utils/network";
import { pxFetch } from "../../Utils/utils";

type ResetPasswordProps = {
    oobCode?: string | null;
    apiKey?: string | null;
    continueUrl?: string | null;
    lang?: string | null;
    auth: any;
    email: string;
    jwt: string | null;
};

export default function ResetPassword({
    oobCode,
    apiKey,
    continueUrl,
    lang,
    auth,
    email,
    jwt,
}: ResetPasswordProps) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    let prefix = "rtro://";
    if (process.env.REACT_APP_ENV === "dev") {
        prefix = "rtrodev://";
    } else if (process.env.REACT_APP_ENV === "local") {
        prefix = "rtrolocal://";
    }

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [resetError, setResetError] = useState<string | null>(null);
    const [hasReset, setHasReset] = useState<boolean>(false);

    const [redirecting, setRedirecting] = useState<boolean>(true);

    const { backgroundStyle, h1Style, labelStyle, inputStyle, buttonStyle } =
        styles(colors, getThemeColor, isTabletOrMobile);

    const tryResetPassword = async () => {
        setResetError(null);
        if (password !== confirmPassword) {
            setResetError("Passwords do not match");
            return;
        }

        try {
            // await confirmPasswordReset(auth, oobCode ?? "", password);
            // Make an api call to our backend to update the user's password flag
            const resetResponse = await pxFetch.post(
                "/auth/resetUserPassword",
                {
                    jwt: jwt,
                    newPassword: password,
                },
            );

            if (resetResponse.status === NetworkCode.OK) {
                setHasReset(true);
            } else {
                setResetError(
                    resetResponse?.data?.friendlyMessage ??
                        "Something went wrong",
                );
                return;
            }
        } catch (e: any) {
            switch (e.code) {
                case "auth/weak-password":
                    setResetError("Password is too weak.");
                    break;
                case "auth/expired-action-code":
                    setResetError("Expired Action Code");
                    break;
                case "auth/invalid-action-code":
                    setResetError("Invalid Action Code");
                    break;
                case "auth/user-disabled":
                    setResetError("User Disabled");
                    break;
                case "auth/user-not-found":
                    setResetError("User Not Found");
                    break;
                default:
                    setResetError("Something went wrong");
                    break;
            }
        }
    };

    useEffect(() => {
        if (oobCode !== null) {
            window.location.href = `${prefix}settings/resetPassword/${oobCode}`;
        }
        setTimeout(() => {
            setRedirecting(false);
        }, 1000);
    }, [oobCode]);

    if (redirecting) return null;

    return (
        <div style={backgroundStyle}>
            <div style={h1Style}>Reset Password</div>

            {resetError && <div>{resetError}</div>}

            {!hasReset ? (
                <>
                    <div>
                        <label style={labelStyle}>New password</label>
                        <input
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={inputStyle}
                        />
                    </div>
                    <div>
                        <label style={labelStyle}>Confirm password</label>
                        <input
                            type="text"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={inputStyle}
                        />
                    </div>
                    <button
                        style={buttonStyle}
                        onClick={() => tryResetPassword()}>
                        Submit
                    </button>
                </>
            ) : (
                <div>
                    <div>Password reset successfully.</div>
                    {continueUrl && (
                        <a href={continueUrl} style={buttonStyle}>
                            Back to sign in
                        </a>
                    )}
                </div>
            )}
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    backgroundStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_16,
        }),
        width: "90%",
        maxWidth: "1200px",
        margin: "0 auto 50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        overflow: "auto",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    h1Style: {
        fontSize: "56px",
        margin: "50px 0px",
        fontWeight: 700,
        textAlign: "center",
    } as React.CSSProperties,
    labelStyle: {
        fontSize: "14px",
        marginBottom: "5px",
        marginTop: "20px",
        display: "block",
        color: colors.GREY_6,
    } as React.CSSProperties,
    inputStyle: {
        fontSize: "14px",
        width: "300px",
        height: "30px",
        border: "none",
        padding: "5px",
        marginBottom: "10px",
        background: "transparent",
        borderBottom: `1px solid ${colors.GREY_6}`,
    } as React.CSSProperties,
    buttonStyle: {
        backgroundColor: "#3B82F6",
        color: colors.GREY_0,
        fontSize: "20px",
        padding: "10px 20px",
        border: "none",
        cursor: "pointer",
        borderRadius: "5px",
        display: "block",
        marginTop: "20px",
    } as React.CSSProperties,
});
