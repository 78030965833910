import React from "react";
import FeatureCard from "./FeatureCard";
import GuaranteeSection from "./GuaranteeSection";
import "./styles.css";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";
import {
    RiAlertLine,
    RiPaletteLine,
    RiContrastDropLine,
    RiScissorsLine,
    RiHandHeartLine,
    RiArchiveLine,
} from "react-icons/ri";

interface Feature {
    icon: JSX.Element;
    title: string;
}

const features: Feature[] = [
    {
        icon: <RiAlertLine size={24} color={colors.INDIGO} />,
        title: "Minor manufacturing defects",
    },
    {
        icon: <RiPaletteLine size={24} color={colors.INDIGO} />,
        title: "Variations in color or material",
    },
    {
        icon: <RiContrastDropLine size={24} color={colors.INDIGO} />,
        title: "Glue stains or overspray",
    },
    {
        icon: <RiScissorsLine size={24} color={colors.INDIGO} />,
        title: "Stitching inconsistencies",
    },
    {
        icon: <RiArchiveLine size={24} color={colors.INDIGO} />,
        title: "Box and label condition issues",
    },
    {
        icon: <RiHandHeartLine size={24} color={colors.INDIGO} />,
        title: "Transparency and Customer Support",
    },
];

const UniqueValueSection: React.FC = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });
    const textColor = getThemeColor({
        light: colors.GREY_0,
        dark: colors.DARKGREY_16,
    });
    return (
        <section className="unique-value-section" style={{ backgroundColor }}>
            <div className="content-wrapper">
                <h2 className="section-title" style={{ color: textColor }}>
                    Our Unique Value: Beyond Authentication
                </h2>
                <p className="section-description" style={{ color: textColor }}>
                    What sets RTRO apart is our commitment to providing a
                    complete picture of your sneakers. We don't just determine
                    if a sneaker is authentic or not - we go the extra mile to
                    identify and document any quality control issues that may be
                    important to serious collectors.
                </p>
                <div className="feature-grid">
                    {features.map((feature, index) => (
                        <FeatureCard
                            key={index}
                            icon={feature.icon}
                            title={feature.title}
                        />
                    ))}
                </div>
                <p className="section-conclusion" style={{ color: textColor }}>
                    This comprehensive approach ensures that you have all the
                    information you need to make informed decisions about your
                    collection or purchases.
                </p>
            </div>
            <GuaranteeSection />
        </section>
    );
};

export default UniqueValueSection;
