import React from "react";
import "./styles.css";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

interface FeatureCardProps {
    icon: JSX.Element;
    title: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title }) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.DARKGREY_14,
        dark: colors.GREY_2,
    });
    const textColor = getThemeColor({
        light: colors.GREY_0,
        dark: colors.DARKGREY_16,
    });
    return (
        <div className="feature-card" style={{ backgroundColor }}>
            {icon}
            <h3 className="feature-title" style={{ color: textColor }}>
                {title}
            </h3>
        </div>
    );
};

export default FeatureCard;
