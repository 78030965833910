import { useMediaQuery } from "react-responsive";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

type FAQPillProps = {
    text: string;
    active: boolean;
    setCategory: any;
};

export default function FAQPill({ text, active, setCategory }: FAQPillProps) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const { pillStyle } = styles(
        colors,
        getThemeColor,
        isTabletOrMobile,
        active,
    );

    const togglePill = () => {
        if (active) {
            setCategory("all");
        } else {
            setCategory(text);
        }
    };

    return (
        <div style={pillStyle} onClick={togglePill}>
            {text}
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
    active: boolean,
) => ({
    pillStyle: {
        fontSize: isTabletOrMobile ? 16 : 20,
        fontWeight: 700,
        color: active
            ? getThemeColor({
                  light: colors.GREY_0,
                  dark: colors.DARKGREY_16,
              })
            : getThemeColor({
                  light: colors.DARKGREY_16,
                  dark: colors.GREY_0,
              }),
        backgroundColor: active
            ? getThemeColor({
                  light: colors.DARKGREY_16,
                  dark: colors.GREY_0,
              })
            : getThemeColor({
                  light: colors.GREY_0,
                  dark: colors.DARKGREY_12,
              }),
        padding: "12px 24px",
        borderRadius: 32,
        cursor: "pointer",
        border: `1px solid ${getThemeColor({
            light: colors.GREY_4,
            dark: colors.GREY_8,
        })}`,
    } as React.CSSProperties,
});
