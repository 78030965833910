import React from "react";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

export default function GuidelinesScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { pageStyle, divStyle } = styles(colors, getThemeColor);

    return (
        <div style={pageStyle}>
            <div style={divStyle}>
                <b>Community Guidelines</b>
            </div>

            <div style={divStyle}>Be kind and give fair trades.</div>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    pageStyle: {
        margin: "50px auto",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        width: "90%",
        maxWidth: "1200px",
    } as React.CSSProperties,
    divStyle: {
        marginBottom: "20px",
    } as React.CSSProperties,
});
