import React from "react";
import PaginationButton from "./PaginationButton";
import { colors } from "../Utils/colors";

type PaginationProps = {
    totalPages: number;
    currentPage: number;
    onChange: (page: number) => void;
    siblingCount?: number;
};

export default function Pagination({
    totalPages,
    currentPage,
    onChange,
    siblingCount = 1,
}: PaginationProps) {
    const { paginationStyle, ellipsesStyle } = styles(colors);

    // If there are no pages, don't render anything
    if (totalPages === 0) return null;

    // If there is only 1 page, only render the page number
    if (totalPages === 1)
        return (
            <PaginationButton
                text={1}
                disabled={true}
                func={() => onChange(1)}
            />
        );

    // If there are less pages than the total number of siblings to show, show only the number of pages
    if (totalPages < siblingCount + 5) {
        // Create an array of the number of pages
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);

        // Render the page numbers
        return (
            <div style={paginationStyle}>
                {currentPage > 1 && (
                    <PaginationButton
                        text={"<"}
                        func={() => onChange(currentPage - 1)}
                    />
                )}
                {pages.map((page, index) => (
                    <PaginationButton
                        key={index}
                        text={page}
                        disabled={page === currentPage}
                        func={() => onChange(page)}
                    />
                ))}
                {currentPage < totalPages && (
                    <PaginationButton
                        text={">"}
                        func={() => onChange(currentPage + 1)}
                    />
                )}
            </div>
        );
    } else {
        // If there are more pages than the total number of siblings to show, show the siblings and ellipses
        // Calculate the number of pages to show on either side of the current page
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPages,
        );

        // Calculate the number of pages to show on either side of the current page
        const shouldShowLeftEllipsis = leftSiblingIndex > 2;
        const shouldShowRightEllipsis = rightSiblingIndex < totalPages - 2;

        // Create an array of the number of pages
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);

        // Render the page numbers
        return (
            <div style={paginationStyle}>
                {currentPage > 1 && (
                    <PaginationButton
                        text={"<"}
                        func={() => onChange(currentPage - 1)}
                    />
                )}

                <PaginationButton
                    text={1}
                    disabled={currentPage === 1}
                    func={() => onChange(1)}
                />
                {shouldShowLeftEllipsis && <div style={ellipsesStyle}>...</div>}
                {pages.map((page) => {
                    if (
                        page === 1 ||
                        page < leftSiblingIndex ||
                        page > rightSiblingIndex
                    ) {
                        return null;
                    }

                    return (
                        <PaginationButton
                            key={page}
                            text={page}
                            disabled={page === currentPage}
                            func={() => onChange(page)}
                        />
                    );
                })}
                {shouldShowRightEllipsis && (
                    <div style={ellipsesStyle}>...</div>
                )}
                <PaginationButton
                    text={totalPages}
                    disabled={currentPage === totalPages}
                    func={() => onChange(totalPages)}
                />
                {currentPage < totalPages && (
                    <PaginationButton
                        text={">"}
                        func={() => onChange(currentPage + 1)}
                    />
                )}
            </div>
        );
    }
}

const styles = (colors: any) => ({
    paginationStyle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    } as React.CSSProperties,
    ellipsesStyle: {
        color: colors.GREY_6,
        marginRight: 10,
    } as React.CSSProperties,
});
