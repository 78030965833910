import { RiArrowRightLine } from "react-icons/ri";
import { useMiscStore } from "../../state/store";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";

const outerwear = require("../../images/outerwear.png");

export default function CantFindAnswer() {
    const navigate = useNavigate();

    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const {
        containerStyle,
        imgStyle,
        h2Style,
        textStyle,
        buttonStyle,
        btnFlexStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    const goToContactUs = () => {
        navigate("/contact");
    };

    return (
        <div style={containerStyle}>
            <img src={outerwear} alt="Outerwear" style={imgStyle} />
            <div>
                <h2 style={h2Style}>Can't find the answer?</h2>
                <div style={textStyle}>
                    Try searching with another keyword or contact our support
                    team.
                </div>
                <button onClick={goToContactUs} style={buttonStyle}>
                    <div style={btnFlexStyle}>Contact Us</div>
                    <RiArrowRightLine size="32" color={colors.DARKGREY_16} />
                </button>
            </div>
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    containerStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 100,
        backgroundColor: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.DARKGREY_14,
        }),
        padding: isTabletOrMobile ? "50px 32px" : "50px 100px",
        margin: "100px auto",
        borderRadius: 16,
        maxWidth: "1200px",
        width: "90%",
    } as React.CSSProperties,
    imgStyle: { width: "100%", maxWidth: "230px" } as React.CSSProperties,
    h2Style: {
        color: colors.GREY_0,
        fontSize: isTabletOrMobile ? 32 : 48,
        fontWeight: 700,
        letterSpacing: "-1.44px",
        marginBottom: 24,
    } as React.CSSProperties,
    textStyle: {
        color: colors.GREY_6,
        fontSize: 20,
        fontWeight: 700,
        letterSpacing: "-0.6px",
        marginBottom: 24,
    } as React.CSSProperties,
    buttonStyle: {
        backgroundColor: colors.GREY_0,
        color: colors.DARKGREY_16,
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: "-0.54px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "12px 24px",
        border: "none",
        borderRadius: 36,
        gap: 100,
        cursor: "pointer",
        width: isTabletOrMobile ? "100%" : "auto",
    } as React.CSSProperties,
    btnFlexStyle: { flex: 1 } as React.CSSProperties,
});
