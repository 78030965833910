import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

type Props = {
    pages: number;
    page: number;
    setPage: (page: number) => void;
};

export default function FAQPagination({ pages, page, setPage }: Props) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { containerStyle, bigButtonStyle } = styles(
        colors,
        getThemeColor,
        false,
    );

    const trySetPage = (page: number) => {
        if (page < 1 || page > pages) return;
        setPage(page);
    };

    // decide here if we need to break up the numbers
    let leftGroup: number[] = [];
    let middleGroup: number[] = [];
    let rightGroup: number[] = [];

    let breakNum = false;
    if (pages > 6) {
        breakNum = true;
    }

    if (!breakNum) {
        middleGroup = [...Array(pages)].map((_, index) => index + 1);
    } else {
        if (page <= 4) {
            leftGroup = [...Array(4)].map((_, index) => index + 1);
            rightGroup = [...Array(2)].map((_, index) => pages - (1 - index));
        } else if (page >= pages - 3) {
            leftGroup = [...Array(2)].map((_, index) => index + 1);
            rightGroup = [...Array(4)].map((_, index) => pages - (3 - index));
        } else {
            leftGroup = [...Array(2)].map((_, index) => index + 1);
            middleGroup = [...Array(2)].map((_, index) => page - (1 - index));
            rightGroup = [...Array(2)].map((_, index) => pages - (1 - index));
        }
    }

    return (
        <div style={containerStyle}>
            <div
                style={bigButtonStyle}
                onClick={() => {
                    trySetPage(page - 1);
                }}>
                <RiArrowLeftSLine
                    size={20}
                    color={
                        page === 1
                            ? getThemeColor({
                                  light: colors.GREY_5,
                                  dark: colors.GREY_7,
                              })
                            : getThemeColor({
                                  light: colors.DARKGREY_16,
                                  dark: colors.GREY_0,
                              })
                    }
                />
            </div>

            {leftGroup.length > 0 &&
                leftGroup.map((num: number, index: number) => (
                    <FAQPaginationNumber
                        key={`left-${index}`}
                        number={num}
                        active={num === page}
                        onClick={trySetPage}
                    />
                ))}

            {middleGroup.length > 0 && leftGroup.length > 0 && (
                <FAQPaginationDots />
            )}

            {middleGroup.length > 0 &&
                middleGroup.map((num: number, index: number) => (
                    <FAQPaginationNumber
                        key={`middle-${index}`}
                        number={num}
                        active={num === page}
                        onClick={trySetPage}
                    />
                ))}

            {leftGroup.length > 0 && rightGroup.length > 0 && (
                <FAQPaginationDots />
            )}

            {rightGroup.length > 0 &&
                rightGroup.map((num: number, index: number) => (
                    <FAQPaginationNumber
                        key={`right-${index}`}
                        number={num}
                        active={num === page}
                        onClick={trySetPage}
                    />
                ))}

            <div
                style={bigButtonStyle}
                onClick={() => {
                    trySetPage(page + 1);
                }}>
                <RiArrowRightSLine
                    size={20}
                    color={
                        page === pages
                            ? getThemeColor({
                                  light: colors.GREY_5,
                                  dark: colors.GREY_7,
                              })
                            : getThemeColor({
                                  light: colors.DARKGREY_16,
                                  dark: colors.GREY_0,
                              })
                    }
                />
            </div>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any, active: boolean) => ({
    containerStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 24,
    } as React.CSSProperties,
    bigButtonStyle: {
        borderRadius: 32,
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_12,
        }),
        padding: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    } as React.CSSProperties,
    numberButtonStyle: {
        color: active
            ? getThemeColor({
                  light: colors.DARKGREY_16,
                  dark: colors.GREY_0,
              })
            : colors.GREY_6,
        fontSize: 14,
        fontWeight: 500,
        cursor: "pointer",
    } as React.CSSProperties,
    dotsStyle: {
        color: colors.GREY_6,
        fontSize: 14,
        fontWeight: 500,
    } as React.CSSProperties,
});

export const FAQPaginationNumber = ({
    number,
    active,
    onClick,
}: {
    number: number;
    active: boolean;
    onClick: (num: number) => void;
}) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { numberButtonStyle } = styles(colors, getThemeColor, active);

    return (
        <div style={numberButtonStyle} onClick={() => onClick(number)}>
            {number}
        </div>
    );
};

export const FAQPaginationDots = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { dotsStyle } = styles(colors, getThemeColor, false);

    return <div style={dotsStyle}>...</div>;
};
