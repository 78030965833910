import React, { useEffect, useState } from "react";
import { useMiscStore } from "../../../state/store";
import { pxFetch } from "../../../Utils/utils";
import "./styles.css";
import { Avatar, Button, Card, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../Utils/colors";
const { Meta } = Card;

const UserProfileCard = ({ user }: any) => {
    const navigate = useNavigate();

    const { profile, username, uid, _id } = user;
    let firstName = "";
    let lastName = "";
    let profilePhotoUrl = "";
    if (profile) {
        firstName = profile.default.firstName;
        lastName = profile.default.lastName;
        profilePhotoUrl = profile.default.profilePhotoUrl;
    }
    const defaultProfilePhoto = "https://example.com/default-profile-photo.jpg"; // Replace with your default image URL
    return (
        <Card
            // style={{ width: 300 }}
            cover={
                <Image
                    // width={150}
                    height={280}
                    style={{
                        objectFit: "cover",
                    }}
                    src={profilePhotoUrl || defaultProfilePhoto}
                />
            }
            actions={[
                <Button
                    type="link"
                    onClick={() => {
                        // navigate to profile
                        navigate(`/dashboard/users/${uid}`);
                    }}>
                    DETAILS
                </Button>,
            ]}>
            <Meta
                avatar={<Avatar src={profilePhotoUrl || defaultProfilePhoto} />}
                title={` ${firstName} ${lastName}`}
                description={
                    <div>
                        <p>Username: {username}</p>
                        <p
                            style={{
                                fontSize: 10,
                            }}>
                            uid: {uid}{" "}
                        </p>
                        <p
                            style={{
                                fontSize: 10,
                            }}>
                            _id: {_id}{" "}
                        </p>
                    </div>
                }
            />
        </Card>
    );
};

const UserGrid = ({ users }: any) => {
    return (
        <div className="user-grid">
            {users &&
                users.map((user: any) => (
                    <UserProfileCard key={user._id} user={user} />
                ))}
        </div>
    );
};

const UsersScreen = () => {
    const [search, setSearch] = useState<string>("");
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const [results, setResults] = useState([]);
    const searchStyle = {
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_16,
        }),
        padding: "5px 10px 5px 0px",
        borderRadius: 5,
    } as React.CSSProperties;

    const getData = async () => {
        const response = await pxFetch.get(
            `/search?q=${encodeURIComponent(
                search,
            )}&offset=${0}&searchType=${"people"}${`&limit=${80}`}`,
        );
        setResults(response.data.results);
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                width: "100%",
                overflowX: "auto",
                flexDirection: "column",
            }}>
            <input
                style={searchStyle}
                value={search}
                onChange={(e: any) => {
                    const value = e.target.value;
                    setSearch(value);
                }}
            />

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                }}>
                <UserGrid users={results} />
            </div>
        </div>
    );
};

export default UsersScreen;
