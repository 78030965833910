import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import { verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../Hooks/useAuth";
import { useMiscStore } from "../../state/store";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";

export default function AuthHandler() {
    const [searchParams] = useSearchParams();

    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const [pageError, setPageError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");

    const hasRun = useRef<boolean>(false);

    const mode = searchParams.get("mode");
    const oobCode = searchParams.get("oobCode");
    const apiKey = searchParams.get("apiKey");
    const continueUrl = searchParams.get("continueUrl");
    const lang = searchParams.get("lang");

    // Get the jwt off of the continueUrl
    const jwt: any = continueUrl?.split("jwt=")[1]?.split("&")[0];

    const { loadingStyle, errorStyle } = styles(
        colors,
        getThemeColor,
        isTabletOrMobile,
    );

    const verifyCode = async (auth: any, oobCode: string) => {
        hasRun.current = true;
        try {
            const email = await verifyPasswordResetCode(auth, oobCode);
            setEmail(email);
        } catch (error: any) {
            switch (error.code) {
                case "auth/expired-action-code":
                    setPageError("This link has expired. Please try again.");
                    break;
                case "auth/invalid-action-code":
                    setPageError(
                        "This link has already been used. Please try again.",
                    );
                    break;
                case "auth/user-disabled":
                    setPageError(
                        "The user corresponding to the given reset code has been disabled.",
                    );
                    break;
                case "auth/user-not-found":
                    setPageError(
                        "The user corresponding to the given reset code has been disabled.",
                    );
                    break;
                case "auth/api-key-not-valid.-please-pass-a-valid-api-key.":
                    setPageError("Something went wrong. Please try again.");
                    break;
                default:
                    setPageError(JSON.stringify(error));
                    break;
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (
            !mode ||
            !oobCode ||
            !apiKey ||
            !jwt ||
            !["resetPassword", "recoverEmail", "verifyEmail"].includes(mode)
        ) {
            setPageError("Invalid URL");
            setLoading(false);
            return;
        }

        // check oobCode
        if (!hasRun.current) {
            verifyCode(auth, oobCode);
        }
    }, [mode, oobCode, apiKey]);

    return (
        <>
            {loading ? (
                <div style={loadingStyle}>Loading...</div>
            ) : (
                <>
                    {pageError ? (
                        <div style={errorStyle}>{pageError}</div>
                    ) : (
                        <>
                            {mode === "resetPassword" && (
                                <ResetPassword
                                    oobCode={oobCode}
                                    apiKey={apiKey}
                                    continueUrl={continueUrl}
                                    lang={lang}
                                    auth={auth}
                                    email={email}
                                    jwt={jwt}
                                />
                            )}
                            {mode === "recoverEmail" && <div>recoverEmail</div>}
                            {mode === "verifyEmail" && <div>verifyEmail</div>}
                        </>
                    )}
                </>
            )}
        </>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    loadingStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        width: "90%",
        maxWidth: "1200px",
        margin: "50px auto 150px",
        textAlign: "center",
    } as React.CSSProperties,
    errorStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        width: "90%",
        maxWidth: "1200px",
        margin: "150px auto 150px",
        fontSize: isTabletOrMobile ? "24px" : "36px",
        textAlign: "center",
    } as React.CSSProperties,
});
