import { useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";

export const useDebounce = (callback: any, delay: number) => {
    const ref = useRef<any>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
};
