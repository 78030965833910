import React from "react";
import { colors } from "../../Utils/colors";
import { useMiscStore } from "../../state/store";

interface ProcessStepProps {
    title: string;
    description: string;
    imageSrc: string;
    imageAlt: string;
    leftAlignedImage?: boolean;
}

const ProcessStep: React.FC<ProcessStepProps> = ({
    title,
    description,
    imageSrc,
    imageAlt,
    leftAlignedImage = true,
}) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.GREY_0,
        dark: colors.DARKGREY_14,
    });
    const textColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });
    return (
        <div className="process-step" style={{ backgroundColor }}>
            {leftAlignedImage && (
                <img
                    loading="lazy"
                    src={imageSrc}
                    alt={imageAlt}
                    className="step-image"
                />
            )}
            <div className="step-content">
                <h2 className="step-title" style={{ color: textColor }}>
                    {title}
                </h2>
                <p
                    className="step-description"
                    dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
            {!leftAlignedImage && (
                <img
                    loading="lazy"
                    src={imageSrc}
                    alt={imageAlt}
                    className="step-image"
                />
            )}
        </div>
    );
};

export default ProcessStep;
