import { colors } from "../../Utils/colors";
import { useMiscStore } from "../../state/store";

type FAQItemProps = {
    question: string;
    answer?: null | TrustedHTML;
};

export default function FAQItem({ question, answer }: FAQItemProps) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { divStyle, questionStyle, answerStyle } = styles(
        colors,
        getThemeColor,
    );

    return (
        <div style={divStyle}>
            <div style={questionStyle}>{question}</div>
            {answer && (
                <div
                    style={answerStyle}
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            )}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    divStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_12,
        }),
        padding: 32,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 16,
        borderRadius: 16,
    } as React.CSSProperties,
    questionStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: 24,
        fontWeight: 700,
    } as React.CSSProperties,
    answerStyle: {
        color: colors.GREY_6,
        fontSize: 16,
        fontWeight: 700,
    } as React.CSSProperties,
});
