import React from "react";
import { useMiscStore } from "../../state/store";
import FAQPill from "./FAQPill";
import FAQItem from "./FAQItem";
import FAQPagination from "./FAQPagination";
import { NetworkCode } from "../../Utils/network";
import { RiSearch2Line } from "react-icons/ri";
import { useDebounce } from "../../Utils/debounceUtil";
import { useMediaQuery } from "react-responsive";
import CantFindAnswer from "./CantFindAnswer";
import { colors } from "../../Utils/colors";
import { ClipLoader } from "react-spinners";

export default function FAQScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const loadFAQs = useMiscStore((state) => state.loadFAQs);

    const [faqs, setFaqs] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState<string>("");
    const [category, setCategory] = React.useState<string>("all");
    const [pages, setPages] = React.useState<number>(0);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);

    const {
        titleStyle,
        searchDivStyle,
        searchInputStyle,
        pillContainerStyle,
        faqContainerStyle,
        faqInnerContainerStyle,
        totalTextStyle,
        loaderContainerStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    const tryLoadFaqs = async () => {
        if (loading) return;
        setLoading(true);
        const response = await loadFAQs(search, category, page);
        setLoading(false);
        if (response?.status === NetworkCode.OK) {
            setFaqs(response?.data?.faqs);
            setPages(response?.data?.pages);
            setTotalCount(response?.data?.count);
        }
    };

    const debouncedSearch = useDebounce(tryLoadFaqs, 500);

    React.useEffect(() => {
        tryLoadFaqs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    React.useEffect(() => {
        setPage(1);
        tryLoadFaqs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    React.useEffect(() => {
        debouncedSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div>
            <h1 style={titleStyle}>FAQs</h1>

            <div style={searchDivStyle}>
                <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSubmit={tryLoadFaqs}
                    placeholder="Ask a question"
                    style={searchInputStyle}
                />
                <RiSearch2Line size={24} color={colors.GREY_6} />
            </div>

            <div style={pillContainerStyle}>
                {[
                    "all",
                    "auction",
                    "closet",
                    "marketplace",
                    "shipping",
                    "trade",
                ].map((text: string, index: number) => (
                    <FAQPill
                        key={`pill-${index}`}
                        text={text}
                        active={category === text}
                        setCategory={setCategory}
                    />
                ))}
            </div>

            <div style={faqContainerStyle}>
                {loading ? (
                    <div style={loaderContainerStyle}>
                        <ClipLoader
                            color={colors.INDIGO}
                            loading={true}
                            size={50}
                            aria-label="Loading FAQ"
                        />
                    </div>
                ) : faqs?.length === 0 ? (
                    <div style={faqInnerContainerStyle}>
                        <div style={totalTextStyle}>RESULT</div>
                        <FAQItem
                            question={
                                "No answers were found, please refine your search!"
                            }
                        />
                    </div>
                ) : (
                    <div style={faqInnerContainerStyle}>
                        <div style={totalTextStyle}>
                            TOTAL OF {totalCount} ANSWER
                            {totalCount !== 1 ? "S" : ""}
                        </div>
                        {faqs?.map((faq: any, index: number) => (
                            <FAQItem
                                key={`faq-${index}`}
                                question={faq?.question}
                                answer={faq?.answer}
                            />
                        ))}
                        <FAQPagination
                            pages={pages}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                )}
            </div>

            <div>
                <CantFindAnswer />
            </div>
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    titleStyle: {
        fontSize: isTabletOrMobile ? 54 : 74,
        fontWeight: 700,
        textAlign: "center",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        margin: "50px auto",
    } as React.CSSProperties,
    searchDivStyle: {
        margin: "50px auto",
        border: `2px solid ${colors.GREY_6}`,
        padding: "16px 24px",
        borderRadius: 28,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "90%",
        maxWidth: 550,
    } as React.CSSProperties,
    searchInputStyle: {
        backgroundColor: "transparent",
        border: "none",
        fontSize: 18,
        fontWeight: 700,
        flex: 1,
        outline: "none",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    pillContainerStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: isTabletOrMobile ? "flex-start" : "center",
        gap: isTabletOrMobile ? 12 : 24,
        overflowX: "auto",
        margin: "0 auto 20px",
        paddingBottom: 30,
        width: "90%",
        maxWidth: 1200,
        scrollbarWidth: "none",
        WebkitOverflowScrolling: "touch",
        msOverflowStyle: "none",
        scrollbarColor: "transparent transparent",
    } as React.CSSProperties,
    faqContainerStyle: {
        backgroundColor: getThemeColor({
            light: colors.GREY_2,
            dark: colors.DARKGREY_14,
        }),
        padding: "40px 0",
    } as React.CSSProperties,
    faqInnerContainerStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 24,
        width: "100%",
        maxWidth: 1200,
        margin: "auto",
        padding: "0 24px",
    } as React.CSSProperties,
    totalTextStyle: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: "3.08px",
        color: colors.GREY_6,
    } as React.CSSProperties,
    loaderContainerStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 24,
        width: "100%",
        maxWidth: 1200,
        margin: "auto",
        padding: "0 24px",
    } as React.CSSProperties,
});
