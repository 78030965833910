import * as React from "react";
import { colors } from "../Utils/colors";
const VerifiedIcon = (props: any) => {
    const fill = props.fill ?? (props?.verified ? colors.BLUE : colors.GREY_6);
    if (props?.large) {
        return (
            <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={24}
                fill={fill}>
                <path
                    fill={fill}
                    fillRule="evenodd"
                    d="M13.205 1.878a1 1 0 0 0-1.411 0L9.156 4.506a1 1 0 0 1-.592.285l-3.699.423a1 1 0 0 0-.88 1.104l.41 3.7a1 1 0 0 1-.146.641l-1.975 3.156a1 1 0 0 0 .314 1.377l3.149 1.986a1 1 0 0 1 .41.514l1.235 3.512a1 1 0 0 0 1.272.613l3.517-1.224a1 1 0 0 1 .657 0l3.517 1.224a1 1 0 0 0 1.271-.613l1.236-3.512a1 1 0 0 1 .41-.514l3.15-1.986a1 1 0 0 0 .313-1.377L20.75 10.66a1 1 0 0 1-.146-.64l.41-3.701a1 1 0 0 0-.88-1.104l-3.7-.423a1 1 0 0 1-.591-.285l-2.638-2.628Zm2.637 6.777-4.255 4.254-2.482-2.482-1.42 1.418 3.902 3.901 5.673-5.673-1.418-1.418Z"
                    clipRule="evenodd"
                />
            </svg>
        );
    }
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={props?.width ?? 21}
            height={props?.height ?? 20}
            viewBox="0 0 21 20"
            fill="none">
            <path
                fill={fill}
                fillRule="evenodd"
                d="M11.206 1.682a1 1 0 0 0-1.412 0L7.755 3.714a1 1 0 0 1-.592.285l-2.86.327a1 1 0 0 0-.88 1.104L3.74 8.29a1 1 0 0 1-.146.64l-1.527 2.44a1 1 0 0 0 .314 1.377l2.434 1.536a1 1 0 0 1 .41.514l.956 2.715a1 1 0 0 0 1.272.613l2.718-.946a1 1 0 0 1 .658 0l2.718.946a1 1 0 0 0 1.272-.613l.956-2.715a1 1 0 0 1 .41-.514l2.434-1.536a1 1 0 0 0 .314-1.376l-1.527-2.44a1 1 0 0 1-.146-.641l.317-2.861a1 1 0 0 0-.88-1.104L13.837 4a1 1 0 0 1-.592-.285l-2.04-2.032Zm2.08 5.53-3.547 3.546-2.068-2.069L6.49 9.871l3.25 3.25 4.728-4.727-1.182-1.181Z"
                clipRule="evenodd"
            />
        </svg>
    );
};
export default VerifiedIcon;
