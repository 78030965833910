import { colors } from "../Utils/colors";
import { useMiscStore } from "../state/store";

type PaginationButtonProps = {
    text: string | number;
    disabled?: boolean;
    func: (num: number | string) => void;
};

export default function PaginationButton({
    text,
    disabled = false,
    func,
}: PaginationButtonProps) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { paginationButtonStyle } = styles(colors, getThemeColor, disabled);

    return (
        <div
            style={paginationButtonStyle}
            onClick={() => {
                if (disabled) return null;
                func(text);
            }}>
            {text}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any, disabled: boolean) => ({
    paginationButtonStyle: {
        padding: "6px 8px 3px 8px",
        margin: "10px 10px 5px 0px",
        fontSize: 12,
        borderRadius: 5,
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        border: `1px solid ${colors.GREY_6}`,
        backgroundColor: disabled
            ? getThemeColor({
                  light: colors.GREY_3,
                  dark: colors.GREY_6,
              })
            : getThemeColor({
                  light: colors.GREY_0,
                  dark: colors.DARKGREY_16,
              }),
        cursor: disabled ? "initial" : "pointer",
        maxWidth: "max-content",
    } as React.CSSProperties,
});
