// @ts-nocheck
import React, { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

const rtro_logo_small = require("../../images/rtro_logo_small.png");

export function getFullUserSchema(user: any) {
    const realUser = {
        ...user.toJSON(),
        providerId: user.providerId,
        metaData: user.metadata.toJSON(),
        accessToken: user?.stsTokenManager?.accessToken,
        phoneNumber: user?.phoneNumber,
        photoURL: user?.photoURL,
        tenantId: user?.tenantId,
    };
    delete realUser["_redirectEventId"];

    return realUser;
}

export default function LoginScreen(props: any) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const {
        containerStyle,
        titleStyle,
        formDivStyle,
        labelStyle,
        inputStyle,
        buttonStyle,
        googleButtonStyle,
        errorStyle,
        dividerContainerStyle,
        dividerStyle,
        dividerLogoStyle,
    } = styles(colors, getThemeColor);

    // add next router here
    const [text, setText] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState("");

    const { logInWithEmailAndPassword, signInWithGoogle } = useAuth();

    const loginError = useMiscStore((state) => state.loginError);

    const loginWithEmailPass = async () => {
        setError("");
        if (
            text.trim().length < 1 ||
            !text.includes("@") ||
            !text.includes(".") ||
            pass.trim().length < 1
        ) {
            return;
        }

        const res = await logInWithEmailAndPassword(text, pass);
        if (res.status === "error") {
            setError(res.message);
        }
    };

    const loginWithGoogle = async () => {
        setError("");
        const res = await signInWithGoogle();
        if (res.status === "error") {
            setError(res.message);
        }
    };

    return (
        <div style={containerStyle}>
            <div style={titleStyle}>LOGIN</div>

            {(error || loginError) && (
                <div
                    style={errorStyle}
                    onClick={() => {
                        setError("");
                        useMiscStore.setState({ loginError: "" });
                    }}>
                    {error || loginError}
                </div>
            )}

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    loginWithEmailPass();
                }}>
                <div style={formDivStyle}>
                    <div style={labelStyle}>EMAIL</div>
                    <input
                        type="email"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        style={inputStyle}
                        onSubmit={loginWithEmailPass}
                    />
                </div>
                <div style={formDivStyle}>
                    <div style={labelStyle}>PASSWORD</div>
                    <input
                        type="password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        style={inputStyle}
                        onSubmit={loginWithEmailPass}
                    />
                </div>
                <button onClick={loginWithEmailPass} style={buttonStyle}>
                    Login with Email
                </button>
            </form>

            <div style={dividerContainerStyle}>
                <div style={dividerStyle}></div>
                <img
                    src={rtro_logo_small}
                    alt="RTRO logo small"
                    style={dividerLogoStyle}
                />
                <div style={dividerStyle}></div>
            </div>

            <button
                onClick={loginWithGoogle}
                style={{ ...buttonStyle, ...googleButtonStyle }}>
                Continue with Google
            </button>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    containerStyle: {
        width: "90%",
        maxWidth: "400px",
        margin: "70px auto 150px",
    } as React.CSSProperties,
    titleStyle: {
        fontSize: "32px",
        fontWeight: "700",
        letterSpacing: "0.22em",
        marginBottom: "40px",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    formDivStyle: {
        marginBottom: "20px",
    } as React.CSSProperties,
    labelStyle: {
        fontSize: "14px",
        fontWeight: "700",
        letterSpacing: "-0.02em",
        marginBottom: "10px",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    inputStyle: {
        width: "100%",
        borderRadius: "10px",
        border: `1px solid ${colors.GREY_6}`,
        padding: "10px",
        fontSize: "16px",
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_15,
        }),
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    buttonStyle: {
        borderRadius: "4px",
        width: "100%",
        padding: "12px 24px",
        cursor: "pointer",
        backgroundColor: colors.INDIGO,
        color: colors.WHITE,
        border: "none",
    } as React.CSSProperties,
    googleButtonStyle: {
        backgroundColor: colors.WHITE,
        color: colors.DARKGREY_16,
        border: `1px solid ${colors.GREY_6}`,
        marginTop: "20px",
    } as React.CSSProperties,
    errorStyle: {
        backgroundColor: colors.RED,
        color: "#ffffff",
        padding: "10px",
        borderRadius: "10px",
        marginBottom: "20px",
        cursor: "pointer",
    } as React.CSSProperties,
    dividerContainerStyle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
    } as React.CSSProperties,
    dividerStyle: {
        borderTop: `1px solid ${colors.GREY_6}`,
        height: "1px",
        flex: 1,
    } as React.CSSProperties,
    dividerLogoStyle: { margin: "0 20px" } as React.CSSProperties,
});
