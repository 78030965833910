import React from "react";

const DisputesScreen = () => {
    const pageStyle = {
        margin: "50px auto",
    } as React.CSSProperties;

    return (
        <div style={pageStyle}>
            <div>
                <b>Dispute Policy</b>
            </div>
        </div>
    );
};

export default DisputesScreen;
