import React from "react";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

export default function PrivacyScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { pageStyle, divStyle, linkStyle } = styles(colors, getThemeColor);

    return (
        <div style={pageStyle}>
            <div style={divStyle}>
                <b>Introduction:</b> This Privacy Policy governs the manner in
                which RTRO ("we", "us", or "our") collects, uses, maintains, and
                discloses information collected from users (each, a "User") of
                the RTRO application (the "App"). This Privacy Policy applies to
                the App and all products and services offered by RTRO.
            </div>
            <div style={divStyle}>
                At RTRO, we are committed to protecting the privacy of our
                Users. By using the App, you consent to the collection, use, and
                disclosure of your personal information in accordance with this
                Privacy Policy.
            </div>

            <ol type="1">
                <li>
                    <b>Information collected from you</b>
                    <ol type="a">
                        <li>
                            <b>
                                The Information We Collect From You or Other
                                Sources
                            </b>
                            <ol type="i">
                                <li>
                                    When you use the Service, you may be asked
                                    to provide certain information that could be
                                    used to identify or contact you. This
                                    information includes, but is not limited to,
                                    your name, phone number, payment card
                                    details, and any other information you
                                    provide when communicating with us
                                    ("personal user information"). "Personal
                                    user Information" does not include
                                    aggregated or deidentified information that
                                    is maintained in a form that is not
                                    reasonably capable of being associated with
                                    or linked to an individual.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>
                                When you register with the Service and use the
                                Service, we collect Personal Information about
                                you.
                            </b>{" "}
                            If you make the choice to register, you are required
                            to provide a username and you can choose what other
                            information you provide as part of your profile,
                            with which other users may see your username on the
                            Service. When you use our Services, you may provide
                            us with personal information directly, including:
                            <ol type="i">
                                <li>
                                    <b>Region and language:</b> When you visit
                                    our app, we may ask you to select your
                                    region and language to provide you with the
                                    relevant Services.
                                </li>
                                <li>
                                    <b>Registration and profile information:</b>{" "}
                                    When you create an account on our Services,
                                    we will collect your name, email address,
                                    and a password. You may also provide
                                    optional profile information such as your
                                    shoe size and products you're following.
                                    When you bid, ask or complete a transaction
                                    on the Services, that information will be
                                    tracked in your account. If you choose to
                                    apply two-factor authentication to your
                                    account, we will collect your mobile phone
                                    number.
                                </li>
                                <li>
                                    <b>Marketing information:</b> When you sign
                                    up to receive marketing messages from us,
                                    you provide us with your mobile phone
                                    number.
                                </li>
                                <li>
                                    <b>Purchase information:</b> When making a
                                    purchase via our Services, we collect your
                                    shipping address, payment information and
                                    billing address. Payment information is
                                    collected through a third-party payment
                                    gateway and we do not store payment card
                                    information. Depending on your jurisdiction,
                                    you may also need to provide additional
                                    information related to customs and taxes,
                                    for example a VAT ID.
                                </li>
                                <li>
                                    <b>Seller information:</b> Sellers must
                                    provide payout information including date of
                                    birth, address, and bank account, bank card,
                                    or digital payment
                                </li>
                                <li>
                                    <b>
                                        Services account information (e.g.,
                                        Paypal, Venmo).
                                    </b>
                                    This information is collected through a
                                    third-party payment gateway and we do not
                                    store payment card information. We may
                                    additionally require a government
                                    identification number (e.g., VAT ID, SSN) to
                                    satisfy tax or information reporting
                                    obligations.
                                </li>
                                <li>
                                    <b>Identity Verification Information:</b>{" "}
                                    Under certain circumstances, we may need to
                                    verify the identity of a buyer or seller on
                                    the Services. To do this, we may request
                                    that you provide government photo
                                    identification, and a live photo to our
                                    identity verification provider, which can
                                    confirm a match between your live photo and
                                    your government identification. This process
                                    may employ the use of biometric data.
                                </li>
                                <li>
                                    <b>Communications:</b> When you contact us
                                    via a contact form, email, phone, or other
                                    means (e.g., to submit a query, or to report
                                    a problem with our Services), you may
                                    provide us with Personal Information, such
                                    as your name and contact details, language,
                                    and the content, date, and time of our
                                    communications.
                                </li>
                                <li>
                                    <b>Contests and sweepstakes:</b> If you
                                    enter a contest or sweepstakes sponsored by
                                    us, you may provide us with your name, date
                                    of birth, mailing address and e-mail address
                                    if you enter via postal mail. If you enter
                                    through a social media platform, you will
                                    provide your social media handle and
                                    potentially other Personal Information as
                                    part of your entry.
                                </li>
                                <li>
                                    <b>Surveys and reviews:</b> If you have
                                    chosen to receive marketing messages from
                                    us, we may contact you to participate in
                                    surveys about the Services or provide
                                    product or Services reviews. If you decide
                                    to participate, you may be asked to provide
                                    Personal Information.
                                </li>
                                <li>
                                    <b>Career information:</b> If you apply for
                                    a job with us directly, you may provide us
                                    with your resume, name and contact details,
                                    and any other information that you submit to
                                    us. If you apply for a job with us through a
                                    third-party platform (such as LinkedIn), we
                                    will collect the information you make
                                    available to us through such a third-party
                                    platform.
                                </li>
                                <li>
                                    We collect any information you submit to us
                                    through a "Submit A Request" customer
                                    support form, such as contact information
                                    and any other information you provide in the
                                    inquiry submission.
                                </li>
                                <li>
                                    We may collect location information about
                                    your mobile device if you allow location
                                    services and grant permission. In addition,
                                    we may use your IP address to determine the
                                    general location of your device.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <b>The information we automatically collect.</b>
                            <ol type="i">
                                <li>
                                    Our servers automatically track certain
                                    technical information from your browser and
                                    device when you use the Service, whether you
                                    use the service as a registered or
                                    non-registered user (each, a "User"), this
                                    includes, but is not limited to, information
                                    such as your computer's IP address, mobile
                                    device identifier, browser type and version,
                                    the web page visited before arriving at the
                                    Site, parts of the Service that you use, the
                                    time spent on those areas, information you
                                    search for on the Site or Apps, access times
                                    and dates, and other statistics.
                                </li>
                                <li>
                                    Cookies and similar technologies, such as
                                    software development kits, web beacons,
                                    pixels and tags, may also be used to collect
                                    this technical information. Using cookies or
                                    similar technologies can help us (or our
                                    third-party partners) collect information
                                    for advertising, monitoring, analysis, and
                                    personalizing your experience on the
                                    Service.
                                </li>
                                <li>
                                    Managing cookies, can be done by an
                                    individual when they change their browser
                                    settings to block cookies or to delete
                                    cookies. Please note that if you choose to
                                    block cookies, you may not be able to use
                                    all of the features of the Service.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Use of information:</b> The information we collect from
                    you may be used for the following purposes:
                    <ol type="a">
                        <li>
                            Managing, monitoring, customizing, improving, and
                            analyzing the Service's use;
                        </li>
                        <li>
                            Delivering the Service, completing your
                            transactions, and responding to your inquiries,
                            including sending you emails to confirm your order
                            status;
                        </li>
                        <li>
                            Providing you with RTRO newsletters, email updates,
                            marketing and promotional materials, and other
                            information you may find of interest;
                        </li>
                        <li>
                            Configuring the Site, Apps and the Service to your
                            interests;
                        </li>
                        <li>
                            As part of internal business goals and operation,
                            which may include analytics and marketing;
                        </li>
                        <li>
                            Complying with the law and maintaining the security
                            of our business; or
                        </li>
                        <li>
                            In addition to what might otherwise be disclosed
                            during collection or use.
                        </li>
                        <li>
                            To unsubscribe from any marketing email you receive
                            from us at any time, click "Unsubscribe" at the
                            bottom (no login required)
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Information sharing:</b> The information you provide to
                    us may be shared with third parties in accordance with this
                    section.
                    <ol type="a">
                        <li>
                            <b>Information from your profile.</b> When you
                            create a profile on the Service, other registered
                            users may be able to see the information that you
                            include in your profile. It is important that users
                            exercise caution when deciding what information to
                            provide and share on your profile.
                        </li>
                        <li>
                            <b>Service Providers.</b> We may employ third
                            parties to facilitate the Service, to provide the
                            Service on our behalf, or to perform other
                            Service-related activities for us (i.e. payment
                            processing services, security and fraud purposes,
                            trust and safety related services, shipping
                            carriers, database management, marketing, web or
                            advertising analytics, maintenance services, and
                            improvement of the Service's features) or assisting
                            us in analyzing how the Service is used.
                        </li>
                        <li>
                            <b>Partner referrals from third parties.</b>{" "}
                            Third-party referral partners may help us provide
                            you with better offers. If you visit the Site or the
                            service through a third-party website, we may share
                            certain information about your individual
                            transaction with the third party. You may be given a
                            unique code, cookie, or graphic by a third-party
                            website in order for it to track and credit your
                            transaction.
                        </li>
                        <li>
                            <b>Enforcement of laws and compliance with laws.</b>{" "}
                            To enforce and comply with applicable laws and
                            regulations, we cooperate with government and law
                            enforcement officials. To respond to claims or legal
                            process, we may disclose any information about you
                            to government, law enforcement, or private parties
                            as we, in our sole discretion, determine is
                            necessary or appropriate - this includes but is not
                            limited to subpoenas - In order to protect RTRO's or
                            a third parties, property, rights, and the safety of
                            others, to protect the safety of the public or to
                            prevent or stop any action that would be illegal,
                            harmful, unethical, or legally actionable.
                        </li>
                        <li>
                            <b>Business Transfers.</b> If we merge, acquire,
                            reorganize, or sell some or all of our assets,
                            including your information, or if we file for
                            bankruptcy, we may sell, transfer, or otherwise
                            share your information.
                        </li>
                        <li>
                            <b>With notice or consent.</b> Your information may
                            be shared with your consent or as otherwise
                            disclosed at the time of data collection or sharing.
                        </li>
                        <li>
                            <b>
                                Information that is aggregated and deidentified.
                            </b>
                            Information that has been aggregated or deidentified
                            may be shared without limitation. We may share
                            technical information (such as information that
                            identifies a device) collected as described above.
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Your information rights</b>
                    <ol type="a">
                        <li>
                            Internet based advertising: We are allowed to
                            partner with ad networks and other ad serving
                            providers (<b>"Advertisement Providers"</b>) that
                            serve ads on behalf of us and others on
                            non-affiliated platforms. Those ads will be done
                            through internet-based advertising. Consequently,
                            some of those ads may be tailored to be relevant to
                            you based on information Advertising Providers
                            collect about how you use the Service and other
                            sites and apps over time, including information
                            about your relationships among different browsers
                            and devices.
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Managing and securing user information</b>
                    <ol type="a">
                        <li>
                            <b>Security:</b> We make reasonable efforts to
                            protect your information by using physical and
                            electronic safeguards designed to improve the
                            security of the information we maintain. However, we
                            cannot guarantee the complete security of your
                            personal information as no electronic transmission
                            or storage of information can be entirely secure.
                        </li>
                        <li>
                            <b>Managing user information:</b> We adopt
                            appropriate data collection, storage, and processing
                            practices and security measures to protect against
                            unauthorized access, alteration, disclosure, or
                            destruction of your personal information, username,
                            password, transaction information, and data stored
                            on our App.
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Personal information of children and privacy</b>
                    <ol type="a">
                        <li>
                            At RTRO, we do not knowingly collect, maintain, or
                            use personal information from children under the age
                            of 13, and no part of our Services is directed to
                            children. If you learn that a child has provided us
                            with personal information in violation of this
                            Privacy Policy, please alert us at{" "}
                            <a href="mailto:support@rtro.com" style={linkStyle}>
                                support@rtro.com
                            </a>
                            .
                        </li>
                    </ol>
                </li>

                <li>
                    <b>
                        <u>International visitors</u>
                    </b>
                    <ol type="a">
                        <li>
                            Our Services are hosted in the United States (U.S)
                            and intended for visitors located within the United
                            States. Visitors from the European Union or other
                            regions with different data collection and use laws
                            than U.S. law may have their personal information
                            transferred outside of their region to the United
                            States for processing and storage. Furthermore, we
                            may transfer your data from the U.S. to other
                            countries or regions as a result of storing and
                            processing your data, fulfilling your requests, and
                            operating the Services. Your use of our Services,
                            including any personal information, constitutes your
                            consent to the transfer, storage, and processing of
                            such information.
                        </li>
                    </ol>
                </li>

                <li>
                    <b>Privacy policy updates</b>
                    <ol type="a">
                        <li>
                            <b>Changes to this Privacy Policy:</b> We reserve
                            the right to modify this Privacy Policy at any time.
                            If we make any material changes to this Privacy
                            Policy, we will notify you either through the App or
                            via email. Your continued use of the App after we
                            post any modifications to the Privacy Policy on this
                            page will constitute your acknowledgment of the
                            modifications and your consent to abide and be bound
                            by the modified Privacy Policy.
                        </li>
                        <li>
                            <b>Financial Incentives.</b> We may provide
                            financial incentives to consumers who allow us to
                            collect and retain personal information, such as
                            identifiers (e.g., name and email address) and
                            commercial information (e.g., purchase history).
                            These incentives may result in differences in our
                            prices or services offered to consumers and may
                            include a lower price for goods and services (e.g.,
                            discounts and other promotions). For example, the
                            financial incentives we may provide include
                            discounts or promo codes for certain existing
                            customers.
                            <ol type="i">
                                <li>
                                    The material aspects of any financial
                                    incentive will be explained and described in
                                    its program terms or in the details of the
                                    incentive offer
                                </li>
                                <li>
                                    Please note that participating in any
                                    financial incentive program is entirely
                                    optional and participants may withdraw from
                                    the program at any time. To opt out of the
                                    program and forgo any ongoing incentives,
                                    please follow the instructions in the
                                    program's terms and conditions or contact us
                                    using the contact information referenced in
                                    the Privacy Policy
                                </li>
                                <li>
                                    Each financial incentive or price or service
                                    difference related to the collection and use
                                    of personal information is based upon our
                                    reasonable, good faith determination of the
                                    estimated value of such information to our
                                    business, taking into consideration the
                                    value of the offer itself and the
                                    anticipated revenue generation that may be
                                    realized by rewarding brand loyalty and/or
                                    repeat purchases.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    pageStyle: {
        margin: "50px auto",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        width: "90%",
        maxWidth: "1200px",
    } as React.CSSProperties,
    divStyle: {
        marginBottom: "20px",
    } as React.CSSProperties,
    linkStyle: {
        color: colors.INDIGO,
        textDecoration: "none",
    } as React.CSSProperties,
});
