import { logEvent } from "firebase/analytics";
import { analytics, getToken } from "../Hooks/useAuth";
import { NetworkCode, RequestType } from "../Utils/network";
import { pxFetch } from "../Utils/utils";
import { FetchReturn } from "./store";

export interface IAuthState {
    // States

    // Setters

    // Getters

    // Actions
    doAuth: (
        user: any,
        provider: string,
        token?: string,
        secret?: string,
        username?: string,
    ) => Promise<FetchReturn>;

    // Clear
    clearAuthStore: () => void;
}

export const authStore = (
    set: any,
    get: () => IAuthState,
    api: any,
): IAuthState => ({
    // States

    // Setters

    // Getters

    // Actions

    doAuth: async (
        user: any,
        provider: string,
        token?: string,
        secret?: string,
        username?: string,
    ) => {
        try {
            const token = (await getToken()) || "";
            const response = await pxFetch(
                RequestType.POST,
                `/auth/login`,
                token,
                { user, provider, token, secret, username },
            );

            const json = await response.json();

            if (response.status === NetworkCode.OK) {
                return {
                    message: json?.message ?? "",
                    status: response.status,
                    userInfo: json?.userInfo ?? {},
                };
            } else if (
                response?.status &&
                [400, 403].includes(response.status)
            ) {
                // Return status code here so frontend can handle appropriately
                return {
                    message: json?.message ?? "",
                    status: response.status,
                };
            } else {
                throw new Error(json?.message ?? "Error");
            }
        } catch (e: any) {
            // Log the error to analytics and return status code error
            logEvent(analytics, "exception", {
                description: `Error doing authentication: ${JSON.stringify(e)}`,
                fatal: false,
            });
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error doing authentication: ${JSON.stringify(
                    e.message,
                )}`,
            };
        }
    },

    // Clear the user store
    clearAuthStore: () => {
        set(() => ({}));
    },
});

export default authStore;
