import React from "react";
import { useMiscStore } from "../../state/store";
// import BottomBillboard from "../LandingScreen/BottomBillboard";
// import ReviewBanner from "./ReviewBanner";
// import FAQBanner from "./FAQBanner";
// import ValuesSection from "./ValuesSection";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";
import AuthenticationProcess from "./AuthenticationProcess";
import UniqueValueSection from "./UniqueValueSection";
import VerifiedIcon from "../../Components/VerifiedIcon";

const coverPhoto = require("../../images/auth_1.jpg");

export default function AuthenticationInfoScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const {
        containerStyles,
        wrapperStyles,
        bannerContainerStyle,
        titleStyles,
        subtitleStyle,
        // bannerImgStyle,
        h2Style,
        storyContainerStyle,
        storyParagraphStyle,
        separatorStyles,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    const backgroundColor = getThemeColor({
        light: colors.GREY_1,
        dark: colors.DARKGREY_16,
    });
    const inverseBackgroundColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });

    return (
        <div style={{ ...wrapperStyles, backgroundColor }}>
            {/* top */}
            <div
                style={{
                    ...bannerContainerStyle,
                    backgroundImage: `url(${coverPhoto})`,
                }}>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        textAlign: "center",
                    }}>
                    <div>
                        <h1 style={titleStyles}>RTRO Authentication</h1>
                    </div>
                    {/* <img
                        src={historyBannerImg}
                        alt="banner"
                        style={bannerImgStyle}
                    /> */}
                </div>
                <div style={subtitleStyle}>
                    <VerifiedIcon fill={colors.GREY_0} width={24} height={24} />
                    {`   `}
                    All items 100% authenticated by hand out of RTRO's Los
                    Angeles HQ
                </div>
            </div>

            <div style={containerStyles}>
                <AuthenticationProcess />
            </div>
            <div style={{ backgroundColor: inverseBackgroundColor }}>
                <UniqueValueSection />
            </div>
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    wrapperStyles: {
        flex: 1,
        width: "100%",
        borderWidth: 1,
        borderColor: "red",
    },
    containerStyles: {
        width: isTabletOrMobile ? "100%" : "90%",
        maxWidth: 1200,
        margin: "0 auto 0",
    } as React.CSSProperties,
    bannerContainerStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: isTabletOrMobile ? "wrap" : "nowrap",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center bottom 50%",
    } as React.CSSProperties,
    titleStyles: {
        color: colors.GREY_0,
        fontSize: isTabletOrMobile ? 52 : 100,
        fontWeight: 700,
        margin: "127px 0",
        fontFamily: "Outfit",
        fontStyle: "normal",
        letterSpacing: "-3px",
        verticalAlign: "middle",
        alignItems: "center",
    } as React.CSSProperties,
    subtitleStyle: {
        color: colors.GREY_0,
        backgroundColor: colors.INDIGO,
        fontSize: 16,
        fontWeight: 700,
        letterSpacing: "-0.48px",
        lineHeight: "120%",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        fontFamily: "Outfit",
        padding: "16px 0",
        display: "flex",
        justifyContent: "center",
    } as React.CSSProperties,
    bannerImgStyle: {
        width: "100%",
        maxWidth: 600,
        borderRadius: 20,
    } as React.CSSProperties,
    h2Style: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: isTabletOrMobile ? 28 : 40,
        fontWeight: 700,
        letterSpacing: "4px",
        textTransform: "uppercase",
        marginBottom: 24,
    } as React.CSSProperties,
    storyContainerStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 24,
    } as React.CSSProperties,
    storyParagraphStyle: {
        color: colors.GREY_6,
        fontSize: isTabletOrMobile ? 14 : 20,
        fontWeight: 500,
        // letterSpacing: "-0.6px",
        lineHeight: isTabletOrMobile ? "22px" : "26.8px",
    } as React.CSSProperties,
    separatorStyles: {
        width: "90%",
        height: 1,
        backgroundColor: getThemeColor({
            light: colors.GREY_2,
            dark: colors.DARKGREY_12,
        }),
        margin: "80px auto",
        maxWidth: 1200,
    } as React.CSSProperties,
});

interface InspectionImageProps {
    src: string;
}
