import React from "react";
import { useMiscStore } from "../../../../state/store";
import { colors } from "../../../../Utils/colors";

type ErrorFilterProps = {
    label: string;
    type: string;
    options?: string[];
    value?: string | null;
    setValue: (value: any) => void;
};

const ErrorFilter = ({
    label,
    type,
    options,
    value,
    setValue,
}: ErrorFilterProps) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const labelStyle = {
        marginRight: 5,
    } as React.CSSProperties;

    const filterStyle = {
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_16,
        }),
        padding: "5px 10px 5px 0px",
        borderRadius: 5,
    } as React.CSSProperties;

    const inputStyle = {
        border: `1px solid ${colors.GREY_6}`,
        paddingLeft: 5,
    } as React.CSSProperties;

    return (
        <div>
            <label style={labelStyle}>{label}</label>
            {type === "text" && (
                <input
                    style={{ ...filterStyle, ...inputStyle }}
                    value={value ?? ""}
                    onChange={(e: any) => {
                        const value = e.target.value;
                        setValue(value);
                    }}
                />
            )}
            {type === "select" && (
                <select
                    style={filterStyle}
                    value={value ?? "all"}
                    onChange={(e: any) => {
                        const value = e.target.value;
                        if (value.toLowerCase() === "all") setValue(null);
                        else setValue(value);
                    }}>
                    {options?.map((option, index) => {
                        return (
                            <option key={`option-${index}`} value={option}>
                                {option}
                            </option>
                        );
                    })}
                </select>
            )}
        </div>
    );
};

export default ErrorFilter;
