import React from "react";
import "./styles.css";
import VerifiedIcon from "../../Components/VerifiedIcon";
import { colors } from "../../Utils/colors";

const GuaranteeSection: React.FC = () => {
    return (
        <section className="guarantee-section">
            <VerifiedIcon fill={colors.GREY_0} width={56} height={56} />
            <div className="guarantee-content">
                <h2 className="guarantee-title">Our Guarantee</h2>
                <p className="guarantee-description">
                    We stand behind our authentication and quality control
                    process. If any sneaker we authenticate is proven to be
                    inauthentic, or if we miss a significant quality control
                    issue, we offer a full refund and compensation for your
                    trouble. Your trust and satisfaction are our top priorities.
                </p>
            </div>
        </section>
    );
};

export default GuaranteeSection;
