import React from "react";
import AboutRow from "./AboutRow";
import Reviewbox from "./Reviewbox";
import BottomBillboard from "./BottomBillboard";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useMiscStore } from "../../state/store";
import Billboard from "./Billboard";
import { colors } from "../../Utils/colors";

const blue_shoe = require("../../images/blue_shoe.png");
const red_lady = require("../../images/red_lady.png");

const trades_light = require("../../images/trades_light.png");
const trades_dark = require("../../images/trades_dark.png");

const shoes_light = require("../../images/shoes_light.png");
const shoes_dark = require("../../images/shoes_dark.png");

const user1 = require("../../images/user1.png");
const user2 = require("../../images/user2.png");
const user3 = require("../../images/user3.png");

const reviews = [
    {
        text: "Love this App!! Traded my Nike Pegasus for Nike Air Max. Order arrived on time. Definitely would trade again.",
        user: {
            name: "Esm454",
            rating: 5,
            profilePhotoUrl: user1,
        },
    },
    {
        text: "I'm an avid sneaker head. Trading sneakers is a nightmare, I have no way to verify legitimacy, condition, etc. I waste times looking for trades. Now I have the solution. Can't wait to expand my merchandise network!",
        user: {
            name: "halt8",
            rating: 5,
            profilePhotoUrl: user2,
        },
    },
    {
        text: "Came across a bunch of pieces that I never knew I wanted - and was able to move some pieces in exchange that I hadn’t worn in a while. Sick to be able to have an app for this now instead of random FB / Reddit posts. Highly recommend if you're trying to swap things out",
        user: {
            name: "GuestPark",
            rating: 5,
            profilePhotoUrl: user3,
        },
    },
];

export default function LandingScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const theme = useMiscStore((state) => state.theme);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const navigate = useNavigate();

    const {
        reviewSectionStyle,
        smallReviewSectionStyle,
        bottomBillboardStyle,
        loginLinkStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    return <Billboard />;
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    categoryRowStyle: {
        display: "flex",
        flexDirection: isTabletOrMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: isTabletOrMobile ? "64px" : "200px",
        flexWrap: "wrap",
    } as React.CSSProperties,
    reviewSectionStyle: {
        display: "flex",
        flexDirection: isTabletOrMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "44px",
        margin: "auto",
        marginTop: isTabletOrMobile ? "64px" : "200px",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: "1200px",
    } as React.CSSProperties,
    smallReviewSectionStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "40px",
        flex: 1,
    } as React.CSSProperties,

    bottomBillboardStyle: {
        marginTop: isTabletOrMobile ? "64px" : "150px",
    } as React.CSSProperties,
    appstoreRowStyle: {
        marginTop: isTabletOrMobile ? "64px" : "150px",
    } as React.CSSProperties,
    loginLinkStyle: {
        marginTop: "10px",
        textAlign: "center",
        color: getThemeColor({
            light: colors.DARKGREY_13,
            dark: colors.GREY_6,
        }),
        textDecoration: "none",
        fontSize: "10px",
        fontWeight: "bold",
        cursor: "pointer",
    } as React.CSSProperties,
});
