import { NetworkCode } from "./network";
// import remoteConfig from "@react-native-firebase/remote-config";
// import {convertInventoryItemToOfferCardItem} from "../components/PxTradeItem/PxTradeItem";
import { getToken } from "../Hooks/useAuth";
export const blankImage =
    "https://firebasestorage.googleapis.com/v0/b/pxprod-8818e.appspot.com/o/models%2Fblank%2FSilhouette.png?alt=media&token=df9587c3-82dc-47cd-8d65-61fe0d1ebcd7";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const getFetchHeaders = async () => {
    const token = (await getToken()) || "";
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
};

export const pxFetch: any = {
    get: async (path: string) => {
        try {
            const URL = `${process.env.REACT_APP_API_URL}${"v1"}${path}`;

            // Start a new HTTP metric for performance monitoring

            const headers = await getFetchHeaders();
            const response = await fetch(URL, {
                method: "GET",
                headers: headers,
            });

            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    post: async (path: string, body: any) => {
        try {
            const URL = `${process.env.REACT_APP_API_URL}${"v1"}${path}`;

            const headers = await getFetchHeaders();
            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body, getCircularReplacer()),
            });

            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    put: async (path: string, body: any) => {
        try {
            const URL = `${process.env.REACT_APP_API_URL}${"v1"}${path}`;

            const headers = await getFetchHeaders();
            const response = await fetch(URL, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(body, getCircularReplacer()),
            });

            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
    delete: async (path: string, body?: any) => {
        try {
            const URL = `${process.env.REACT_APP_API_URL}${"v1"}${path}`;

            const headers = await getFetchHeaders();
            const response = await fetch(URL, {
                method: "DELETE",
                headers: headers,
                body: body
                    ? JSON.stringify(body, getCircularReplacer())
                    : undefined,
            });

            const data = await response.json();
            return {
                status: response.status,
                message: data?.message ?? "",
                data,
            };
        } catch (e: any) {
            console.log("ERROR", path, e);
            return {
                status: NetworkCode.INTERNAL_SERVER_ERROR,
                message: `Error: ${e.message}`,
                data: null,
            };
        }
    },
};

// export const createOfferCardData = (trade: any, user: any, userId?: string) => {
// 	// Define offer IDs for if we are going to display an existing offer (instead of HAVE and WANT items)
// 	let myOfferId = "";
// 	let creatorOfferId = "";

// 	// Select what items are shown in seller/they HAVE section (left side)
// 	let sellerItems = Array.isArray(trade?.items)
// 		? trade?.items.map(convertInventoryItemToOfferCardItem)
// 		: [];
// 	let sellerLatestOfferItems: any;

// 	// Select what items are shown in buyer/they WANT section (right side)
// 	let buyerItems = Array.isArray(trade?.desiredItems)
// 		? trade?.desiredItems.map(convertInventoryItemToOfferCardItem)
// 		: [];
// 	let buyerLatestOfferItems: any;

// 	// Check if there is a provided user and if this trade has offers
// 	if ((userId || user?.uid) && Array.isArray(trade.offers)) {
// 		// Check if current user is not the trade creator && has an offer
// 		trade.offers.forEach((element: any) => {
// 			const isValidOfferFromProvidedUser =
// 				element.recipientLatestOffer &&
// 				element.recipientLatestOffer.createdBy ===
// 					(userId || user?.uid) &&
// 				element.recipientLatestOffer.items &&
// 				element.recipientLatestOffer.items.length > 0 &&
// 				element.creatorLatestOffer &&
// 				element.creatorLatestOffer.items &&
// 				element.creatorLatestOffer.items.length > 0;
// 			if (isValidOfferFromProvidedUser) {
// 				myOfferId = element._id;
// 				buyerLatestOfferItems = element.recipientLatestOffer.items.map(
// 					convertInventoryItemToOfferCardItem,
// 				);
// 				creatorOfferId = element._id;
// 				sellerLatestOfferItems = element.creatorLatestOffer.items.map(
// 					convertInventoryItemToOfferCardItem,
// 				);
// 			}
// 		});
// 	}

// 	return {
// 		seller: {
// 			sellerProfile: {
// 				username:
// 					trade?.createdByUsername ??
// 					trade?.createdByProfile?.[0]?.username,
// 				uid: trade?.createdBy,
// 				profilePhotoUrl:
// 					trade?.createdByProfile?.[0]?.profilePhotoUrl ??
// 					trade?.createdByProfile?.[0]?.profile?.default
// 						?.profilePhotoUrl,
// 				blueBadge: false,
// 			},
// 			offerCardItems: sellerItems,
// 			latestOfferItems: sellerLatestOfferItems,
// 		},
// 		buyer: {
// 			buyerProfile: {
// 				username: user?.username,
// 				profilePhotoUrl: user?.profile?.default?.profilePhotoUrl,
// 				blueBadge: false,
// 				uid: user?.uid,
// 			},
// 			offerCardItems: buyerItems,
// 			latestOfferItems: buyerLatestOfferItems,
// 		},
// 		date: trade?.createdAt,
// 		lastUpdated: trade?.lastUpdated,
// 		tradeId: trade?._id,
// 	};
// };

// export const createOfferCardDataFromOffers = (trade: any, user: any) => {
// 	return {
// 		seller: {
// 			sellerProfile: {
// 				username: trade?.createdByUsername,
// 				uid: trade?.createdBy,
// 				profilePhotoUrl: trade?.createdByProfile?.[0]?.profilePhotoUrl,
// 				blueBadge: false,
// 			},
// 			offerCardItems: Array.isArray(
// 				trade?.offers?.creatorLatestOffer?.items,
// 			)
// 				? trade?.offers?.creatorLatestOffer?.items.map(
// 						convertInventoryItemToOfferCardItem,
// 				  )
// 				: [],
// 		},
// 		buyer: {
// 			buyerProfile: {
// 				username: trade?.offers?.recipientUsername,
// 				profilePhotoUrl:
// 					trade?.offers?.recipientProfile?.[0]?.profilePhotoUrl,
// 				blueBadge: false,
// 			},
// 			offerCardItems: Array.isArray(
// 				trade?.offers?.recipientLatestOffer?.items,
// 			)
// 				? trade?.offers?.recipientLatestOffer?.items.map(
// 						convertInventoryItemToOfferCardItem,
// 				  )
// 				: [],
// 		},
// 		date: trade?.createdAt,
// 		lastUpdated: trade?.lastUpdated,
// 		tradeId: trade?._id,
// 	};
// };

// export const getImages = async (
// 	item: any,
// 	currentTradeItem: any,
// 	getAdditionImages: any,
// 	fixSearchResultsImages: any,
// ) => {
// 	const itemData = item?.modelData || item || currentTradeItem;
// 	const goatSlug =
// 		itemData?.keyData?.goatSlug ??
// 		itemData?.modelData?.keyData?.goatSlug ??
// 		itemData?.goatSlug;
// 	const additionalImages =
// 		itemData?.modelDataImages ??
// 		itemData?.images ??
// 		itemData?.modelData?.images;
// 	const customImages = item?.customImages ?? currentTradeItem?.images;

// 	if ((!additionalImages || additionalImages.length <= 1) && goatSlug) {
// 		var myHeaders = new Headers();

// 		var requestOptions = {
// 			method: "GET",
// 			headers: myHeaders,
// 			redirect: "follow",
// 		};

// 		return fetch(
// 			`https://www.goat.com/web-api/v1/product_templates/${goatSlug}`,
// 			requestOptions,
// 		)
// 			.then(response => response.json())
// 			.then(async result => {
// 				if (result?.productTemplateExternalPictures) {
// 					const finalImages =
// 						result?.productTemplateExternalPictures.map(
// 							(imageObject: {
// 								mainPictureUrl: any;
// 								order: number;
// 							}) => {
// 								return {
// 									image: imageObject?.mainPictureUrl,
// 									order: imageObject?.order,
// 								};
// 							},
// 						);
// 					const response: any = await getAdditionImages(
// 						itemData?.slug ??
// 							itemData?.modelData?.slug ??
// 							itemData?.id, // why is this slug being changed to id...pain
// 						finalImages,
// 					);

// 					if (response.status !== NetworkCode.OK) {
// 						Toast.show({
// 							type: "error",
// 							text1: "Error",
// 							text2: "Error fetching additional images",
// 						});
// 					}

// 					let formattedImages = finalImages.map(
// 						(_image: {image: any}) => _image.image,
// 					);
// 					// Remove first element of array since its usually the same as main picture
// 					formattedImages.shift();
// 					const formattedCustomImages = (customImages ?? []).map(
// 						(_image: {imageURL: any}) => _image.imageURL,
// 					);

// 					const returnData = [
// 						(additionalImages),
// 						...(formattedCustomImages.length > 0
// 							? formattedCustomImages
// 							: formattedImages),
// 					];

// 					if (item)
// 						fixSearchResultsImages(itemData?.slug, returnData);
// 					else {
// 						useMiscStore.setState({
// 							currentTradeItem: {
// 								...currentTradeItem,
// 								modelDataImages: returnData,
// 							},
// 						});
// 					}

// 					return returnData;
// 				} else {
// 					const formattedCustomImages = (customImages ?? []).map(
// 						(_image: {imageURL: any}) => _image.imageURL,
// 					);

// 					const returnData = [
// 						getMainImage(additionalImages),
// 						...(formattedCustomImages ?? []),
// 					];

// 					// For if they do not have any additional images
// 					return returnData;
// 				}
// 			})
// 			.catch(error => console.log("error33", error, goatSlug));
// 	} else {
// 		let formattedImages = additionalImages?.map(
// 			(_image: {url: any}) => _image?.url ?? _image,
// 		);
// 		// Remove first element of array since its usually the same as main picture
// 		formattedImages.shift();
// 		const formattedCustomImages = (customImages ?? []).map(
// 			(_image: {imageURL: any}) => _image.imageURL,
// 		);

// 		return [
// 			getMainImage(additionalImages),
// 			...(formattedCustomImages.length > 0
// 				? formattedCustomImages
// 				: formattedImages),
// 		];
// 	}
// };

// export const getMainImage = (imagesArray: any[]) => {
// 	try {
// 		// Find image.primary in array
// 		const primaryImage = (imagesArray ?? [])?.find(
// 			(image: {primary: boolean}) => image.primary,
// 		);

// 		// If no primary image, return first image in array
// 		if (!primaryImage) return imagesArray?.[0]?.url ?? blankImage;

// 		// If primary image, return primary image
// 		return primaryImage?.url ?? blankImage;
// 	} catch (error) {
// 		return blankImage;
// 	}
// };

// export const PxRecordError = (customErrorMessage: string, metaData?: any) => {
// 	console.log("PxRecordError", customErrorMessage, metaData ?? "");
// 	try {
// 		throw new Error(
// 			`${customErrorMessage} ${JSON.stringify(metaData ?? "")}`,
// 		);
// 	} catch (error: any) {
// 		crashlytics().recordError({
// 			name: "PxRecordError",
// 			message: JSON.stringify(error),
// 		});
// 	}
// };
