import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorStore, useMiscStore } from "../../../../state/store";
import { NetworkCode } from "../../../../Utils/network";
import { RiArrowLeftLine } from "react-icons/ri";
import { colors } from "../../../../Utils/colors";

const ErrorView = () => {
    const navigate = useNavigate();
    const params = useParams();

    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const getErrors = useErrorStore((state) => state.getErrors);

    const [error, setError] = useState<any>(null);
    const [parsedError, setParsedError] = useState<any>(null);

    const {
        backButton,
        errorPage,
        leftError,
        rightError,
        errorTitle,
        errorMsg,
        preStyle,
        errorLabel,
        errorValue,
    } = styles(colors, getThemeColor);

    const goBack = () => {
        navigate(-1);
    };

    const fetchError = async (id: string) => {
        const response = await getErrors({ id: id });
        if (response.status === NetworkCode.OK) {
            setError(response.data.errors);
        } else {
            console.log("error fetching error", response?.data?.message);
        }
    };

    useEffect(() => {
        if (params?.id) {
            fetchError(params.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    useEffect(() => {
        if (error) {
            const parsed = JSON.parse(error.metaData);
            setParsedError(parsed);
        } else {
            setParsedError(null);
        }
    }, [error]);

    return (
        <div>
            <button onClick={goBack} style={backButton}>
                <RiArrowLeftLine />
                Back
            </button>
            {error ? (
                <div style={errorPage}>
                    <div style={leftError}>
                        <div style={errorTitle}>{error?.title}</div>
                        <div style={errorMsg}>{parsedError?.message}</div>
                        <div>
                            <pre style={preStyle}>
                                {JSON.stringify(parsedError, undefined, 4)}
                            </pre>
                        </div>
                    </div>
                    <div style={rightError}>
                        <div>
                            <div style={errorLabel}>Error ID</div>
                            <div style={errorValue}>{error?._id}</div>
                        </div>
                        <div>
                            <div style={errorLabel}>User ID</div>
                            <div style={errorValue}>
                                {error?.userId ?? "N/A"}
                            </div>
                        </div>
                        <div>
                            <div style={errorLabel}>Version</div>
                            <div style={errorValue}>
                                v{error?.otaVersion ?? "0"}{" "}
                                {error?.version ?? ""}
                            </div>
                        </div>
                        <div>
                            <div style={errorLabel}>Platform</div>
                            <div style={errorValue}>{error?.platform}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>loading...</div>
            )}
        </div>
    );
};

export default ErrorView;

const styles = (colors: any, getThemeColor: any) => {
    return {
        backButton: {
            backgroundColor: getThemeColor({
                light: colors.GREY_0,
                dark: colors.DARKGREY_16,
            }),
            color: getThemeColor({
                light: colors.DARKGREY_16,
                dark: colors.GREY_0,
            }),
            border: `1px solid ${colors.GREY_6}`,
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            gap: 5,
            padding: "5px 10px",
            marginBottom: 20,
        } as React.CSSProperties,
        errorPage: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "flex-start",
            flexWrap: "wrap",
            gap: 20,
        } as React.CSSProperties,
        leftError: {
            flex: 1,
        } as React.CSSProperties,
        rightError: {
            backgroundColor: getThemeColor({
                light: colors.GREY_2,
                dark: colors.DARKGREY_14,
            }),
            padding: 20,
            borderRadius: 4,
            color: getThemeColor({
                light: colors.DARKGREY_16,
                dark: colors.GREY_0,
            }),
            display: "flex",
            flexDirection: "column",
            gap: 10,
        } as React.CSSProperties,
        errorTitle: {
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 10,
            color: getThemeColor({
                light: colors.DARKGREY_16,
                dark: colors.GREY_0,
            }),
        } as React.CSSProperties,
        errorMsg: {
            fontSize: 16,
            marginBottom: 20,
            color: getThemeColor({
                light: colors.DARKGREY_16,
                dark: colors.GREY_0,
            }),
        } as React.CSSProperties,
        errorLabel: {
            fontSize: 14,
            fontWeight: 600,
            marginBottom: 5,
        } as React.CSSProperties,
        errorValue: {
            fontSize: 14,
        } as React.CSSProperties,
        preStyle: {
            backgroundColor: getThemeColor({
                light: colors.GREY_1,
                dark: colors.DARKGREY_15,
            }),
            color: getThemeColor({
                light: colors.DARKGREY_16,
                dark: colors.GREY_0,
            }),
            padding: 10,
            borderRadius: 4,
            fontSize: 14,
            whiteSpace: "pre-wrap",
        } as React.CSSProperties,
    };
};
