import React from "react";
import { useMiscStore } from "../../state/store";
// import BottomBillboard from "../LandingScreen/BottomBillboard";
// import ReviewBanner from "./ReviewBanner";
import TeamSection from "./TeamSection";
// import FAQBanner from "./FAQBanner";
// import ValuesSection from "./ValuesSection";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";

// const historyBannerImg = require("../../images/history-banner.png");

export default function OurHistoryScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const {
        containerStyles,
        bannercontainerStyle,
        titleStyles,
        subtitleStyle,
        // bannerImgStyle,
        // h2Style,
        storyContainerStyle,
        storyParagraphStyle,
        separatorStyles,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    return (
        <div>
            {/* top */}
            <div style={containerStyles}>
                <div style={bannercontainerStyle}>
                    <div>
                        <h1 style={titleStyles}>
                            <span style={{ color: colors.INDIGO }}>OUR</span>{" "}
                            PURPOSE
                        </h1>
                        <div style={subtitleStyle}>
                            To connect people in community through quality,
                            authentic products.
                        </div>
                    </div>
                    {/* <img
                        src={historyBannerImg}
                        alt="history banner"
                        style={bannerImgStyle}
                    /> */}
                </div>
            </div>

            {/* our story */}
            <div style={containerStyles}>
                {/* <h2 style={h2Style}>Our Story</h2> */}
                <div style={storyContainerStyle}>
                    <div style={storyParagraphStyle}>
                        Being in LA, we saw the way sneakers and streetwear can
                        help people express themselves and make friends. We
                        wanted to open this experience safely to the entire US.
                        To do that, we hired top authenticators from the LA area
                        with over a decade of authentication experience at
                        institutions like FlightClub to bring safe trading,
                        buying, and selling to thousands of collectors.
                    </div>
                </div>
            </div>

            {/* review */}
            {/* <ReviewBanner /> */}

            {/* <div style={separatorStyles}></div> */}

            {/* values */}
            {/* <ValuesSection /> */}

            <div style={separatorStyles}></div>

            {/* team */}
            <TeamSection />

            {/* <div style={separatorStyles}></div> */}

            {/* faq banner */}
            {/* <FAQBanner /> */}

            {/* bottom billboard */}
            {/* <BottomBillboard /> */}
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    containerStyles: {
        width: "90%",
        maxWidth: 1200,
        margin: "60px auto 0",
    } as React.CSSProperties,
    bannercontainerStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: isTabletOrMobile ? "wrap" : "nowrap",
        gap: 100,
    } as React.CSSProperties,
    titleStyles: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: isTabletOrMobile ? 52 : 74,
        fontWeight: 700,
        letterSpacing: "7.4px",
        textTransform: "uppercase",
        marginBottom: 50,
    } as React.CSSProperties,
    subtitleStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: isTabletOrMobile ? 32 : 48,
        fontWeight: 700,
        letterSpacing: "-1.44px",
        lineHeight: isTabletOrMobile ? "35.2px" : "52.8px",
    } as React.CSSProperties,
    bannerImgStyle: {
        width: "100%",
        maxWidth: 600,
        borderRadius: 20,
    } as React.CSSProperties,
    h2Style: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: isTabletOrMobile ? 28 : 40,
        fontWeight: 700,
        letterSpacing: "4px",
        textTransform: "uppercase",
        marginBottom: 24,
    } as React.CSSProperties,
    storyContainerStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 24,
    } as React.CSSProperties,
    storyParagraphStyle: {
        color: colors.GREY_6,
        fontSize: isTabletOrMobile ? 14 : 20,
        fontWeight: 500,
        // letterSpacing: "-0.6px",
        lineHeight: isTabletOrMobile ? "22px" : "26.8px",
    } as React.CSSProperties,
    separatorStyles: {
        width: "90%",
        height: 1,
        backgroundColor: getThemeColor({
            light: colors.GREY_2,
            dark: colors.DARKGREY_12,
        }),
        margin: "80px auto",
        maxWidth: 1200,
    } as React.CSSProperties,
});
