import { NetworkCode } from "../Utils/network";
import { pxFetch } from "../Utils/utils";
import { FetchReturn } from "./store";
import produce from "immer";

export interface IUserState {
    // States
    user: any | null;
    tempFirst: string;
    tempLast: string;

    //////////////////////////
    // State management
    //////////////////////////
    isAdmin: () => boolean;
    setProfile: (data: any) => void;

    //////////////////////////
    // Apis
    //////////////////////////
    getUser: (userId: string) => Promise<FetchReturn>;
    updateUser: (userId: string, data: any) => Promise<FetchReturn>;
    removeUser: (userId: string) => Promise<FetchReturn>;

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearUserStore: () => void;
}

export const userStore = (
    set: any,
    get: () => IUserState,
    api: any,
): IUserState => ({
    // States
    user: null,
    tempFirst: "",
    tempLast: "",

    //////////////////////////
    // State management
    //////////////////////////
    isAdmin: () => {
        const user = get().user;
        if (user) {
            return user?.admin === true;
        } else {
            return false;
        }
    },
    setProfile: (data: any) => {
        set(
            produce((state: any) => {
                state.user.profile.default = {
                    ...state?.user?.profile?.default,
                    ...data,
                };
            }),
            false,
            "setProfileData",
        );
    },

    //////////////////////////
    // Apis
    //////////////////////////
    getUser: async (userId: string) => {
        const response = await pxFetch.get(`/user/${userId}`);
        return response;
    },
    updateUser: async (userId: string, data: any) => {
        const response = await pxFetch.put(`/user/${userId}`, data);
        if (response.status === NetworkCode.OK) {
            set(
                produce((state: any) => {
                    state.user = { ...state.user, ...response.data.user };
                }),
                false,
                "updateUser",
            );
        }
        return response;
    },
    removeUser: async (userId: string) => {
        const response = await pxFetch.delete(`/user/${userId}`);
        return response;
    },

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearUserStore: () => {
        set(() => ({
            // user: null, do not need to clear user because authstatechange will do it
            tempFirst: "",
            tempLast: "",
        }));
    },
});

export default userStore;
