export const FIREBASE_LOCAL_URLS = {
    functions: {
        url: "localhost",
        port: 5001,
    },
    auth: {
        url: "http://localhost:9099",
    },
    database: {
        url: "localhost",
        port: 9001,
    },
    storage: {
        url: "localhost",
        port: 9199,
    },
    firestore: {
        url: "localhost",
        port: 8080,
    },
};

export const FIREBASE_LOCAL_CONFIG = {
    apiKey: "AIzaSyBX4HGmztAIQv7Vx7V7PtSRFo-qCMnpuBg",
    authDomain: "pxdev-f0e02.firebaseapp.com",
    databaseURL: "http://localhost:9001/?ns=pxdev-f0e02-default-rtdb",
    projectId: "pxdev-f0e02",
    storageBucket: "pxdev-f0e02.appspot.com",
    messagingSenderId: "895710786587",
    appId: "1:895710786587:web:df08dcfd05e5148b35b4be",
    measurementId: "G-27YP43FDST",
};

export const FIREBASE_DEV_CONFIG = {
    apiKey: "AIzaSyBX4HGmztAIQv7Vx7V7PtSRFo-qCMnpuBg",
    authDomain: "pxdev-f0e02.firebaseapp.com",
    databaseURL: "https://pxdev-f0e02-default-rtdb.firebaseio.com",
    projectId: "pxdev-f0e02",
    storageBucket: "pxdev-f0e02.appspot.com",
    messagingSenderId: "895710786587",
    appId: "1:895710786587:web:df08dcfd05e5148b35b4be",
    measurementId: "G-27YP43FDST",
};

export const FIREBASE_PROD_CONFIG = {
    apiKey: "AIzaSyDqUxWf17UqpM_cowOdSR13M1mINIRxmzE",
    // authDomain: "pxprod-8818e.firebaseapp.com",
    authDomain: "rtro.com",
    databaseURL: "https://pxprod-8818e-default-rtdb.firebaseio.com",
    projectId: "pxprod-8818e",
    storageBucket: "pxprod-8818e.appspot.com",
    messagingSenderId: "30218799415",
    appId: "1:30218799415:web:b7497aa327bc93780bf2b2",
    measurementId: "G-4FE5F1QBNS",
};
