import { pxFetch } from "../Utils/utils";
import { FetchReturn } from "./store";

// -------------------------------------------------------------------------------------------------------------------
// This store is a NON PERSISTED store for random variables to get the non rerender features of zustand.
// Feel free to put anything in this store that is needed that doesnt need an entire store of its own.
// Example:
// The profile page tabview, we want to pass the page data to each of the tabs without prop drilling. And even
// with prop drilling, the index of the tab is changing which causes rerenders which can be stopped by using zustand.
// -------------------------------------------------------------------------------------------------------------------

export interface IErrorState {
    // States

    //////////////////////////
    // State management
    //////////////////////////

    //////////////////////////
    // Apis
    //////////////////////////
    getErrors: (data: any) => Promise<FetchReturn>;

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearErrorStore: () => void;
}

export const errorStore = (
    set: any,
    get: () => IErrorState,
    api: any,
): IErrorState => ({
    // States

    //////////////////////////
    // State management
    //////////////////////////

    //////////////////////////
    // Apis
    //////////////////////////
    getErrors: async (data: any) => {
        // build url from data
        let url = `/errors?`;
        if (Object.entries(data).length > 0) {
            Object.entries(data).forEach(([key, value]) => {
                url += `${key}=${value}&`;
            });
        }

        console.log("errors: ", url);

        const response = await pxFetch.get(url, data);
        return response;
    },

    //////////////////////////
    // Realtime actions
    //////////////////////////

    //////////////////////////
    // Cleanup
    //////////////////////////
    clearErrorStore: () => {
        set(() => ({}), false, "Clearing store");
    },
});

export default errorStore;
