import React, { useState } from "react";
import { useMiscStore } from "../../state/store";
import { NetworkCode } from "../../Utils/network";
import { colors } from "../../Utils/colors";

export default function UploadModelImagesScreen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const uploadModelImage = useMiscStore((state) => state.uploadModelImage);

    const {
        containerStyle,
        titleStyle,
        formDivStyle,
        labelStyle,
        inputStyle,
        buttonStyle,
        previewStyle,
    } = styles(colors, getThemeColor);

    const [model, setModel] = useState<string>("");
    const [file, setFile] = useState<any | null>(null);

    const tryUpload = async () => {
        if (!model || !file) {
            alert("Please fill out all fields");
            return;
        }

        const images = [
            {
                ...file,
                order: 0,
            },
        ];

        const response = await uploadModelImage(model, images);
        if (response.status === NetworkCode.OK) {
            alert("Upload successful");
            setModel("");
            setFile(null);
        } else {
            alert("Upload failed, please try again");
        }
    };

    const trySetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] ?? null;
        const fileData: any = {};
        if (!file) {
            setFile(null);
            return;
        }

        fileData.src = URL.createObjectURL(file);

        const reader = new FileReader();
        reader.onloadend = (e) => {
            const base64String = reader?.result?.toString() ?? "";
            fileData.base64 = base64String;
            setFile(fileData);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div style={containerStyle}>
            <div style={titleStyle}>Upload Custom Images</div>

            <form
                onSubmit={(e: any) => {
                    e.preventDefault();
                    tryUpload();
                }}>
                <div style={formDivStyle}>
                    <div style={labelStyle}>Model</div>
                    <input
                        type="text"
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        style={inputStyle}
                    />
                </div>

                <div style={formDivStyle}>
                    <div style={labelStyle}>Image</div>
                    <input
                        type="file"
                        accept=".png,.jpg"
                        onChange={trySetFile}
                    />
                </div>

                <div>
                    {file && (
                        <img
                            src={file?.src}
                            alt="preview"
                            style={previewStyle}
                        />
                    )}
                </div>

                <div>
                    <button onClick={() => {}} style={buttonStyle}>
                        Upload
                    </button>
                </div>
            </form>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    containerStyle: {
        width: "90%",
        maxWidth: "1200px",
        margin: "40px auto",
    } as React.CSSProperties,
    titleStyle: {
        fontSize: "28px",
        fontWeight: "700",
        marginBottom: "40px",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    formDivStyle: {
        marginBottom: "20px",
    } as React.CSSProperties,
    labelStyle: {
        fontSize: "12px",
        fontWeight: "700",
        letterSpacing: "-0.02em",
        marginBottom: "5px",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    inputStyle: {
        borderRadius: "4px",
        border: `1px solid ${colors.GREY_6}`,
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_15,
        }),
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        padding: "10px",
    } as React.CSSProperties,
    buttonStyle: {
        backgroundColor: colors.INDIGO,
        color: colors.WHITE,
        borderRadius: "4px",
        padding: "6px 12px",
        border: "none",
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: "-0.03em",
        lineHeight: "140%",
        cursor: "pointer",
    } as React.CSSProperties,
    previewStyle: {
        width: "100%",
        maxWidth: "100px",
        marginBottom: "20px",
    } as React.CSSProperties,
});
