import React from "react";
import {
    LineChartOutlined,
    CommentOutlined,
    UserOutlined,
    SkinOutlined,
    RobotOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import RtroPage from "../RtroPage";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items: MenuProps["items"] = [
    getItem("Admin", "adminhead", <></>, [
        getItem("Trades", "trades", <CommentOutlined />),
        getItem("Analytics", "analytics", <LineChartOutlined />),
        getItem("Users", "users", <UserOutlined />),
        getItem("Warehouse", "warehouse", <SkinOutlined />),
        getItem("Bot Management", "bot", <RobotOutlined />),
        getItem("Error Log", "errors", <WarningOutlined />),
    ]),
];

const AdminDashboardContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const currentPage = location.pathname.split("/").pop();

    const onClick: MenuProps["onClick"] = (e) => {
        navigate(`/dashboard/${e.key}`);
    };

    return (
        <RtroPage fullWidth={true}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                }}>
                <Menu
                    onClick={onClick}
                    style={{ width: 256, height: "100%" }}
                    defaultSelectedKeys={[currentPage || "trades"]}
                    defaultOpenKeys={["adminhead"]}
                    mode="inline"
                    items={items}
                />
                <div
                    style={{
                        width: "100%",
                        padding: "0 20px",
                    }}>
                    <Outlet />
                </div>
            </div>
        </RtroPage>
    );
};

export default AdminDashboardContainer;
