import React from "react";
import Navbar from "../Components/Navbar";
import { useMiscStore } from "../state/store";
import Footer from "../Components/Footer";
import { colors } from "../Utils/colors";

export default function RtroPage({ fullWidth, children }: any) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { backgroundStyle, pageStyle } = styles(colors, getThemeColor);

    return (
        <div style={backgroundStyle}>
            <div style={pageStyle}>
                <div
                    style={{
                        width: "100%",
                        maxWidth: fullWidth ? "unset" : "1200px",
                        margin: "auto",
                    }}>
                    <Navbar />
                </div>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        maxWidth: fullWidth ? "unset" : "1200px",
                    }}>
                    {children}
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    backgroundStyle: {
        fontFamily: "Outfit, sans-serif",
        backgroundColor: getThemeColor({
            light: colors.GREY_0,
            dark: colors.DARKGREY_16,
        }),
        minHeight: "100vh",
    } as React.CSSProperties,
    pageStyle: {
        margin: "auto",
        padding: "40px 0 0 0",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
    } as React.CSSProperties,
});
