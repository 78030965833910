import React from "react";

import { Table, Tag, Avatar, Collapse, Button, Image } from "antd";
import { useMiscStore, useTradesStore } from "../../../state/store";
import { colors } from "../../../Utils/colors";

const { Panel } = Collapse;

export const TradesTable: React.FC<any> = ({ trades }) => {
    const renderFinalizedData = (data: any) => {
        if (
            !data.finalizedData ||
            !data.finalizedData.profiles ||
            !data.finalizedData.participants
        ) {
            return null;
        }
        const participants = data?.finalizedData?.participants;
        const profiles = data?.finalizedData?.profiles;
        const finalOffers = data?.finalizedData?.finalOffers;
        const payments = data?.finalizedData?.payments;

        const renderParticipantDetails = (participant: string) => {
            const profile = profiles[participant];
            return (
                <div>
                    <div>
                        id: {profile?.uid} - {profile?.username}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                        <Avatar
                            src={profile?.profile?.default?.profilePhotoUrl}
                        />
                        <div
                            style={{
                                paddingLeft: 10,
                            }}>
                            <div>
                                First Name:
                                {profile?.profile?.default?.firstName}
                            </div>
                            <div>
                                Last Name:
                                {profile?.profile?.default?.lastName}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };
        const renderFinalOffers = (participant: string) => {
            const finalOffer: any = Object.entries(finalOffers)?.find(
                ([offerId, offerDetails]: any) =>
                    offerDetails.createdBy === participant,
            );
            return (
                <Panel header="Final Offer" key="finalOfferPanel">
                    <div>
                        invalidated:{" "}
                        {typeof finalOffer?.invalidated !== "undefined" &&
                            finalOffer?.invalidated.toString()}{" "}
                        - total Estimated val: {finalOffer?.totalEstimatedValue}
                    </div>
                    <div>
                        seen:{" "}
                        {typeof finalOffer?.seen !== "undefined" &&
                            finalOffer?.seen.toString()}
                    </div>

                    <div>
                        created:{" "}
                        {new Date(
                            parseInt(finalOffer?.createdAt),
                        ).toLocaleString()}
                    </div>
                    <div>
                        items:
                        {finalOffer.items &&
                            finalOffer.items.map((item: any, index: number) => {
                                return (
                                    <div
                                        key={`finaloffer-${index}`}
                                        style={{
                                            backgroundColor: "lightgray",
                                            borderRadius: 12,
                                            padding: 4,
                                            marginBottom: 4,
                                        }}>
                                        type: {item?.type} - inventory id:{" "}
                                        {item?.inventoryItemId}
                                        <Button
                                            type="link"
                                            onClick={() =>
                                                console.log(
                                                    "OPEN ITEM:",
                                                    item?._id,
                                                )
                                            }>
                                            See Item details
                                        </Button>
                                    </div>
                                );
                            })}
                    </div>
                </Panel>
            );
        };
        const renderPayments = (participant: string) => {
            const payment = payments[participant];
            const address = payment?.address;
            return (
                <Panel header="Payment" key="paymentpanel">
                    <div>
                        additionalCashUSD: {payment?.additionalCashUSD} - stripe
                        ID: {payment?.stripePaymentIntentId}
                    </div>
                    <div>fees: {payment?.fees}</div>
                    <div>shipping: {payment?.shipping}</div>
                    <div>tax: {payment?.tax}</div>
                    <div>total: {payment?.total}</div>

                    {address && (
                        <div>
                            address:
                            <div>
                                {address?.address1} - {address?.address2} -{" "}
                                {address?.city} - {address?.country} - zip{" "}
                                {address?.zip} - {address?.state} - phone:{" "}
                                {address?.phone}
                            </div>
                        </div>
                    )}

                    {payment?.sendLabelDownload && (
                        <>
                            <Button
                                type="link"
                                href={payment?.sendLabelDownload?.href}
                                target="_blank">
                                Warehouse Label
                            </Button>
                            <Button
                                type="link"
                                href={payment?.sendLabelDownload?.pdf}
                                target="_blank">
                                PDF
                            </Button>
                        </>
                    )}
                </Panel>
            );
        };

        return participants.map((participant: any, index: number) => {
            return (
                <div
                    key={`participant-${index}`}
                    style={{
                        borderWidth: 1,
                        border: "solid",
                        borderColor: "lightgray",
                        borderRadius: 12,
                        padding: 6,
                        marginTop: 6,
                    }}>
                    {renderParticipantDetails(participant)}
                    <Collapse>
                        {finalOffers && renderFinalOffers(participant)}
                        {payments && renderPayments(participant)}
                    </Collapse>
                </div>
            );
        });
    };

    const renderItems = (data: any) => {
        return (
            <div>
                <Collapse>
                    <Panel header="Items" key="itemsPanel">
                        {data.items &&
                            data.items.map((el: any, index: number) => {
                                const itemData = el.itemData;
                                const modelData = el.modelData;
                                return (
                                    <div
                                        key={`item-${index}`}
                                        style={{
                                            backgroundColor: "lightgray",
                                            padding: 6,
                                            borderRadius: 3,
                                            marginBottom: 12,
                                        }}>
                                        {itemData.userId && (
                                            <div>owner: {itemData?.userId}</div>
                                        )}
                                        {modelData && (
                                            <div>
                                                <div>
                                                    Title: {modelData?.title}
                                                </div>
                                                <Image
                                                    width={100}
                                                    src={
                                                        modelData?.images[0]
                                                            ?.url
                                                    }
                                                />
                                                <div>
                                                    sku: {modelData?.sku} -
                                                    slug: {modelData?.slug}
                                                </div>
                                                <div>
                                                    Est. val:{" "}
                                                    {modelData?.pricing
                                                        ?.estimatedValue || 0}
                                                </div>
                                            </div>
                                        )}
                                        <div>brand: {el?.brandData?.title}</div>
                                        {itemData && (
                                            <div>
                                                <div>
                                                    modelSlug:{" "}
                                                    {itemData?.modelSlug}
                                                </div>
                                                <div>
                                                    condition:{" "}
                                                    {itemData?.condition} -
                                                    size:{" "}
                                                    {itemData?.properties?.size}{" "}
                                                    - gender:{" "}
                                                    {
                                                        itemData?.properties
                                                            ?.gender
                                                    }{" "}
                                                    - size:{" "}
                                                    {itemData?.properties?.size}
                                                </div>
                                                {itemData?.properties
                                                    ?.issues && (
                                                    <div>
                                                        issues:
                                                        {Object.keys(
                                                            itemData?.properties
                                                                ?.issues,
                                                        ).map(
                                                            (
                                                                issue: any,
                                                                index: number,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={`issue-${index}`}>
                                                                        {issue}:{" "}
                                                                        {itemData?.properties?.issues[
                                                                            issue
                                                                        ].toString()}
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </Panel>
                </Collapse>
            </div>
        );
    };

    const columns = [
        {
            title: "Trade details",
            dataIndex: "_id",
            key: "_id",
            render: (text: string, data: any) => (
                <div>
                    <div>{text}</div>
                    {/* <div>invalidated: {data?.invalidated.toString()}</div> */}
                    {renderItems(data)}
                </div>
            ),
        },
        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (createdBy: string, data: any) => (
                <>
                    {}
                    <p>id: {createdBy}</p>
                    {data?.createdByUsername && (
                        <p>Username: {data?.createdByUsername[0]}</p>
                    )}
                    {data?.createdByProfile && data?.createdByProfile[0] && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                            <Avatar
                                src={data?.createdByProfile[0].profilePhotoUrl}
                            />
                            <div
                                style={{
                                    paddingLeft: 10,
                                }}>
                                <div>
                                    First Name:
                                    {data?.createdByProfile[0].firstName}
                                </div>
                                <div>
                                    Last Name:
                                    {data?.createdByProfile[0].lastName}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ),
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: string) => new Date(parseInt(text)).toLocaleString(),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: string) => <Tag color="blue">{text}</Tag>,
        },
        {
            title: "Offer Filters",
            dataIndex: "offerFilters",
            key: "offerFilters",
            render: (offerFilters: any["offerFilters"]) => (
                <>
                    {offerFilters.models && (
                        <Tag color="green">{`Models: ${offerFilters.models.join(
                            ", ",
                        )}`}</Tag>
                    )}
                    {offerFilters.brands && (
                        <Tag color="purple">{`Brands: ${offerFilters.brands.join(
                            ", ",
                        )}`}</Tag>
                    )}
                    {offerFilters.colors && (
                        <Tag color="gold">{`Colors: ${offerFilters.colors.join(
                            ", ",
                        )}`}</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Finalized Data",
            dataIndex: "finalizedData",
            key: "finalizedData",
            render: (finalizedData: any["finalizedData"], data: any) => (
                <div
                    style={{
                        width: 400,
                    }}>
                    {renderFinalizedData(data)}
                </div>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={trades}
            rowKey={(record) => record._id}
            pagination={{ pageSize: 10 }}
            scroll={{ x: true }}
        />
    );
};

const TradesScreen = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const adminTrades = useTradesStore((state) => state.adminTrades);
    const getAdminTrades = useTradesStore((state) => state.getAdminTrades);

    React.useEffect(() => {
        getAdminTrades(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                overflowX: "auto",
            }}>
            <h1
                style={{
                    color: getThemeColor({
                        light: colors.DARKGREY_16,
                        dark: colors.GREY_0,
                    }),
                }}>
                Trades
            </h1>
            <TradesTable trades={adminTrades} />
        </div>
    );
};

export default TradesScreen;
