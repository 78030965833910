// render user profile

import { Card, Collapse, Image } from "antd";
import { useEffect, useState } from "react";
import { pxFetch } from "../../../../Utils/utils";
import { useParams } from "react-router-dom";
import { TradesTable } from "../Trades";
const { Panel } = Collapse;

const UserProfile = () => {
    const { id } = useParams();
    const [userProfile, setUserProfile] = useState<any>(null);

    const [inventory, setInventory] = useState<any>([]);
    const [wishlist, setWishlist] = useState<any>([]);
    const [trades, setTrades] = useState<any>([]);

    const getData = async () => {
        const response = await pxFetch.get(`/user/${id}`);
        const inventoryData = await pxFetch.get(`/inventory/${id}?limit=40`);
        const wishlistData = await pxFetch.get(`/wishlist/${id}?limit=40`);
        const tradesData = await pxFetch.get(
            `/trade/createdByMe/?userId=${id}&`,
        );
        setInventory(inventoryData?.data?.items || []);
        setUserProfile(response.data.user);
        setWishlist(wishlistData?.data?.wishlist || []);
        setTrades(tradesData?.data?.trades || []);
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!userProfile) return <div>Loading user data</div>;

    const renderInterests = () => {
        const interests = {
            ...userProfile.interests,
            shoeData: userProfile.shoeData,
            streetwearData: userProfile.streetwearData,
        };
        const interestKeys = Object.keys(interests);
        const interestValues = Object.values(interests);

        const interestList = interestKeys.map((interest, index) => {
            return (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 12,
                    }}>
                    <Collapse>
                        <Panel header={interest} key={interest}>
                            {Object.keys(interestValues[index] || []).map(
                                (key: string, subIndex: number) => {
                                    const value =
                                        (interestValues[index] as any) || {};

                                    return (
                                        <p key={key + index}>
                                            {key}:
                                            {value[key]
                                                ? value[key].valueOf()
                                                : "false"}
                                        </p>
                                    );
                                },
                            )}
                        </Panel>
                    </Collapse>
                </div>
            );
        });

        return (
            <div>
                <h3>Interests</h3>
                {interestList}
            </div>
        );
    };

    const renderItems = (items: any, label: string) => {
        return (
            <div
                style={{
                    paddingTop: 12,
                }}>
                <Collapse>
                    <Panel header={label} key="itemsPanel">
                        {items &&
                            items.map((el: any) => {
                                const itemData = el;
                                const modelData = el.modelData;

                                return (
                                    <div
                                        style={{
                                            backgroundColor: "lightgray",
                                            padding: 6,
                                            borderRadius: 3,
                                            marginBottom: 12,
                                        }}>
                                        {itemData.userId && (
                                            <div>owner: {itemData?.userId}</div>
                                        )}
                                        {modelData && (
                                            <div>
                                                <div>
                                                    Title: {modelData?.title}
                                                </div>
                                                <Image
                                                    width={100}
                                                    src={
                                                        modelData?.images[0]
                                                            ?.url
                                                    }
                                                />
                                                <div>
                                                    sku: {modelData?.sku} -
                                                    slug: {modelData?.slug}
                                                </div>
                                                <div>
                                                    Est. val:{" "}
                                                    {modelData?.pricing
                                                        ?.estimatedValue || 0}
                                                </div>
                                            </div>
                                        )}
                                        <div>brand: {el?.brandData?.title}</div>
                                        {itemData && (
                                            <div>
                                                <div>
                                                    modelSlug:{" "}
                                                    {itemData?.modelSlug}
                                                </div>
                                                <div>
                                                    condition:{" "}
                                                    {itemData?.condition} -
                                                    size:{" "}
                                                    {itemData?.properties?.size}{" "}
                                                    - gender:{" "}
                                                    {
                                                        itemData?.properties
                                                            ?.gender
                                                    }{" "}
                                                    - size:{" "}
                                                    {itemData?.properties?.size}
                                                </div>
                                                {itemData?.properties
                                                    ?.issues && (
                                                    <div>
                                                        issues:
                                                        {Object.keys(
                                                            itemData?.properties
                                                                ?.issues,
                                                        ).map((issue: any) => {
                                                            return (
                                                                <div>
                                                                    {issue}:{" "}
                                                                    {itemData?.properties?.issues[
                                                                        issue
                                                                    ].toString()}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </Panel>
                </Collapse>
            </div>
        );
    };
    const created = new Date(userProfile.createdAt).toDateString();
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 40,
                }}>
                <Card
                    style={{
                        backgroundColor: "#E9ECEF",
                    }}
                    cover={
                        <img
                            width={280}
                            height={500}
                            alt="example"
                            src={userProfile.profile.default.profilePhotoUrl}
                        />
                    }>
                    <p>Username: {userProfile.username}</p>
                    <p>First Name: {userProfile.profile.default.firstName}</p>
                    <p>Last Name: {userProfile.profile.default.lastName}</p>
                    <p>Email: {userProfile.email}</p>
                    <p>uid: {userProfile.uid}</p>
                </Card>
                <Card
                    style={{
                        flex: 1,
                        backgroundColor: "#E9ECEF",
                    }}>
                    <p>created: {created} </p>
                    <p>Followers: {userProfile?.followers?.length ?? 0} </p>
                    <p>Following: {userProfile?.following?.length ?? 0} </p>
                    <p>Following: {userProfile?.following?.length ?? 0} </p>
                    {renderInterests()}
                    {renderItems(inventory, "Inventory")}
                    {renderItems(wishlist, "Wishlist")}
                </Card>
            </div>
            <Card
                style={{
                    flex: 1,
                    marginTop: 40,
                    backgroundColor: "#E9ECEF",
                }}>
                <TradesTable trades={trades} />
            </Card>
        </div>
    );
};

export default UserProfile;
