import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import userStore, { IUserState } from "./userStore";

import authStore, { IAuthState } from "./authStore";
import miscStore from "./miscStore";
import tradesStore from "./tradesStore";
import errorStore from "./errorStore";

export type FetchReturn = {
    status: number;
    message: string;
    data?: any;
};

export type DataReturn = {
    status: number;
    data: any;
};

export type IStoreSchema = IAuthState & IUserState;

export const useUserStore = create(
    devtools(
        persist(userStore, {
            name: "UserStore", // local storage name
            getStorage: () => localStorage,
        }),
        {
            name: "UserStore",
        },
    ),
);
export const useTradesStore = create(
    devtools(
        persist(tradesStore, {
            name: "Trades",
            getStorage: () => localStorage,
        }),
        {
            name: "Trades",
        },
    ),
);

export const useAuthStore = create(
    devtools(authStore, {
        name: "AuthStore",
    }),
);

export const useMiscStore = create(
    devtools(miscStore, {
        name: "miscStore",
    }),
);

export const useErrorStore = create(
    devtools(errorStore, {
        name: "errorStore",
    }),
);

// For things like logging out
export const clearStores = () => {
    useUserStore.getState().clearUserStore();
    useAuthStore.getState().clearAuthStore();
    useMiscStore.getState().clearMiscStore();
    useErrorStore.getState().clearErrorStore();
};
