export const colors: {
    GREY_0: string;
    GREY_1: string;
    GREY_2: string;
    GREY_3: string;
    GREY_4: string;
    GREY_5: string;
    GREY_6: string;
    GREY_7: string;
    GREY_8: string;
    GREY_9: string;
    DARKGREY_10: string;
    DARKGREY_11: string;
    DARKGREY_12: string;
    DARKGREY_13: string;
    DARKGREY_14: string;
    DARKGREY_15: string;
    DARKGREY_16: string;

    BLACK: string;
    WHITE: string;
    CYAN: string;
    BLUE: string;
    INDIGO: string;
    INDIGO_DARK: string;
    INDIGO_BLUE: string;
    INDIGO_800: string;
    ULTRAVIOLET: string;
    VIOLET_2: string;
    PURPLE_2: string;
    PURPLE: string;
    NEW_RED: string;
    PINK: string;
    GREEN: string;
    RED: string;

    LEMON: string;
    LEMON_YELLOW: string;
    LIGHT_YELLOW: string;
    YELLOW: string;
    DARK_YELLOW: string;
    DARK_MUSTARD: string;
    GOLD: string;
    ORANGE: string;
} = {
    GREY_0: "#FEFEFE",
    GREY_1: "#F8F9FA",
    GREY_2: "#E9ECEF",
    GREY_3: "#DEE2E6",
    GREY_4: "#CED4DA",
    GREY_5: "#ADB5BD",
    GREY_6: "#6C757D",
    GREY_7: "#495057",
    GREY_8: "#343A40",
    GREY_9: "#212529",
    DARKGREY_10: "#212529",
    DARKGREY_11: "#1E2125",
    DARKGREY_12: "#191C1F",
    DARKGREY_13: "#141719",
    DARKGREY_14: "#101214",
    DARKGREY_15: "#0B0C0E",
    DARKGREY_16: "#000",

    BLACK: "#000000",
    WHITE: "#FFFFFF",
    CYAN: "#4CC9F0",
    BLUE: "#4b45ff",
    INDIGO: "#4b45ff",
    INDIGO_DARK: "#6661FF",
    INDIGO_BLUE: "#736FFF",
    INDIGO_800: "#232072",
    ULTRAVIOLET: "#3F37C9",
    VIOLET_2: "#3A0CA3",
    PURPLE_2: "#560BAD",
    PURPLE: "#7209B7",
    NEW_RED: "#D22D4B",
    PINK: "#EF3885",
    GREEN: "#00CD46",
    RED: "#E9493F",

    LEMON: "#5EED82",
    LEMON_YELLOW: "#EDFF00",
    LIGHT_YELLOW: "#FDFEEA",
    YELLOW: "#F2F261",
    DARK_YELLOW: "#796500",
    DARK_MUSTARD: "#191A06",
    GOLD: "#CCB43D",
    ORANGE: "#FD6A2B",
};
