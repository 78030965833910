import React from "react";
import moment from "moment";
import { useMiscStore } from "../../../../state/store";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../../Utils/colors";

type ErrorRowProps = {
    error: any;
    isEmpty: boolean;
};

const ErrorRow = ({ error, isEmpty }: ErrorRowProps) => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const navigate = useNavigate();

    const cellStyle = {
        padding: 10,
        textAlign: "left",
        borderBottom: `1px solid ${colors.GREY_6}`,
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: 14,
        width: "10%",
        maxWidth: 0,
        wordWrap: "break-word",
    } as React.CSSProperties;

    let errorMsg = JSON.parse(error?.metaData ?? "{}")?.message ?? "NULL";

    return (
        <tr
            onClick={() => {
                if (!isEmpty) navigate(`/dashboard/errors/${error._id}`);
            }}>
            {isEmpty ? (
                <td
                    style={{
                        ...cellStyle,
                        textAlign: "center",
                        color: colors.GREY_6,
                    }}
                    colSpan={7}>
                    No errors found
                </td>
            ) : (
                <React.Fragment>
                    <td style={{ ...cellStyle, width: "5%" }}>
                        {error?.version}
                    </td>
                    <td style={{ ...cellStyle, width: "5%" }}>
                        {error?.userId ?? "null"}
                    </td>
                    <td style={{ ...cellStyle, width: "5%" }}>
                        {error?.platform}
                    </td>
                    <td style={{ ...cellStyle, width: "5%" }}>
                        {error?.otaVersion}
                    </td>
                    <td style={{ ...cellStyle, width: "10%" }}>
                        {moment(error?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td style={{ ...cellStyle, width: "20%" }}>
                        {error?.title}
                    </td>
                    <td style={{ ...cellStyle, width: "40%" }}>{errorMsg}</td>
                </React.Fragment>
            )}
        </tr>
    );
};

export default ErrorRow;
