import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
    connectAuthEmulator,
    getAuth,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { useCallback } from "react";
import {
    FIREBASE_DEV_CONFIG,
    FIREBASE_LOCAL_CONFIG,
    FIREBASE_LOCAL_URLS,
    FIREBASE_PROD_CONFIG,
} from "./../configs/firebase";
import { useMiscStore, useUserStore } from "../state/store";
import { pxFetch } from "../Utils/utils";
import { NetworkCode } from "../Utils/network";

export let app: FirebaseApp;

// idk why but our recent environment changes required me to fuck around and have different configs???
switch (process.env?.REACT_APP_ENV) {
    case "local":
        app = initializeApp(FIREBASE_LOCAL_CONFIG);
        break;
    case "dev":
        app = initializeApp(FIREBASE_DEV_CONFIG);
        break;
    case "prod":
        app = initializeApp(FIREBASE_PROD_CONFIG);
        break;
    default:
        app = initializeApp(FIREBASE_LOCAL_CONFIG);
        break;
}

export const auth = getAuth(app);
export const fs = getFirestore(app);
export const db = getDatabase();
export const functions = getFunctions(getApp());
export const storage = getStorage();
export const analytics = getAnalytics(app);

export const getToken = async () => {
    return await auth.currentUser?.getIdToken();
};
// Connect the emulators
if (process.env?.REACT_APP_ENV === "local") {
    connectFunctionsEmulator(
        functions,
        FIREBASE_LOCAL_URLS.functions.url,
        FIREBASE_LOCAL_URLS.functions.port,
    );
    connectAuthEmulator(auth, FIREBASE_LOCAL_URLS.auth.url, {
        disableWarnings: true,
    });
    connectStorageEmulator(
        storage,
        FIREBASE_LOCAL_URLS.storage.url,
        FIREBASE_LOCAL_URLS.storage.port,
    );
}

const googleProvider = new GoogleAuthProvider();

export const toHex = (stringToConvert: string) => {
    return stringToConvert
        .split("")
        .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
        .join("");
};

const handleAuthIssues = (err: any) => {
    const resp: any = { res: null, status: "error" };

    switch (err.code) {
        case "auth/invalid-email":
            resp.message = "Invalid email address format";
            break;
        case "auth/user-not-found":
            resp.message = "No user found with that email address";
            break;
        case "auth/wrong-password":
            resp.message = "Incorrect password";
            break;
        case "auth/network-request-failed":
            resp.message = "Network error, please try again later";
            break;
        default:
            resp.message = "Error logging in";
            break;
    }

    return resp;
};

export const useAuth = () => {
    const getCurrentUser = useCallback(() => {
        return auth.currentUser;
    }, []);

    const onAuthStateChanged = async (user: any) => {
        if (user) {
            try {
                const response = await pxFetch.post(`/auth/login`, {
                    user: {
                        ...user,
                        username: null,
                    },
                    haveAccount: true,
                });
                if (response?.status === NetworkCode.OK) {
                    useUserStore.setState({ user: response?.data?.user });
                } else {
                    await signOut(auth);
                    useMiscStore.setState({
                        loginError: response.data?.message,
                    });
                    console.log("ERROR2", response);
                    useUserStore.setState({ user: null });
                }
            } catch (error: any) {
                await signOut(auth);
                useMiscStore.setState({ loginError: error?.message });
                console.log("ERROR", error);
                useUserStore.setState({ user: null });
            }
        } else {
            useUserStore.setState({ user: null });
        }
    };

    const signInWithGoogle = async () => {
        try {
            const res = await signInWithPopup(auth, googleProvider);
            return { status: "success", res: res };
        } catch (err) {
            console.log("ERROR:", err);
            logEvent(analytics, "exception", {
                description: `Error signing in with Google: ${JSON.stringify(
                    err,
                )}`,
                fatal: false,
            });
            return handleAuthIssues(err);
        }
    };

    const logInWithEmailAndPassword = async (
        email: string,
        password: string,
    ) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            return { status: "success", res: res };
        } catch (err: any) {
            console.log("ERROR:", err);
            logEvent(analytics, "exception", {
                description: `Error signing in with email and password: ${JSON.stringify(
                    err,
                )}`,
                fatal: false,
            });
            return handleAuthIssues(err);
        }
    };

    const sendPasswordReset = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset link sent!");
        } catch (err) {
            logEvent(analytics, "exception", {
                description: `Error sending password reset: ${JSON.stringify(
                    err,
                )}`,
                fatal: false,
            });
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (err) {
            logEvent(analytics, "exception", {
                description: `Error logging out: ${JSON.stringify(err)}`,
                fatal: false,
            });
        }
    };

    return {
        onAuthStateChanged,
        logInWithEmailAndPassword,
        signInWithGoogle,
        sendPasswordReset,
        getCurrentUser,
        logout,
    };
};
