import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { useUserStore } from "../state/store";

const ProtectedRoute = React.memo((props: any) => {
    const params = useParams();
    const user = useUserStore((state) => state.user);

    // Define the redirect link, preserve community and channel state
    let redirectLink = "/login";
    if (params?.upload) {
        redirectLink = `/login?redirect=${params.upload}`;
    }

    return user ? props.children : <Navigate to={`${redirectLink}`} />;
});

export default ProtectedRoute;
