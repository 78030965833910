import { useState } from "react";
import { colors } from "../Utils/colors";
import { useMiscStore } from "../state/store";

type NavbarLinkProps = {
    text: string;
    onClick: () => void;
    active?: boolean;
};

export default function NavbarLink({
    text,
    onClick,
    active = false,
}: NavbarLinkProps) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const { linkStyle, hoverStyles } = styles(colors, getThemeColor, active);

    const [hover, setHover] = useState(false);

    return (
        <div
            style={{ ...linkStyle, ...(hover ? hoverStyles : {}) }}
            onClick={onClick}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}>
            {text}
        </div>
    );
}

const styles = (colors: any, getThemeColor: any, activeLink: boolean) => {
    return {
        linkStyle: {
            textDecoration: "none",
            color: activeLink
                ? colors.INDIGO
                : getThemeColor({
                      light: colors.GREY_7,
                      dark: colors.GREY_5,
                  }),
            fontSize: "12px",
            letterSpacing: "0.22em",
            fontWeight: "700",
            cursor: "pointer",
            marginTop: "4px",
            transition: "all 0.25s",
        } as React.CSSProperties,
        hoverStyles: {
            opacity: 0.7,
        } as React.CSSProperties,
    };
};
