import React from "react";
import { useMiscStore } from "../state/store";
import { colors } from "../Utils/colors";

const rtro_logo_full_light = require("../images/rtro_logo_full_light.png");
const rtro_logo_full_dark = require("../images/rtro_logo_full_dark.png");

export default function Error404Screen() {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const theme = useMiscStore((state) => state.theme);

    const { pageStyle, imageStyle, bigTextStyle, smallTextStyle, buttonStyle } =
        styles(colors, getThemeColor);

    return (
        <div style={pageStyle}>
            <img
                src={
                    theme === "dark"
                        ? rtro_logo_full_dark
                        : rtro_logo_full_light
                }
                alt="RTRO"
                style={imageStyle}
            />
            <div style={bigTextStyle}>404 Error</div>
            <div style={smallTextStyle}>Page Not Found</div>
            <a href="/" style={buttonStyle}>
                Go Home
            </a>
        </div>
    );
}

const styles = (colors: any, getThemeColor: any) => ({
    pageStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "100px auto",
        width: "90%",
        maxWidth: "1200px",
    } as React.CSSProperties,
    imageStyle: {
        width: "150px",
        height: "150px",
    } as React.CSSProperties,
    bigTextStyle: {
        fontSize: "40px",
        fontWeight: 700,
        marginTop: "20px",
        textAlign: "center",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    smallTextStyle: {
        fontSize: "28px",
        fontWeight: 500,
        marginTop: "20px",
        marginBottom: "50px",
        textAlign: "center",
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
    } as React.CSSProperties,
    buttonStyle: {
        backgroundColor: colors.INDIGO,
        color: colors.GREY_0,
        borderRadius: "24px",
        padding: "12px 24px",
        border: "none",
        fontSize: "18px",
        fontWeight: "500",
        letterSpacing: "-0.03em",
        lineHeight: "140%",
        cursor: "pointer",
        textDecoration: "none",
    } as React.CSSProperties,
});
