import React from "react";
import { useMiscStore } from "../../state/store";
import { RiFileCopyLine, RiCheckLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { colors } from "../../Utils/colors";

const app_icon = require("../../images/app_icon.png");
const referralPhone1 = require("../../images/referral-phone-1.png");
const referralPhone2 = require("../../images/referral-phone-2.png");
const referralImage = require("../../images/referral-image.png");
const appstore_btn = require("../../images/appstore_btn.png");
const google_play_btn = require("../../images/google_play_btn.png");

export default function FAQScreen({ hideReferral }: any) {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const routeParams = useParams();

    const [copied, setCopied] = React.useState<boolean>(false);

    const {
        bodyStyle,
        backgroundStyle,
        backgroundLineStyle,
        container,
        featuredPhoneImage,
        referralImageStyle,
        btnContainerStyle,
        googlePlayBtnStyle,
        appstoreBtnStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    return (
        <div style={bodyStyle}>
            <div style={backgroundStyle} />
            <div style={backgroundLineStyle} />
            <div style={{ display: "flex", height: "100%" }}>
                {!isTabletOrMobile && (
                    <div className="hideScrollbar" style={container}>
                        <img
                            src={referralPhone1}
                            alt="app screenshot"
                            style={featuredPhoneImage}
                        />
                    </div>
                )}
                <div className="hideScrollbar" style={container}>
                    <img
                        src={app_icon}
                        alt="app icon"
                        style={{ width: 56, height: 56 }}
                    />
                    <div
                        style={{
                            color: colors.GREY_0,
                            fontFamily: "Outfit, sans-serif",
                            fontSize: 32,
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "110%",
                            letterSpacing: -0.96,
                            marginTop: 24,
                        }}>
                        Welcome to RTRO.
                    </div>
                    {hideReferral && (
                        <div
                            style={{
                                color: colors.GREY_0,
                                marginTop: 16,
                                fontFamily: "Outfit, sans-serif",
                                fontWeight: 400,
                                fontSize: 16,
                                fontStyle: "normal",
                                lineHeight: "120%",
                                letterSpacing: -0.48,
                            }}>
                            Download the app to start buying, selling, and
                            trading.
                        </div>
                    )}
                    {!hideReferral && (
                        <>
                            <div
                                style={{
                                    color: colors.GREY_0,
                                    marginTop: 16,
                                    fontFamily: "Outfit, sans-serif",
                                    fontWeight: 400,
                                    fontSize: 16,
                                    fontStyle: "normal",
                                    lineHeight: "120%",
                                    letterSpacing: -0.48,
                                }}>
                                <span
                                    style={{
                                        fontFamily: "Outfit, sans-serif",
                                        fontWeight: 700,
                                    }}>
                                    $10 value*
                                </span>{" "}
                                for both you and your friend when they complete
                                their first trade.
                            </div>
                            <div
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        routeParams?.referralSlug ?? "",
                                    );
                                    setCopied(true);
                                }}
                                style={{
                                    maxWidth: 320,
                                    width: "100%",
                                    borderRadius: 12,
                                    border: "1px dashed #060606",
                                    padding: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    marginTop: 24,
                                }}>
                                <div
                                    style={{
                                        flexDirection: "column",
                                        flex: 1,
                                    }}>
                                    <div
                                        style={{
                                            flex: 1,
                                            flexDirection: "column",
                                            fontFamily: "Noto Sans",
                                            fontSize: 9,
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "140%",
                                            letterSpacing: 1.44,
                                            textTransform: "uppercase",
                                            color: colors.GREY_0,
                                        }}>
                                        Copy your referral code
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Outfit, sans-serif",
                                            fontSize: 14,
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                            letterSpacing: -0.42,
                                            padding: "12px 10px 16px 10px",
                                            color: colors.GREY_0,
                                            flex: 1,
                                            borderBottom: "2px solid #060606",
                                        }}>
                                        {routeParams?.referralSlug}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 40,
                                        height: 40,
                                        margin: 16,
                                        marginRight: 0,
                                        borderRadius: 20,
                                        background: colors.DARKGREY_16,
                                        cursor: "pointer",
                                    }}>
                                    {copied ? (
                                        <RiCheckLine
                                            size={16}
                                            color={colors.GREY_0}
                                        />
                                    ) : (
                                        <RiFileCopyLine
                                            size={16}
                                            color={colors.GREY_0}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <img
                        src={referralImage}
                        alt="refer your friends"
                        style={referralImageStyle}
                    />
                    <div style={btnContainerStyle}>
                        <a
                            href={
                                "https://play.google.com/store/apps/details?id=com.rtro"
                            }
                            target="_blank"
                            rel="noreferrer">
                            <img
                                src={google_play_btn}
                                alt="google play button"
                                style={googlePlayBtnStyle}
                            />
                        </a>
                        <a
                            href={
                                "https://apps.apple.com/us/app/rtro-trading-marketplace/id1661563424"
                            }
                            target="_blank"
                            rel="noreferrer">
                            <img
                                src={appstore_btn}
                                alt="appstore button"
                                style={appstoreBtnStyle}
                            />
                        </a>
                    </div>
                </div>
                {!isTabletOrMobile && (
                    <div className="hideScrollbar" style={container}>
                        <img
                            src={referralPhone2}
                            alt="app screenshot"
                            style={featuredPhoneImage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    bodyStyle: {
        backgroundColor: colors.DARKGREY_16,
        width: "100vw",
        height: "100vh",
    } as React.CSSProperties,
    backgroundStyle: {
        zIndex: 0,
        width: "200vw",
        height: "200vh",
        backgroundColor: colors.DARKGREY_16,
        position: "fixed",
        top: "-50vh",
        left: "-50vw",
    } as React.CSSProperties,
    backgroundLineStyle: {
        zIndex: 0,
        width: "200vw",
        height: "80vh",
        backgroundColor: colors.BLUE,
        transform: "translateY(-20px) translateX(5px) rotate(27deg)",
        position: "fixed",
        top: "50%",
        left: "-50vw",
        marginTop: "-40vh",
    } as React.CSSProperties,
    container: {
        position: "relative",
        zIndex: 1,
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    } as React.CSSProperties,
    featuredPhoneImage: {
        width: "80%",
        height: "80%",
        objectFit: "contain",
    } as React.CSSProperties,
    referralImageStyle: {
        maxHeight: 180,
        width: "100%",
        objectFit: "contain",
        marginTop: 24,
    } as React.CSSProperties,
    btnContainerStyle: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center",
        marginTop: 32,
    } as React.CSSProperties,
    googlePlayBtnStyle: {
        width: isTabletOrMobile ? "125px" : "150px",
    } as React.CSSProperties,
    appstoreBtnStyle: {
        width: isTabletOrMobile ? "108px" : "130px",
    } as React.CSSProperties,
});
